// Import for framework tools
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sendGAPageview, formatTime, sortVehicleNamesHelper, machineTypeMapping} from '../../app/utils';
import {DateTime} from 'luxon';

// Import dependent components
import {Framework} from '../../components/Framework';
import {CoverageTable} from './CoverageTable';
import {SprayTable} from './SprayTable';
import {CoverageDataModal} from './CoverageDataModal';
import {Map} from './Map';
import {Menu} from './Menu';
import {updateSpraysComplete, updateMapView} from './cropviewSlice';

function Cropview(props) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.cropview.loading;
  });
  const dates = useSelector((state) => {
    return state.cropview.dates;
  });
  const zoneAnalytics = useSelector((state) => {
    return state.cropview.zoneAnalytics;
  });
  const equipmentAnalytics = useSelector((state) => {
    return state.cropview.equipmentAnalytics;
  });
  const displayedTable = useSelector((state) => {
    return state.cropview.displayedTable;
  });
  const displayedColumn = useSelector((state) => {
    return state.cropview.displayedColumn;
  });
  const mapView = useSelector((state) => {
    return state.cropview.mapView;
  });
  const sortMethod = useSelector((state) => {
    return state.cropview.sortMethod;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [tableData, setTableData] = useState({
    blocks: [],
    fields: [],
    regions: [],
    equipment: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    zones: [],
    equipment: [],
    tasks: [],
  });
  const [filters, setFilters] = useState({
    zones: [],
    equipment: [],
    tasks: [],
  });

  const [drag, setDrag] = useState({
    active: false,
    x: '',
    y: '',
  });

  const [dims, setDims] = useState({
    w: window.innerWidth / 2,
    h: window.innerHeight / 2,
  });

  const [showMap, setShowMap] = useState(props.mapModeEnabled);
  const [showGps, setShowGps] = useState(props.gpsModeEnabled);

  useEffect(() => {
    document.title = 'IntelliCulture | CropView';
    sendGAPageview(document.title);
    getSpraysComplete();
    // When init cropview-export, also ensure mobile mode does not show map
    if (!props.mapModeEnabled) {
      dispatch(updateMapView(0));
    }
  }, []);

  useEffect(() => {
    getCoverage();
  }, [zoneAnalytics, equipmentAnalytics, sortMethod, displayedColumn]);

  function startResize(e) {
    setDrag({
      active: true,
      x: e.clientX || e.touches[0].pageX,
      y: e.clientY || e.touches[0].pageY,
    });
  }

  function resizeFrame(e) {
    const {active, x, y} = drag;
    if (active) {
      const pointerX = e.clientX || e.touches[0].pageX;
      const pointerY = e.clientY || e.touches[0].pageY;
      if (!smallScreen) {
        const xDiff = Math.abs(x - pointerX);
        let newW = x > pointerX ? dims.w - xDiff : dims.w + xDiff;

        const minWidth = 200;
        const maxWidth = window.innerWidth - 300;
        newW = newW < minWidth ? minWidth : newW;
        newW = newW > maxWidth ? maxWidth : newW;

        setDrag({...drag, x: pointerX, y: pointerY});
        setDims({w: newW, h: dims.h});
      } else {
        const yDiff = Math.abs(y - pointerY) * 3;
        const newH = y > pointerY ? dims.h - yDiff : dims.h + yDiff;
        setDrag({...drag, x: pointerX, y: pointerY});
        setDims({w: dims.w, h: newH});
      }
    }
  }

  function stopResize() {
    setDrag({...drag, active: false});
  }

  function setFilterDefault() {
    setFilters({
      zones: [],
      equipment: [],
      tasks: [],
    });
  }

  function toggleShowMap() {
    setShowMap((value) => {
      // Force showGps state to sync with showMap
      setShowGps(!value);
      return !value;
    });
  }

  return (
    <React.Fragment>
      <Framework activePage='cropview' pageName='CropView'>
        <div
          className='container-fluid'
          id='tab-wrapper'
          onMouseMove={resizeFrame}
          onTouchMove={resizeFrame}
          onMouseUp={stopResize}
          onMouseLeave={stopResize}
          onTouchEnd={stopResize}
        >
          <Menu
            setFilterDefault={setFilterDefault}
            gpsModeEnabled={showGps}
            mapModeEnabled={showMap}
            toggleShowMap={toggleShowMap}
          />
          {smallScreen ? (
            <React.Fragment>
              {mapView <= 1 && renderTable()}
              {mapView >= 1 && <Map filters={filters} />}
            </React.Fragment>
          ) : (
            <div className='d-flex flex-row cropview-main'>
              {renderTable()}
              <div className='cropview-handle-horizontal ml-1 mr-3 mb-5' onPointerDown={startResize}></div>
              <div className='flex-grow-1 pt-2 pb-0'>{showMap && <Map filters={filters} />}</div>
            </div>
          )}
        </div>
      </Framework>
      <CoverageDataModal />
    </React.Fragment>
  );

  function renderTable() {
    switch (displayedTable) {
      case 'block':
        return (
          <CoverageTable
            type='block'
            tableData={tableData.blocks}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'field':
        return (
          <CoverageTable
            type='field'
            tableData={tableData.fields}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'region':
        return (
          <CoverageTable
            type='region'
            tableData={tableData.regions}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'equipment':
        return (
          <CoverageTable
            type='equipment'
            tableData={tableData.equipment}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'spray':
        return <SprayTable filterOptions={filterOptions} filters={filters} setFilters={setFilters} dims={dims} />;
    }
  }

  function getCoverage() {
    // If the current day is not selected and zonesAnalStruct and equipmentData is not empty
    const regions = [];
    const fields = [];
    const blocks = [];
    const filterOptionsZone = [];
    const filterOptionsEquipment = [];
    const filterOptionsTask = [];

    // The thresholds used for determining if regions, fields, and blocks are displayed are based on trial and error
    // to avoid displaying drive-bys. These thresholds should be removed once the triptask generation process is
    // updated.
    for (let i = 0; i < zoneAnalytics.length; i++) {
      regions.push(zoneAnalytics[i]);
      for (let j = 0; j < zoneAnalytics[i].subZoneList.length; j++) {
        fields.push(zoneAnalytics[i].subZoneList[j]);

        for (let k = 0; k < zoneAnalytics[i].subZoneList[j].subZoneList.length; k++) {
          blocks.push(zoneAnalytics[i].subZoneList[j].subZoneList[k]);
        }
      }
    }

    const regionsSorted = sortZoneData(regions);
    const fieldsSorted = sortZoneData(fields);
    const blocksSorted = sortZoneData(blocks);
    const equipmentSorted = sortEquipmentData(equipmentAnalytics);

    const blockTableData = generateZoneTableData(blocksSorted, 'block');
    const fieldTableData = generateZoneTableData(fieldsSorted, 'field');
    const regionTableData = generateZoneTableData(regionsSorted, 'region');
    const equipmentTableData = generateEquipmentTableData(equipmentSorted);

    // Collect options for filters
    // Zone filter
    for (let i = 0; i < zoneAnalytics.length; i++) {
      for (let j = 0; j < zoneAnalytics[i].subZoneList.length; j++) {
        for (let k = 0; k < zoneAnalytics[i].subZoneList[j].subZoneList.length; k++) {
          if (
            Math.round(
              (zoneAnalytics[i].subZoneList[j].subZoneList[k].acreTotal /
                zoneAnalytics[i].subZoneList[j].subZoneList[k].area) *
                100
            ) > 0
          ) {
            filterOptionsZone.push({
              value: zoneAnalytics[i].subZoneList[j].subZoneList[k].name,
              text: zoneAnalytics[i].subZoneList[j].subZoneList[k].name,
              group: zoneAnalytics[i].subZoneList[j].name,
            });
          }
        }
      }
    }

    // Equipment filter
    for (let i = 0; i < equipmentAnalytics.length; i++) {
      filterOptionsEquipment.push({
        value: equipmentAnalytics[i].name,
        text: equipmentAnalytics[i].name,
        group: machineTypeMapping[equipmentAnalytics[i].machineType],
      });
    }
    filterOptionsEquipment.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    // Task filter
    for (let i = 0; i < zoneAnalytics.length; i++) {
      for (let j = 0; j < zoneAnalytics[i].vehicleList.length; j++) {
        for (let k = 0; k < zoneAnalytics[i].vehicleList[j].taskList.length; k++) {
          if (zoneAnalytics[i].vehicleList[j].taskList[k].name != '') {
            const filterOptionsTaskExists = filterOptionsTask.filter((filterOption) => {
              return filterOption.value === zoneAnalytics[i].vehicleList[j].taskList[k].name;
            });

            // Check task option doesnt exist
            if (filterOptionsTaskExists.length === 0) {
              filterOptionsTask.push({
                value: zoneAnalytics[i].vehicleList[j].taskList[k].name,
                text: zoneAnalytics[i].vehicleList[j].taskList[k].name,
              });
            }
          }
        }
      }
    }
    filterOptionsTask.sort((a, b) => {
      return a.text == 'No Task' ? -1 : b.text == 'No Task' ? 1 : a.text.localeCompare(b.text);
    });
    // Add empty option
    filterOptionsTask.unshift({value: '', text: '*'});

    setFilterOptions((values) => {
      return {
        ...values,
        zones: [...new Set(filterOptionsZone)],
        equipment: [...new Set(filterOptionsEquipment)],
        tasks: [...new Set(filterOptionsTask)],
      };
    });

    setTableData({
      blocks: blockTableData,
      fields: fieldTableData,
      regions: regionTableData,
      equipment: equipmentTableData,
    });
  }

  function generateZoneTableData(zones, type) {
    const zoneData = [];
    // Iterate through zones
    for (let i = 0; i < zones.length; i++) {
      // Check if all vehicles are passenger vehicles
      let allPassenger = true;
      for (let j = 0; j < zones[i].vehicleList.length; j++) {
        if (zones[i].vehicleList[j].machineType != 1) {
          allPassenger = false;
        }
      }

      // Iterate through vehicles grouped under zones
      for (let j = 0; j < zones[i].vehicleList.length; j++) {
        // Check if vehicle is passenger vehicle
        const vehPassenger = zones[i].vehicleList[j].machineType == 1;

        // Iterate through tasks grouped under vehicles
        for (let k = 0; k < zones[i].vehicleList[j].taskList.length; k++) {
          // Iterate through recs grouped under tasks
          for (let n = 0; n < zones[i].vehicleList[j].taskList[k].recList.length; n++) {
            const rowItem = zones[i].vehicleList[j].taskList[k].recList[n];
            // Create equipment coverage text
            const equipmentTime = rowItem.timeTotal;
            const equipmentAcreage = rowItem.acreTotal;
            let equipmentAcreageRate = 0;
            if (equipmentTime) {
              equipmentAcreageRate = (equipmentAcreage / equipmentTime) * 60;
            }
            const equipmentAcreageAcString = !vehPassenger
              ? `${Math.round(equipmentAcreage * 100) / 100} ac | `
              : ' - ';
            const equipmentAcreageRateString = !vehPassenger
              ? `${Math.round(equipmentAcreageRate * 10) / 10} ac/hr`
              : '';
            let equipmentCoverageString =
              `${formatTime(equipmentTime)} | ` + equipmentAcreageAcString + equipmentAcreageRateString;

            if (
              customerSettings.general.zoneEntryAndExitTimeEnabled &&
              typeof rowItem.entryTime !== 'undefined' &&
              rowItem.entryTime !== null &&
              rowItem.entryTime !== '' &&
              typeof rowItem.exitTime !== 'undefined' &&
              rowItem.exitTime !== null &&
              rowItem.exitTime !== ''
            ) {
              const entryTime = DateTime.fromISO(rowItem.entryTime).setZone(customerSettings.general.timeZone);
              const exitTime = DateTime.fromISO(rowItem.exitTime).setZone(customerSettings.general.timeZone);
              const selectedDateRangeSameDay = DateTime.fromISO(dates.start)
                .setZone(customerSettings.general.timeZone)
                .hasSame(DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone), 'day');
              const entryExitSameDay = entryTime.hasSame(exitTime, 'day');
              if (selectedDateRangeSameDay && entryExitSameDay) {
                equipmentCoverageString += ` | ${entryTime.toFormat('h:mm a')} - ${exitTime.toFormat('h:mm a')}`;
              } else {
                equipmentCoverageString += ` | ${entryTime.toFormat('LL/dd/yy h:mm a')} - ${exitTime.toFormat(
                  'LL/dd/yy h:mm a'
                )}`;
              }
            }

            const zoneDataObj = {
              type: type,
              zone: zones[i].name,
              zoneTime: zones[i].timeTotal,
              equipmentTime: equipmentTime,
              time: formatTime(zones[i].timeTotal),
              coverageValue: zones[i].acreTotal,
              coverageValueRaw: zones[i].acreTotalRaw,
              coverage: !allPassenger ? `${Math.round(zones[i].acreTotal * 100) / 100} ac ` : ' - ',
              equipmentName: zones[i].vehicleList[j].name,
              equipmentSN: zones[i].vehicleList[j].serialNumber,
              equipmentCoverageValue: equipmentAcreage,
              equipmentCoverage: equipmentCoverageString,
              equipmentSpeed:
                userSettings.general.units == 'imperial'
                  ? `${(rowItem.avgSpeed * 0.621).toFixed(1)} mph`
                  : `${rowItem.avgSpeed.toFixed(1)} kph`,
              task: zones[i].vehicleList[j].taskList[k].name,
              taskId: zones[i].vehicleList[j].taskList[k].id,
              recId: rowItem.id,
              implementSN: zones[i].vehicleList[j].implementSNList[k],
              fullEquipmentList: zones[i].vehicleList,
              maxAreaZone: zones[i].maxAreaZone,
              entryTime: rowItem.entryTime,
              exitTime: rowItem.exitTime,
            };
            zoneData.push(zoneDataObj);
          }
        }
      }
    }

    return zoneData;
  }

  function generateEquipmentTableData(equipment) {
    const equipmentData = [];
    for (let i = 0; i < equipment.length; i++) {
      // Check if vehicle is passenger vehicle
      const vehPassenger = equipment[i].machineType == 1;

      let timeTotal = equipment[i].timeInField + equipment[i].timeOutField;
      if (customerSettings.general.idleTime) {
        timeTotal += equipment[i].timeIdle;
      }

      let acreageRate = 0;
      if (equipment[i].timeInField) {
        acreageRate = (equipment[i].acreTotal / equipment[i].timeInField) * 60;
      }

      const equipmentDataObj = {
        type: 'equipment',
        equipment: equipment[i].name,
        equipmentSN: equipment[i].serialNumber,
        timeInField: formatTime(equipment[i].timeInField),
        timeOutField: formatTime(equipment[i].timeOutField),
        timeIdle: formatTime(equipment[i].timeIdle),
        timeTotal: formatTime(timeTotal),
        coverage: !vehPassenger ? `${Math.round(equipment[i].acreTotal * 10) / 10} ac` : ' - ',
        acreageRate: !vehPassenger ? `${Math.round(acreageRate * 10) / 10} ac/hr` : ' - ',
        task: equipment[i].task,
        taskId: equipment[i].taskId,
        taskPerm: equipment[i].taskPerm,
        gpsFound: equipment[i].latestGpsPoint.length > 0,
        recId: equipment[i].recId,
      };
      equipmentData.push(equipmentDataObj);
    }
    return equipmentData;
  }

  function sortZoneData(dataList) {
    // Make copy of list or else will get error when trying to sort
    const zoneList = JSON.parse(JSON.stringify(dataList));
    // Sort zones and vehicle list within zone by method specified
    // Will sort a after b if compare function returns > 0 and sort a before b if compare function returns < 0
    if (sortMethod == 'name') {
      zoneList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      for (let i = 0; i < zoneList.length; i++) {
        zoneList[i].vehicleList.sort((a, b) => {
          return sortVehicleNamesHelper(a.name, b.name);
        });
      }
    } else if (sortMethod == 'acreage') {
      zoneList.sort((a, b) => {
        return a.acreTotal < b.acreTotal ? 1 : -1;
      });
      for (let i = 0; i < zoneList.length; i++) {
        zoneList[i].vehicleList.sort((a, b) => {
          return a.acreTotal < b.acreTotal ? 1 : -1;
        });
      }
    } else if (sortMethod == 'percent') {
      zoneList.sort((a, b) => {
        return (a.acreTotal / a.area) * 100 < (b.acreTotal / b.area) * 100 ? 1 : -1;
      });
      for (let i = 0; i < zoneList.length; i++) {
        zoneList[i].vehicleList.sort((a, b) => {
          return a.timeTotal < b.timeTotal ? 1 : -1; // Sort vehicles by time as they do not have percentage
        });
      }
    } else {
      zoneList.sort((a, b) => {
        return a.timeTotal < b.timeTotal ? 1 : -1;
      });
      for (let i = 0; i < zoneList.length; i++) {
        zoneList[i].vehicleList.sort((a, b) => {
          return a.timeTotal < b.timeTotal ? 1 : -1;
        });
      }
    }

    return zoneList;
  }

  function sortEquipmentData(dataList) {
    // Make copy of list or else will get error when trying to sort
    const equipmentList = JSON.parse(JSON.stringify(dataList));
    // Sort equipment by method specified
    // Will sort a after b if compare function returns > 0 and sort a before b if compare function returns < 0
    if (sortMethod == 'name') {
      equipmentList.sort((a, b) => {
        return sortVehicleNamesHelper(a.name, b.name);
      });
    } else if (sortMethod == 'acreage') {
      equipmentList.sort((a, b) => {
        return a.acreTotal < b.acreTotal ? 1 : -1;
      });
    } else if (sortMethod == 'timeTotal') {
      equipmentList.sort((a, b) => {
        let aTotal = a.timeInField + a.timeOutField;
        let bTotal = b.timeInField + b.timeOutField;
        if (customerSettings.general.idleTime) {
          aTotal += a.timeIdle;
          bTotal += b.timeIdle;
        }
        return aTotal < bTotal ? 1 : -1;
      });
    } else if (sortMethod == 'type') {
      // Type order [Tractor, Platform, ATV, Construction, Road Vehicle, Trailer, Not Assigned]
      const typeSortOrder = [2, 5, 4, 6, 1, 3, 0];
      equipmentList.sort((a, b) => {
        const aTypeIdx = typeSortOrder.indexOf(a.machineType);
        const bTypeIdx = typeSortOrder.indexOf(b.machineType);
        return aTypeIdx - bTypeIdx || sortVehicleNamesHelper(a.name, b.name);
      });
    } else {
      equipmentList.sort((a, b) => {
        return a[sortMethod] < b[sortMethod] ? 1 : -1;
      });
    }

    return equipmentList;
  }

  async function getSpraysComplete() {
    // Get data
    let sprayCompleteDict = {};
    try {
      const sprayCompleteResponse = await fetch('/cropview/getSprayComplete', {cache: 'no-store'});
      sprayCompleteDict = await sprayCompleteResponse.json();
    } catch (err) {
      console.error(err);
    }

    // Update store
    dispatch(updateSpraysComplete(sprayCompleteDict));
  }
}

export {Cropview};
