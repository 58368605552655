import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Collapse} from '@mui/material';
import {ModalSubmitting} from './GeneralComponents';

function ModalFramework(props) {
  return (
    <div
      className='modal fade'
      id={props.id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      aria-hidden='true'
      data-backdrop={props.backdrop || true}
      data-keyboard={props.keyboard || true}
    >
      <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg' role='document'>
        <div className='modal-content'>{props.children}</div>
      </div>
    </div>
  );
}

function ModalRowSection(props) {
  return (
    <div>
      <div className='row mb-3'>{props.children}</div>
      {props.underline && <hr />}
    </div>
  );
}

function ModalColumnSection(props) {
  return <div className='flex-column flex-fill mw-50 col-6 px-1'>{props.children}</div>;
}

function ModalWideColumnSection(props) {
  return <div className='flex-column flex-fill mw-100 col-12 px-1'>{props.children}</div>;
}

function ModalWideColumnSectionCentered(props) {
  return <div className='flex-column centered flex-fill mw-100 col-12 px-1'>{props.children}</div>;
}

function ModalInputSection(props) {
  return (
    <div className='flex-row mb-3 col-12 px-1'>
      <label>{props.label}</label>
      {props.children}
    </div>
  );
}

function ModalWarning(props) {
  return <div className='row text-s text-danger font-italic mb-3'>{props.text}</div>;
}

function ModalNote(props) {
  return <div className='row text-s text-primary font-italic ml-2 text-break'>{props.text}</div>;
}

function ModalFooter(props) {
  if (props.submitting) {
    return null;
  }
  return (
    <div className='modal-footer'>
      {props.onLeftSideButton && (
        <button type='button' className='btn btn-info mr-auto' onClick={props.onLeftSideButton}>
          {props.leftSideButtonText || 'Left'}
        </button>
      )}
      {props.onDelete && (
        <button type='button' className='btn btn-danger mr-auto' onClick={props.onDelete}>
          Delete
        </button>
      )}
      {!props.noCancelButton && (
        <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={props.onCancel}>
          Cancel
        </button>
      )}
      {props.onSubmit && (
        <button type='button' className='btn btn-primary' onClick={props.onSubmit} disabled={props?.submitting}>
          {props.submitText || 'Submit'}
        </button>
      )}
    </div>
  );
}

function ModalHeader(props) {
  return (
    <div className='modal-header'>
      <div>
        <h5 className={`modal-title font-weight-bold ${props.children && 'mb-2'}`}>{props.title}</h5>
        {props.children}
      </div>
      <div>
        {!props.noCloseButton && (
          <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
            <span aria-hidden='true'>&times;</span>
          </button>
        )}
      </div>
    </div>
  );
}

function ModalBody(props) {
  return (
    <div className='modal-body'>
      {props.submitting && <ModalSubmitting />}
      <div className={`container-fluid px-1 ${props.submitting ? 'd-none' : ''}`}>
        <div className='d-flex '>
          <div className='flex-fill'>
            <form noValidate>{props.children}</form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ModalCollapsableRow(props) {
  const open = props.collapseOpen[props.section];
  return (
    <React.Fragment>
      <div className='row w-100 px-3 m-0'>
        <h6 className='mt-2' style={{color: 'black'}}>
          {props.title}
          {props.viewOnly && <span style={{fontWeight: 'bold'}}> - View Only</span>}
          <span
            className='ml-2 hoverPls'
            onClick={() => {
              props.setCollapseOpen((values) => {
                return {...values, [props.section]: !props.collapseOpen[props.section]};
              });
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </span>
        </h6>
      </div>
      <div className='row w-100 px-3 m-0'>
        <Collapse in={open} timeout='auto' unmountOnExit sx={{width: '100%'}}>
          {props.children}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export {
  ModalFramework,
  ModalRowSection,
  ModalColumnSection,
  ModalWideColumnSection,
  ModalWideColumnSectionCentered,
  ModalInputSection,
  ModalWarning,
  ModalNote,
  ModalFooter,
  ModalHeader,
  ModalBody,
};
