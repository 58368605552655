import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function FilterCard(props) {
  return (
    <div className={`card flex-row shadow mx-auto shopview-card-div border-left-${props.color}`}>
      <div className='input-group-prepend'>
        <button
          className={`card-filter-btn bg-${props.color} border-0`}
          onClick={props.filterOnClick}
          name={props.name}
        >
          {props.filterActive ? (
            <FontAwesomeIcon icon='fa fa-filter-circle-xmark' style={{color: '#4e73df'}} />
          ) : (
            <FontAwesomeIcon icon='fa fa-filter' />
          )}
        </button>
      </div>
      <div className='card-body shopview-card-body'>
        <div className='d-flex flex-row'>
          <div className='d-inline'>
            <span className={`font-weight-bold shopview-card-text text-${props.color}`}>
              {props.text}: {props.value}
            </span>
          </div>
          <div className='flex-fill'>
            <div className='float-right shopview-card-icon pt-1'>
              <i className={`fas fa-2x text-gray-300 ${props.icon}`}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Card(props) {
  return (
    <div className={`card flex-row shadow mx-auto shopview-card-div border-left-${props.color}`}>
      <div className='card-body shopview-card-body'>
        <div className='d-flex flex-row'>
          <div className='d-inline'>
            <span className={`font-weight-bold shopview-card-text text-${props.color}`}>
              {props.text}: {props.value}
            </span>
          </div>
          <div className='flex-fill'>
            <div className='float-right shopview-card-icon pt-1'>
              <i className={`fas fa-2x text-gray-300 ${props.icon}`}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export {FilterCard, Card};
