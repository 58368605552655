import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Table, TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {Tailselect} from '../../../components/Tailselect';
import {searchFind} from '../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {setInitialValues} from './diagnosticsModalSlice';
import MyPagination from '../../../components/MyPagination';

function DiagnosticsTable(props) {
  const dispatch = useDispatch();

  const MAX_ITEMS_PER_PAGE = 50;
  const columnClass = ['diagnostics-col-1', 'diagnostics-col-2', 'diagnostics-col-3'];

  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });

  const [items, setItems] = useState(0); // Number of pages in pagination
  const [curPage, setCurPage] = useState(1); // Current page in pagination
  const [tableData, setTableData] = useState([]); // partial data to render in table
  const [filters, setFilters] = useState({
    vehicles: [],
    faults: [],
  });

  useEffect(() => {
    filterTableRows(props.tableData);
  }, [props.tableData, curPage, filters, searchText]);

  const afterPageClicked = (pageNumber) => {
    setCurPage(pageNumber);
  };

  async function filterTableRows(tablerow) {
    // Filter props.tablerows based on filters and pagination to render limited amount of data on tables
    // Promise.all is used to avoid desync between states update and rerendering due to slow filter on large array
    await Promise.all(
      // Generate an array based on rowFilter function (return true or false) base on filters
      tablerow.map((rowData) => {
        return rowFilter(rowData, <div>{JSON.stringify(rowData)}</div>);
      })
    ).then((result) => {
      // Filter rows of data based on filter results
      const resultRows = tablerow.filter((row, index) => {
        return result[index] === true;
      });

      // Updating Pagination States based on number of items/rows of data
      let numPages = Math.floor(resultRows.length / MAX_ITEMS_PER_PAGE);
      if (resultRows.length % MAX_ITEMS_PER_PAGE != 0) {
        numPages++;
      }
      setItems(numPages);
      // Navigate to the 1st page if current page is larger than total number of pages
      if (curPage > numPages) {
        setCurPage(1);
      }

      // Update tableData State to render partial rows of data from pagination
      setTableData(resultRows.slice(curPage * MAX_ITEMS_PER_PAGE - MAX_ITEMS_PER_PAGE, curPage * MAX_ITEMS_PER_PAGE));
    });
  }

  function generateTableData() {
    return (
      <tbody id='diagnostics-table-body'>
        {tableData.map((rowData, index) => {
          const row = (
            <tr
              key={index}
              onClick={() => {
                if ($(window).width() <= 575) {
                  dispatch(setInitialValues(rowData));
                  $('#shopview-diagnostics-data-modal').modal('show');
                }
              }}
            >
              <td className={columnClass[0]}>
                <div>
                  <div className='font-weight-bold'>{rowData.vehicle}</div>
                </div>
              </td>
              <td className={columnClass[1]}>
                <div>
                  <div>{rowData.faultCode}</div>
                </div>
              </td>
              <td className={columnClass[2]}>
                <div>
                  <div>{rowData.faultDescription}</div>
                </div>
              </td>
            </tr>
          );
          return row;
        })}
      </tbody>
    );
  }

  function generateTableHeaders() {
    return (
      <tr>
        <th className={columnClass[0]} width='30%'>
          <TableHeadFilterButtonTitleWrapper title='Equipment'>
            <button
              className='btn btn-transparent btn-sm'
              onClick={() => {
                return tail.select('#shopview-diagnostics-vehicle-select').toggle();
              }}
            >
              <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.vehicles.length > 0 && '#4e73df'}} />
            </button>
          </TableHeadFilterButtonTitleWrapper>
          <TableHeadHiddenDropdownWrapper>
            <Tailselect
              id='shopview-diagnostics-vehicle-select'
              name='vehicles'
              multiple={true}
              search={true}
              value={filters.vehicles}
              options={props.filterOptions.vehicles}
              onChange={handleFilters}
            />
          </TableHeadHiddenDropdownWrapper>
        </th>
        <th className={columnClass[1]} width='30%'>
          <TableHeadFilterButtonTitleWrapper title='Fault Code'>
            <button
              className='btn btn-transparent btn-sm'
              onClick={() => {
                return tail.select('#shopview-diagnostics-fault-select').toggle();
              }}
            >
              <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.faults.length > 0 && '#4e73df'}} />
            </button>
          </TableHeadFilterButtonTitleWrapper>
          <TableHeadHiddenDropdownWrapper>
            <Tailselect
              id='shopview-diagnostics-fault-select'
              name='faults'
              multiple={true}
              search={true}
              value={filters.faults}
              options={props.filterOptions.faults}
              onChange={handleFilters}
            />
          </TableHeadHiddenDropdownWrapper>
        </th>
        <th className={columnClass[2]} width='40%'>
          Fault Description
        </th>
      </tr>
    );
  }

  function rowFilter(rowData, row) {
    // Filter rows based on dropdown selectors and search text
    const vehiclesInFilter = filters.vehicles.includes(rowData.vehicle) || filters.vehicles.length == 0;
    const faultInFilter = filters.faults.includes(rowData.faultCode) || filters.faults.length == 0;
    const search = searchFind(row, searchText.toLowerCase().trim());

    return vehiclesInFilter && faultInFilter && search;
  }

  function handleFilters(select) {
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  return (
    <div className='table-responsive'>
      <Table headers={generateTableHeaders()} body={generateTableData()} loading={props.loading} />
      <MyPagination
        totPages={items}
        currentPage={curPage}
        pageClicked={(ele) => {
          afterPageClicked(ele);
        }}
      ></MyPagination>
    </div>
  );
}

export {DiagnosticsTable};
