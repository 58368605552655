import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  stringToArray,
  emailRegex,
  searchFind,
  hideCell,
  squareButton,
  generateCsv,
  fetchPostAuthSafe,
} from '../../../app/utils';
import {getCustomerAndUserInfo} from '../../../appSlice';
import {getSettingsData, updateLoading} from '../settingsSlice';
import {
  CollapsableSection,
  SettingsSwitch,
  SettingsSelect,
  SettingsSearchSelect,
  SettingsChipInput,
  ViewOnlyText,
} from '../../../components/GeneralComponents';

import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {Tailselect} from '../../../components/Tailselect';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../../components/CustomTablePagination';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  Box,
  Button,
  Chip,
  CircularProgress,
  Tab,
} from '@mui/material';

function CoverageEquipmentTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.loading;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const reportSettings = useSelector((state) => {
    return state.app.customerSettings.reports;
  });
  const vehicles = useSelector((state) => {
    return state.settings.vehicles;
  });
  const databaseUsers = useSelector((state) => {
    return state.settings.databaseUsers;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });

  const [collapseOpen, setCollapseOpen] = useState({
    general: false,
    equipment: false,
    coverage: false,
    inspection: false,
  });

  const [formValues, setFormValues] = useState({
    coverageBccEmails: [],
    equipmentBccEmails: [],
    myGeotabAccess: false,
    deliveryMethod: '',
    coverageReportFrequency: [],
    equipmentReportFrequency: [],
    coverageEmailsDaily: [],
    coverageEmailsWeekly: [],
    equipmentEmailsDaily: [],
    equipmentEmailsWeekly: [],
    exceptionsList: [],
    fieldAlertsPage: false,
    efficiencyPage: false,
    blocksPage: false,
    flaggedLoggersPage: false,
    acresLegend: false,
    diagnosticsPage: false,
    vehicleSeparateFarmAndHighway: false,
    staticPlotsNames: [],
    staticPlotsCoords: [],
    vehicleSummarySort: '',
    fieldSummarySort: '',
    blockPagesSort: '',
    fieldCoverageUser: false,
    fieldCoverageImplement: false,
    fieldCoverageTask: false,
    extendedFieldLegends: false,
    coverageShowVehicleAverageSpeed: false,
    flaggedLoggersIgnoreNotActive: false,
    excludeBesols: false,
    coverageHideInactiveVehicles: false,
    coverageSendUnfiltered: false,
    coverageSendFiltered: false,
    coverageExcludedVehicles: [],
    taskFilterDaily: [],
    taskFilterWeekly: [],
  });

  // For the Reports Configuration Visualizer
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [filters, setFilters] = useState({
    reportVisualsEmail: [],
    reportVisualsCoverage: [],
    reportVisualsEquipment: [],
    reportVisualsCoverageFilters: [],
    reportVisualsExcludedVeh: [],
    reportVisualsExcludedBlocks: [],
    reportVisualsExcludedFields: [],
    reportVisualsExcludedRegions: [],
    reportVisualsExcludedTasks: [],
    reportVisualsEnforceExclusion: [],
    reportVisualsCustomReport: [],
  });

  const [filterOptions, setFilterOptions] = useState({
    reportVisualsEmail: [],
    reportVisualsCoverage: ['Daily', 'Weekly', 'Daily & Weekly', 'None'],
    reportVisualsEquipment: ['Daily', 'Weekly', 'Daily & Weekly', 'None'],
    reportVisualsCoverageFilters: ['Daily', 'Weekly', 'Daily & Weekly', 'None'],
    reportVisualsEquipmentFilters: ['Daily', 'Weekly', 'Daily & Weekly', 'None'],
    reportVisualsExcludedVeh: ['Yes', 'No'],
    reportVisualsExcludedBlocks: ['Yes', 'No'],
    reportVisualsExcludedFields: ['Yes', 'No'],
    reportVisualsExcludedRegions: ['Yes', 'No'],
    reportVisualsExcludedTasks: ['Daily', 'Weekly', 'Daily & Weekly', 'None'],
    reportVisualsEnforceExclusion: ['Yes', 'No'],
    reportVisualsCustomReport: ['Yes', 'No'],
  });

  const [tableData, setTableData] = useState([]);
  const [activeVehicles, setActiveVehicles] = useState([]);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  useEffect(async () => {
    const activeVehTemp = [];
    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].icActive) {
        activeVehTemp.push(vehicles[i]);
      }
    }

    setActiveVehicles(activeVehTemp);
  }, [vehicles]);

  useEffect(async () => {
    // Generate table data
    const dataPacket = await generateReportConfigurationMetadata();
    const reportConfigurationMetadata = dataPacket.reportConfigurationMetadata;
    const emailsList = dataPacket.emailsList;

    setFilterOptions({
      ...filterOptions,
      reportVisualsEmail: emailsList,
    });

    const tempTableData = [...reportConfigurationMetadata].filter((configObj) => {
      return filterRow(configObj);
    });

    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(tempTableData);

    resetFormValues();
  }, [customerSettings, databaseUsers, searchText, filters]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setFormValues((values) => {
      return {...values, [name]: value};
    });
  };

  function resetFormValues() {
    const staticPlots = reportSettings.coveragePages.staticPlots;
    const staticPlotsNames = [];
    const staticPlotsCoords = [];
    for (let i = 0; i < staticPlots.length; i++) {
      staticPlotsNames.push(staticPlots[i].name);
      const dimensions = staticPlots[i].dimensions;
      staticPlotsCoords.push(`(${dimensions[0]},${dimensions[1]},${dimensions[2]},${dimensions[3]})`);
    }
    setFormValues((values) => {
      return {
        ...values,
        coverageBccEmails: reportSettings.background.coverageBccEmails,
        equipmentBccEmails: reportSettings.background.equipmentBccEmails,
        myGeotabAccess: reportSettings.background.myGeotabAccess,
        deliveryMethod: reportSettings.delivery.deliveryMethod,
        coverageReportFrequency: reportSettings.delivery.coverageReportFrequency,
        equipmentReportFrequency: reportSettings.delivery.equipmentReportFrequency,
        coverageEmailsDaily: reportSettings.delivery.coverageEmailsDaily,
        coverageEmailsWeekly: reportSettings.delivery.coverageEmailsWeekly,
        equipmentEmailsDaily: reportSettings.delivery.equipmentEmailsDaily,
        equipmentEmailsWeekly: reportSettings.delivery.equipmentEmailsWeekly,
        exceptionsList: reportSettings.exceptions.exceptionsList,
        fieldAlertsPage: reportSettings.coveragePages.fieldAlertsPage,
        efficiencyPage: reportSettings.coveragePages.efficiencyPage,
        blocksPage: reportSettings.coveragePages.blocksPage,
        flaggedLoggersPage: reportSettings.coveragePages.flaggedLoggersPage,
        acresLegend: reportSettings.coveragePages.acresLegend,
        diagnosticsPage: reportSettings.coveragePages.diagnosticsPage,
        vehicleSeparateFarmAndHighway: reportSettings.coveragePages.vehicleSeparateFarmAndHighway,
        staticPlots: reportSettings.coveragePages.staticPlots,
        staticPlotsNames: staticPlotsNames,
        staticPlotsCoords: staticPlotsCoords,
        vehicleSummarySort: reportSettings.coveragePages.vehicleSummarySort,
        fieldSummarySort: reportSettings.coveragePages.fieldSummarySort,
        blockPagesSort: reportSettings.coveragePages.blockPagesSort,
        fieldCoverageUser: reportSettings.coveragePages.fieldCoverageUser,
        fieldCoverageImplement: reportSettings.coveragePages.fieldCoverageImplement,
        fieldCoverageTask: reportSettings.coveragePages.fieldCoverageTask,
        extendedFieldLegends: reportSettings.coveragePages.extendedFieldLegends,
        coverageShowVehicleAverageSpeed: reportSettings.coveragePages.coverageShowVehicleAverageSpeed,
        flaggedLoggersIgnoreNotActive: reportSettings.coveragePages.flaggedLoggersIgnoreNotActive,
        excludeBesols: reportSettings.coveragePages.excludeBesols,
        coverageHideInactiveVehicles: reportSettings.vehicleFilter.coverageHideInactiveVehicles,
        coverageSendUnfiltered: reportSettings.vehicleFilter.coverageSendUnfiltered,
        coverageSendFiltered: reportSettings.vehicleFilter.coverageSendFiltered,
        coverageExcludedVehicles: reportSettings.vehicleFilter.coverageExcludedVehicles,
        taskFilterDaily: reportSettings.taskFilter.daily,
        taskFilterWeekly: reportSettings.taskFilter.weekly,
      };
    });
  }

  /*
  Filter the table data based on the current state of the filters
  */
  function filterRow(configObj) {
    const rowObj = {
      email: configObj.email, // email
      coverage: configObj.coverage, // Daily, Weekly, Daily & Weekly, None
      equipment: configObj.equipment, // Daily, Weekly, Daily & Weekly, None
      coverageFilters: configObj.coverageFilters, // Daily, Weekly, Daily & Weekly, None
      excludedVehicles: configObj.excludedVehicles, // true, false
      excludedBlocks: configObj.excludedBlocks, // true, false
      excludedFields: configObj.excludedFields, // true, false
      excludedRegions: configObj.excludedRegions, // true, false
      excludedTasks: configObj.excludedTasks, // true, false
      enforceExclusion: configObj.enforceExclusion, // true, false
      customReport: configObj.customReport, // true, false
    };

    const search = searchFind(<div>{JSON.stringify(rowObj)}</div>, searchText.toLowerCase().trim());
    const emailInFilter =
      filters.reportVisualsEmail.includes(configObj.email) || filters.reportVisualsEmail.length == 0;
    const coverageInFilter =
      filters.reportVisualsCoverage.includes(configObj.coverage) || filters.reportVisualsCoverage.length == 0;
    const coverageFiltersInFilter =
      filters.reportVisualsCoverageFilters.includes(configObj.coverageFilters) ||
      filters.reportVisualsCoverageFilters.length == 0;
    const excludedVehiclesInFilter =
      filters.reportVisualsExcludedVeh.includes(configObj.excludedVehicles) ||
      filters.reportVisualsExcludedVeh.length == 0;
    const excludedBlocksInFilter =
      filters.reportVisualsExcludedBlocks.includes(configObj.excludedBlocks) ||
      filters.reportVisualsExcludedBlocks.length == 0;
    const excludedFieldsInFilter =
      filters.reportVisualsExcludedFields.includes(configObj.excludedFields) ||
      filters.reportVisualsExcludedFields.length == 0;
    const excludedRegionsInFilter =
      filters.reportVisualsExcludedRegions.includes(configObj.excludedRegions) ||
      filters.reportVisualsExcludedRegions.length == 0;
    const excludedTasksInFilter =
      filters.reportVisualsExcludedTasks.includes(configObj.excludedTasks) ||
      filters.reportVisualsExcludedTasks.length == 0;
    const enforceExclusionInFilter =
      filters.reportVisualsEnforceExclusion.includes(configObj.enforceExclusion) ||
      filters.reportVisualsEnforceExclusion.length == 0;
    const customReportInFilter =
      filters.reportVisualsCustomReport.includes(configObj.customReport) ||
      filters.reportVisualsCustomReport.length == 0;

    const userInFilter =
      emailInFilter &&
      coverageInFilter &&
      coverageFiltersInFilter &&
      excludedVehiclesInFilter &&
      excludedBlocksInFilter &&
      excludedFieldsInFilter &&
      excludedRegionsInFilter &&
      excludedTasksInFilter &&
      enforceExclusionInFilter &&
      customReportInFilter;

    return userInFilter && search;
  }

  async function generateReportConfigurationMetadata() {
    // databaseUsers
    // reportSettings
    // customerSettings

    const reportConfigurationMetadata = [];

    if (databaseUsers.length == 0 || databaseUsers === undefined || reportSettings === undefined) {
      return {reportConfigurationMetadata: reportConfigurationMetadata, emailsList: []};
    }

    // Create list of users to check against
    let emailsList = [];
    const usersMap = {};
    databaseUsers.forEach((user) => {
      // Only include active users
      if (!user.archived && user.email != '' && user.email != null) {
        emailsList.push(user.email);
        usersMap[user.email] = user;
      }
    });

    // Combine all emails
    emailsList = [
      ...emailsList,
      ...reportSettings.delivery.coverageEmailsDaily,
      ...reportSettings.delivery.coverageEmailsWeekly,
      ...reportSettings.delivery.equipmentEmailsDaily,
      ...reportSettings.delivery.equipmentEmailsWeekly,
    ];

    // Remove any duplicate emails
    emailsList = [...new Set(emailsList)];

    // Iterate through all emails and check configurations
    emailsList.forEach((email) => {
      const configObj = {
        email: email, // email
        coverage: 'None', // Daily, Weekly, Daily & Weekly, None
        equipment: 'None', // Daily, Weekly, Daily & Weekly, None
        coverageFilters: 'None', // Daily, Weekly, Daily & Weekly, None
        excludedVehicles: 'No', // true, false
        excludedBlocks: 'No', // true, false
        excludedFields: 'No', // true, false
        excludedRegions: 'No', // true, false
        excludedTasks: 'None', // true, false
        enforceExclusion: 'No', // true, false
        customReport: 'No', // true, false
      };

      // Check whether user will receive a custom report
      if (
        (usersMap.hasOwnProperty(email) &&
          !usersMap[email].settings.reports.receiveCustomReport &&
          usersMap[email].settings.general.excludedVehicles.length == 0) ||
        !usersMap.hasOwnProperty(email)
      ) {
        // Check which coverage reports are enabled
        if (
          reportSettings.delivery.coverageEmailsDaily.includes(email) &&
          !reportSettings.delivery.coverageEmailsWeekly.includes(email)
        ) {
          configObj.coverage = 'Daily';
        } else if (
          reportSettings.delivery.coverageEmailsWeekly.includes(email) &&
          !reportSettings.delivery.coverageEmailsDaily.includes(email)
        ) {
          configObj.coverage = 'Weekly';
        } else if (
          reportSettings.delivery.coverageEmailsDaily.includes(email) &&
          reportSettings.delivery.coverageEmailsWeekly.includes(email)
        ) {
          configObj.coverage = 'Daily & Weekly';
        }

        // Check which equipment reports are enabled
        if (
          reportSettings.delivery.equipmentEmailsDaily.includes(email) &&
          !reportSettings.delivery.equipmentEmailsWeekly.includes(email)
        ) {
          configObj.equipment = 'Daily';
        } else if (
          reportSettings.delivery.equipmentEmailsWeekly.includes(email) &&
          !reportSettings.delivery.equipmentEmailsDaily.includes(email)
        ) {
          configObj.equipment = 'Weekly';
        } else if (
          reportSettings.delivery.equipmentEmailsDaily.includes(email) &&
          reportSettings.delivery.equipmentEmailsWeekly.includes(email)
        ) {
          configObj.equipment = 'Daily & Weekly';
        }

        // Check if coverage filters are enabled
        if (
          reportSettings.vehicleFilter.coverageSendFiltered === true &&
          reportSettings.vehicleFilter.coverageSendUnfiltered === false &&
          reportSettings.vehicleFilter.coverageExcludedVehicles.length > 0 &&
          configObj.coverage != 'None'
        ) {
          configObj.coverageFilters = configObj.coverage; // maps to the coverage setting
        }
        // Check if vehicles specifically are excluded
        if (reportSettings.vehicleFilter.coverageExcludedVehicles.length > 0) {
          configObj.excludedVehicles = 'Yes';
        }

        // Check if task filters are applied
        if (
          reportSettings.taskFilter.daily.length == 1 &&
          reportSettings.taskFilter.weekly.length == 1 &&
          reportSettings.taskFilter.daily.includes('all') &&
          reportSettings.taskFilter.weekly.includes('all')
        ) {
          configObj.excludedTasks = 'None';
        } else if (
          // daily filter has anything other than all and weekly filter only has all
          !reportSettings.taskFilter.daily.every((x) => {
            return x == 'all';
          }) &&
          reportSettings.taskFilter.weekly.every((x) => {
            return x == 'all';
          })
        ) {
          configObj.excludedTasks = 'Daily';
        } else if (
          // weekly filter has anything other than all and weekly filter only has all
          !reportSettings.taskFilter.weekly.every((x) => {
            return x == 'all';
          }) &&
          reportSettings.taskFilter.daily.every((x) => {
            return x == 'all';
          })
        ) {
          configObj.excludedTasks = 'Weekly';
        } else {
          configObj.excludedTasks = 'Daily & Weekly';
        }

        // Equipment filters are on a per user basis
      } else if (usersMap.hasOwnProperty(email)) {
        const currentUserSettings = usersMap[email].settings;

        // Check if user has coverage reports enabled
        if (
          currentUserSettings.reports.receiveDailyCoverageReport &&
          !currentUserSettings.reports.receiveWeeklyCoverageReport
        ) {
          configObj.coverage = 'Daily';
        } else if (
          !currentUserSettings.reports.receiveDailyCoverageReport &&
          currentUserSettings.reports.receiveWeeklyCoverageReport
        ) {
          configObj.coverage = 'Weekly';
        } else if (
          currentUserSettings.reports.receiveDailyCoverageReport &&
          currentUserSettings.reports.receiveWeeklyCoverageReport
        ) {
          configObj.coverage = 'Daily & Weekly';
        }

        // Check if user has equipment reports enabled
        if (
          currentUserSettings.reports.receiveDailyEquipmentReport &&
          !currentUserSettings.reports.receiveWeeklyEquipmentReport
        ) {
          configObj.equipment = 'Daily';
        } else if (
          !currentUserSettings.reports.receiveDailyEquipmentReport &&
          currentUserSettings.reports.receiveWeeklyEquipmentReport
        ) {
          configObj.equipment = 'Weekly';
        } else if (
          currentUserSettings.reports.receiveDailyEquipmentReport &&
          currentUserSettings.reports.receiveWeeklyEquipmentReport
        ) {
          configObj.equipment = 'Daily & Weekly';
        }

        // Check if coverage filters are enabled
        if (
          currentUserSettings.general.enableBlockExclusion ||
          currentUserSettings.general.excludedBlocks.length > 0 ||
          currentUserSettings.general.excludedFields.length > 0 ||
          currentUserSettings.general.excludedRegions.length > 0 ||
          (currentUserSettings.general.excludedVehicles.length > 0 && configObj.coverage !== 'None')
        ) {
          // maps to the coverage setting
          configObj.coverageFilters = configObj.coverage;
        }

        // Check if vehicles specifically are excluded
        if (currentUserSettings.general.excludedVehicles.length > 0) {
          configObj.excludedVehicles = 'Yes';
        }

        // Check if blocks specifically are excluded
        if (currentUserSettings.general.excludedBlocks.length > 0 && currentUserSettings.general.enableBlockExclusion) {
          configObj.excludedBlocks = 'Yes';
        }

        // Check if fields specifically are excluded
        if (currentUserSettings.general.excludedFields.length > 0 && currentUserSettings.general.enableBlockExclusion) {
          configObj.excludedFields = 'Yes';
        }

        // Check if regions specifically are excluded
        if (
          currentUserSettings.general.excludedRegions.length > 0 &&
          currentUserSettings.general.enableBlockExclusion
        ) {
          configObj.excludedRegions = 'Yes';
        }

        // Check if task filters are applied
        if (
          // If both daily and weekly have anything other than all
          !currentUserSettings.reports.taskFilter.daily.every((x) => {
            return x == 'all';
          }) &&
          !currentUserSettings.reports.taskFilter.weekly.every((x) => {
            return x == 'all';
          })
        ) {
          configObj.excludedTasks = 'Daily & Weekly';
        } else if (
          // If daily has anything other than all but weekly only has all
          !currentUserSettings.reports.taskFilter.daily.every((x) => {
            return x == 'all';
          }) &&
          currentUserSettings.reports.taskFilter.weekly.every((x) => {
            return x == 'all';
          })
        ) {
          configObj.excludedTasks = 'Daily';
        } else if (
          currentUserSettings.reports.taskFilter.daily.every((x) => {
            return x == 'all';
          }) &&
          !currentUserSettings.reports.taskFilter.weekly.every((x) => {
            return x == 'all';
          })
        ) {
          configObj.excludedTasks = 'Weekly';
        }

        // Check if enforce exclusion is enabled
        if (currentUserSettings.reports.enforceZoneInclusion) {
          configObj.enforceExclusion = 'Yes';
        }

        // Check is user is receiving a custom report'
        if (currentUserSettings.reports.receiveCustomReport) {
          configObj.customReport = 'Yes';
        }
      }

      // Push to list
      reportConfigurationMetadata.push(configObj);
    });

    return {reportConfigurationMetadata: reportConfigurationMetadata, emailsList: emailsList};
  }

  async function handleSubmit() {
    // Set loading state
    dispatch(updateLoading(true));

    let updateDoc = {};
    let options = {};

    // Background
    updateDoc = {};
    const backgroundBooleansAndStrings = ['myGeotabAccess'];
    const backgroundStringArrays = ['coverageBccEmails', 'equipmentBccEmails'];

    for (let i = 0; i < backgroundBooleansAndStrings.length; i++) {
      if (reportSettings.background[backgroundBooleansAndStrings[i]] != formValues[backgroundBooleansAndStrings[i]]) {
        updateDoc[backgroundBooleansAndStrings[i]] = formValues[backgroundBooleansAndStrings[i]];
      }
    }
    for (let i = 0; i < backgroundStringArrays.length; i++) {
      if (
        new Set(reportSettings.background[backgroundStringArrays[i]]) != new Set(formValues[backgroundStringArrays[i]])
      ) {
        updateDoc[backgroundStringArrays[i]] = formValues[backgroundStringArrays[i]];
      }
    }

    // Validate request body is not empty before sending
    let updateBackgroundRequest = {success: false, status: 400, error: 'Request body is empty'};
    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      updateBackgroundRequest = fetchPostAuthSafe(
        '/settings/updateReportsBackgroundControl',
        options,
        userSettings.username,
        userSettings.databaseName
      );
    }

    // Delivery
    updateDoc = {};
    const deliveryBooleansAndStrings = ['deliveryMethod', 'coverageReportFrequency', 'equipmentReportFrequency'];
    const deliveryStringArrays = [
      'coverageEmailsDaily',
      'coverageEmailsWeekly',
      'equipmentEmailsDaily',
      'equipmentEmailsWeekly',
    ];

    for (let i = 0; i < deliveryBooleansAndStrings.length; i++) {
      if (reportSettings.delivery[deliveryBooleansAndStrings[i]] != formValues[deliveryBooleansAndStrings[i]]) {
        updateDoc[deliveryBooleansAndStrings[i]] = formValues[deliveryBooleansAndStrings[i]];
      }
    }
    for (let i = 0; i < deliveryStringArrays.length; i++) {
      if (new Set(reportSettings.delivery[deliveryStringArrays[i]]) != new Set(formValues[deliveryStringArrays[i]])) {
        updateDoc[deliveryStringArrays[i]] = formValues[deliveryStringArrays[i]];
      }
    }

    // Validate request body is not empty before sending
    let updateDeliveryRequest = {success: false, status: 400, error: 'Request body is empty'};
    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      updateDeliveryRequest = fetchPostAuthSafe(
        '/settings/updateReportsDeliveryControl',
        options,
        userSettings.username,
        userSettings.databaseName
      );
    }

    // Exceptions
    updateDoc = {};
    const exceptionsStringArrays = ['exceptionsList'];

    for (let i = 0; i < exceptionsStringArrays.length; i++) {
      if (
        new Set(reportSettings.exceptions[exceptionsStringArrays[i]]) != new Set(formValues[exceptionsStringArrays[i]])
      ) {
        updateDoc[exceptionsStringArrays[i]] = formValues[exceptionsStringArrays[i]];
      }
    }

    // Validate request body is not empty before sending
    let updateExceptionsRequest = {success: false, status: 400, error: 'Request body is empty'};
    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      updateExceptionsRequest = fetchPostAuthSafe(
        '/settings/updateReportsExceptionsControl',
        options,
        userSettings.username,
        userSettings.databaseName
      );
    }

    // Coverage Pages
    updateDoc = {};
    const coveragePagesBooleansAndStrings = [
      'fieldAlertsPage',
      'efficiencyPage',
      'blocksPage',
      'flaggedLoggersPage',
      'acresLegend',
      'diagnosticsPage',
      'vehicleSeparateFarmAndHighway',
      'vehicleSummarySort',
      'fieldSummarySort',
      'blockPagesSort',
      'fieldCoverageUser',
      'fieldCoverageImplement',
      'fieldCoverageTask',
      'extendedFieldLegends',
      'coverageShowVehicleAverageSpeed',
      'flaggedLoggersIgnoreNotActive',
      'excludeBesols',
    ];

    for (let i = 0; i < coveragePagesBooleansAndStrings.length; i++) {
      if (
        reportSettings.coveragePages[coveragePagesBooleansAndStrings[i]] !=
        formValues[coveragePagesBooleansAndStrings[i]]
      ) {
        updateDoc[coveragePagesBooleansAndStrings[i]] = formValues[coveragePagesBooleansAndStrings[i]];
      }
    }

    const staticPlots = [];
    const namesList = formValues.staticPlotsNames;
    // const coordinatesString = formValues.staticPlotsCoords;
    const coordinatesList = formValues.staticPlotsCoords;
    let allStaticPlotsValid = true;
    if (namesList.length == coordinatesList.length) {
      for (let i = 0; i < namesList.length; i++) {
        const coordinatesSetString = coordinatesList[i].substring(1, coordinatesList[i].length - 1);
        let coordinates = stringToArray(coordinatesSetString);
        coordinates = coordinates.map((item) => {
          return parseFloat(item);
        });
        if (coordinates.length != 4) {
          console.error('Four values must be specified for coordinates: ', coordinates);
          allStaticPlotsValid = false;
        } else {
          staticPlots.push({name: namesList[i], dimensions: coordinates});
        }
      }
    } else {
      console.error('Names List and Coordinates not equal lengths');
      allStaticPlotsValid = false;
    }

    if (allStaticPlotsValid) {
      updateDoc.staticPlots = staticPlots;
    }

    // Validate request body is not empty before sending
    let updateCoveragePagesRequest = {success: false, status: 400, error: 'Request body is empty'};
    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      updateCoveragePagesRequest = fetchPostAuthSafe(
        '/settings/updateReportsCoveragePagesControl',
        options,
        userSettings.username,
        userSettings.databaseName
      );
    }

    // Vehicle Filer
    updateDoc = {};
    const vehicleFilterBooleansAndStrings = [
      'coverageHideInactiveVehicles',
      'coverageSendUnfiltered',
      'coverageSendFiltered',
      'coverageExcludedVehicles',
    ];

    for (let i = 0; i < vehicleFilterBooleansAndStrings.length; i++) {
      if (
        reportSettings.vehicleFilter[vehicleFilterBooleansAndStrings[i]] !=
        formValues[vehicleFilterBooleansAndStrings[i]]
      ) {
        updateDoc[vehicleFilterBooleansAndStrings[i]] = formValues[vehicleFilterBooleansAndStrings[i]];
      }
    }

    // Validate request body is not empty before sending
    let updateVehicleFilterRequest = {success: false, status: 400, error: 'Request body is empty'};
    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      updateVehicleFilterRequest = fetchPostAuthSafe(
        '/settings/updateReportsVehicleFilterControl',
        options,
        userSettings.username,
        userSettings.databaseName
      );
    }

    // Task Filter
    updateDoc = {};
    if (new Set(reportSettings.taskFilter.daily) != new Set(formValues.taskFilterDaily)) {
      updateDoc.daily = formValues.taskFilterDaily;
    }
    if (new Set(reportSettings.taskFilter.weekly) != new Set(formValues.taskFilterWeekly)) {
      updateDoc.weekly = formValues.taskFilterWeekly;
    }

    // Validate request body is not empty before sending
    let updateTaskFilterRequest = {success: false, status: 400, error: 'Request body is empty'};
    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(updateDoc),
      };
      updateTaskFilterRequest = fetchPostAuthSafe(
        '/settings/updateReportsTaskFilterControl',
        options,
        userSettings.username,
        userSettings.databaseName
      );
    }

    // Make all calls
    const responses = await Promise.all([
      updateBackgroundRequest,
      updateDeliveryRequest,
      updateExceptionsRequest,
      updateCoveragePagesRequest,
      updateVehicleFilterRequest,
      updateTaskFilterRequest,
    ]);
    const responsesJsons = responses.map((response) => {
      // If the request was prevented from
      // being sent due to an empty request body
      // then the response will be a json object already
      try {
        return response.json();
      } catch (error) {
        if (response.success == false) {
          return response;
        }
      }
    });
    const results = await Promise.all(responsesJsons);
    for (let i = 0; i < results.length; i++) {
      if (results[i].status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
        break;
      } else if (results[i].errorMsg) {
        navigate('/error', {state: {errorMsg: results[i].errorMsg}});
      } else if (results[i].status == 400 || results[i].success == false) {
        continue; // Empty request body, just skip over
      }
    }
    await dispatch(getCustomerAndUserInfo());
    await dispatch(getSettingsData());
  }

  function generateGeneralSettings() {
    const access = userSettings.roleViewAccess['reportsDeliveryManagement'];
    const viewOnlyDelivery = !userSettings.roleAccess['reportsDeliveryManagement'];
    const viewOnlyBackground = !userSettings.roleAccess['reportsDeliveryManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='General'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='general'
            viewOnly={viewOnlyDelivery && viewOnlyBackground}
          >
            {userSettings.roleViewAccess['reportsDeliveryManagement'] && (
              <React.Fragment>
                <h6 className='mt-2 pl-3' style={{color: 'black', fontWeight: 600}}>
                  Delivery
                  {viewOnlyDelivery && (
                    <span>
                      <b> - View Only</b>
                    </span>
                  )}
                </h6>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <SettingsSelect
                      label='Delivery Method'
                      setting='deliveryMethod'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyDelivery}
                      options={[
                        {value: 'pdf', label: 'PDF'},
                        {value: 'link', label: 'Link'},
                      ]}
                    />
                    <SettingsSelect
                      label='Coverage Report Frequency'
                      setting='coverageReportFrequency'
                      multiple
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyDelivery}
                      options={[
                        {value: 'weekly', label: 'Weekly'},
                        {value: 'daily', label: 'Daily'},
                        {value: 'half', label: '12 Hrs'},
                      ]}
                    />
                    <SettingsSelect
                      label='Equipment Report Frequency'
                      setting='equipmentReportFrequency'
                      multiple
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyDelivery}
                      options={[
                        {value: 'weekly', label: 'Weekly'},
                        {value: 'daily', label: 'Daily'},
                      ]}
                    />
                  </TableBody>
                </Table>
              </React.Fragment>
            )}
            {userSettings.roleViewAccess['reportsBackgroundManagement'] && (
              <React.Fragment>
                <h6 className='mt-2 pl-3' style={{color: 'black', fontWeight: 600}}>
                  Background Settings
                  {viewOnlyBackground && (
                    <span>
                      <b> - View Only</b>
                    </span>
                  )}
                </h6>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <SettingsChipInput
                      label='Coverage Bcc Emails'
                      setting='coverageBccEmails'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='email'
                      disabled={viewOnlyBackground}
                    />
                    <SettingsChipInput
                      label='Equipment Bcc Emails'
                      setting='equipmentBccEmails'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='email'
                      disabled={viewOnlyBackground}
                    />
                    <SettingsSwitch
                      label='Geotab Alert Links'
                      setting='myGeotabAccess'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyBackground}
                    />
                  </TableBody>
                </Table>
              </React.Fragment>
            )}
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateEquipmentSettings() {
    const access =
      userSettings.roleViewAccess['reportsDeliveryManagement'] ||
      userSettings.roleViewAccess['reportsExceptionsManagement'];
    const viewOnlyDelivery = !userSettings.roleAccess['reportsDeliveryManagement'];
    const viewOnlyExceptions = !userSettings.roleAccess['reportsExceptionsManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Equipment Reports'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='equipment'
            viewOnly={viewOnlyDelivery && viewOnlyExceptions}
          >
            {userSettings.roleViewAccess['reportsDeliveryManagement'] && (
              <React.Fragment>
                <h6 className='mt-2 pl-3' style={{color: 'black', fontWeight: 600}}>
                  Mailing List
                  {viewOnlyDelivery && (
                    <span>
                      <b> - View Only</b>
                    </span>
                  )}
                </h6>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <SettingsChipInput
                      label='Daily Emails'
                      setting='equipmentEmailsDaily'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='email'
                      disabled={viewOnlyDelivery}
                    />
                    <SettingsChipInput
                      label='Weekly Emails'
                      setting='equipmentEmailsWeekly'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='email'
                      disabled={viewOnlyDelivery}
                    />
                  </TableBody>
                </Table>
              </React.Fragment>
            )}
            {userSettings.roleViewAccess['reportsExceptionsManagement'] && (
              <React.Fragment>
                <h6 className='mt-2 pl-3' style={{color: 'black', fontWeight: 600}}>
                  Control
                  {viewOnlyExceptions && (
                    <span>
                      <b> - View Only</b>
                    </span>
                  )}
                </h6>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <SettingsChipInput
                      label='Exceptions List'
                      setting='exceptionsList'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='text'
                      disabled={viewOnlyExceptions}
                    />
                  </TableBody>
                </Table>
              </React.Fragment>
            )}
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateCoverageSettings() {
    const access =
      userSettings.roleViewAccess['reportsDeliveryManagement'] ||
      userSettings.roleViewAccess['reportsCoveragePagesManagement'] ||
      userSettings.roleViewAccess['reportsVehicleFilterManagement'] ||
      userSettings.roleViewAccess['reportsTaskFilterManagement'];
    const viewOnlyDelivery = !userSettings.roleAccess['reportsDeliveryManagement'];
    const viewOnlyCoverage = !userSettings.roleAccess['reportsCoveragePagesManagement'];
    const viewOnlyVehFilter = !userSettings.roleAccess['reportsVehicleFilterManagement'];
    const viewOnlyTaskFilter = !userSettings.roleAccess['reportsTaskFilterManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Coverage Reports'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='coverage'
            viewOnly={viewOnlyDelivery && viewOnlyCoverage && viewOnlyVehFilter && viewOnlyTaskFilter}
          >
            {userSettings.roleViewAccess['reportsDeliveryManagement'] && (
              <React.Fragment>
                <h6 className='mt-2 pl-3' style={{color: 'black', fontWeight: 600}}>
                  Mailing List
                  {viewOnlyDelivery && (
                    <span>
                      <b> - View Only</b>
                    </span>
                  )}
                </h6>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <SettingsChipInput
                      label='Daily Emails'
                      setting='coverageEmailsDaily'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='email'
                      disabled={viewOnlyDelivery}
                    />
                    <SettingsChipInput
                      label='Weekly Emails'
                      setting='coverageEmailsWeekly'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='email'
                      disabled={viewOnlyDelivery}
                    />
                  </TableBody>
                </Table>
              </React.Fragment>
            )}
            {userSettings.roleViewAccess['reportsCoveragePagesManagement'] && (
              <React.Fragment>
                <h6 className='mt-2 pl-3' style={{color: 'black', fontWeight: 600}}>
                  Pages Control
                  {viewOnlyCoverage && (
                    <span>
                      <b> - View Only</b>
                    </span>
                  )}
                </h6>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <SettingsSwitch
                      label='Field Alerts Page'
                      setting='fieldAlertsPage'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Efficiency Page'
                      setting='efficiencyPage'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Blocks Page'
                      setting='blocksPage'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Flagged Loggers Page'
                      setting='flaggedLoggersPage'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Acres Legend'
                      setting='acresLegend'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Diagnostics Page'
                      setting='diagnosticsPage'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Separate Farm and Highway Vehicles'
                      setting='vehicleSeparateFarmAndHighway'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsChipInput
                      label='Static Plots Names'
                      setting='staticPlotsNames'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='text'
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsChipInput
                      label='Static Plots Coords'
                      setting='staticPlotsCoords'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='text'
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSelect
                      label='Vehicle Summary Sort Method'
                      setting='vehicleSummarySort'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                      options={[
                        {value: 'alphanumeric', label: 'Alphanumeric'},
                        {value: 'time', label: 'Time'},
                        {value: 'type', label: 'Vehicle Type'},
                      ]}
                    />
                    <SettingsSelect
                      label='Field Summary Sort Method'
                      setting='fieldSummarySort'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                      options={[
                        {value: 'alphanumeric', label: 'Alphanumeric'},
                        {value: 'time', label: 'Time'},
                      ]}
                    />
                    <SettingsSelect
                      label='Block Page Sort Method'
                      setting='blockPagesSort'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                      options={[
                        {value: 'alphanumeric', label: 'Alphanumeric'},
                        {value: 'time', label: 'Time'},
                      ]}
                    />
                    <SettingsSwitch
                      label='Field Coverage User'
                      setting='fieldCoverageUser'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Field Coverage Implement'
                      setting='fieldCoverageImplement'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Field Coverage Task'
                      setting='fieldCoverageTask'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Extended Field Legends'
                      setting='extendedFieldLegends'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Vehicle Average Speed'
                      setting='coverageShowVehicleAverageSpeed'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Ignore Not Active Flagged Loggers'
                      setting='flaggedLoggersIgnoreNotActive'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                    <SettingsSwitch
                      label='Exclude Besols'
                      setting='excludeBesols'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyCoverage}
                    />
                  </TableBody>
                </Table>
              </React.Fragment>
            )}
            {userSettings.roleViewAccess['reportsVehicleFilterManagement'] && (
              <React.Fragment>
                <h6 className='mt-2 pl-3' style={{color: 'black', fontWeight: 600}}>
                  Vehicle Filters
                  {viewOnlyVehFilter && (
                    <span>
                      <b> - View Only</b>
                    </span>
                  )}
                </h6>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <SettingsSwitch
                      label='Coverage Hide Inactive Vehicles'
                      setting='coverageHideInactiveVehicles'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyVehFilter}
                    />
                    <SettingsSwitch
                      label='Coverage Send Unfiltered'
                      setting='coverageSendUnfiltered'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyVehFilter}
                    />
                    <SettingsSwitch
                      label='Coverage Send Filtered'
                      setting='coverageSendFiltered'
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyVehFilter}
                    />
                    <SettingsSearchSelect
                      label='Coverage Excluded Vehicles'
                      setting='coverageExcludedVehicles'
                      multiple
                      formValues={formValues}
                      handleChange={handleChange}
                      disabled={viewOnlyVehFilter}
                      options={activeVehicles.map((veh) => {
                        return {value: veh.serialNumber, label: veh.name};
                      })}
                    />
                  </TableBody>
                </Table>
              </React.Fragment>
            )}
            {userSettings.roleViewAccess['reportsTaskFilterManagement'] && (
              <React.Fragment>
                <h6 className='mt-2 pl-3' style={{color: 'black', fontWeight: 600}}>
                  Task Filters
                  {viewOnlyTaskFilter && (
                    <span>
                      <b> - View Only</b>
                    </span>
                  )}
                </h6>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <SettingsChipInput
                      label='Daily'
                      setting='taskFilterDaily'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='text'
                      disabled={viewOnlyTaskFilter}
                    />
                    <SettingsChipInput
                      label='Weekly'
                      setting='taskFilterWeekly'
                      formValues={formValues}
                      handleChange={handleChange}
                      inputType='text'
                      disabled={viewOnlyTaskFilter}
                    />
                  </TableBody>
                </Table>
              </React.Fragment>
            )}
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  /*
  Reports Configuration Visualizer Components
  */

  function generateReportsConfigurationVisualizer() {
    const access =
      userSettings.roleViewAccess['reportsDeliveryManagement'] ||
      userSettings.roleViewAccess['reportsCoveragePagesManagement'] ||
      userSettings.roleViewAccess['reportsVehicleFilterManagement'] ||
      userSettings.roleViewAccess['reportsTaskFilterManagement'];
    const viewOnlyDelivery = !userSettings.roleAccess['reportsDeliveryManagement'];
    const viewOnlyCoverage = !userSettings.roleAccess['reportsCoveragePagesManagement'];
    const viewOnlyVehFilter = !userSettings.roleAccess['reportsVehicleFilterManagement'];
    const viewOnlyTaskFilter = !userSettings.roleAccess['reportsTaskFilterManagement'];
    return (
      <React.Fragment>
        {access && (
          <CollapsableSection
            title='Reports Configuration'
            collapseOpen={collapseOpen}
            setCollapseOpen={setCollapseOpen}
            section='reportsConfig'
            viewOnly={viewOnlyDelivery && viewOnlyCoverage && viewOnlyVehFilter && viewOnlyTaskFilter}
          >
            <div
              className='p-1'
              style={{height: Math.max(tableData.length * 25, 500)} /* This is to anchor pagination to the bottom*/}
            >
              <TabMenuTableWrapper
                menu={generateReportsVisualMenu()}
                table={generateReportsVisualTable()}
                pagination={
                  <CustomTablePagination
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    page={page}
                    setPage={setPage}
                  />
                }
              />
            </div>
          </CollapsableSection>
        )}
      </React.Fragment>
    );
  }

  function generateReportsVisualMenu() {
    return (
      <React.Fragment>
        <div className='pt-1 pb-0'>
          <h6 className='mt-1 pl-1' style={{color: 'black', fontWeight: 600}}>
            Reports Configuration Table
          </h6>
          <div className='pt-1 pb-2 pl-1'>
            <Button
              sx={squareButton}
              variant='ic-button'
              color='secondary'
              className='ml-1'
              title='Download CSV'
              onClick={downloadCsv}
            >
              <FontAwesomeIcon icon='fas fa-download' />
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  function generateReportsVisualTable() {
    let colorFlip = false;
    return (
      <React.Fragment>
        <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Email'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-email-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsEmail'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-email-select'
                    name={'reportVisualsEmail'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsEmail']}
                    options={filterOptions['reportVisualsEmail']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Coverage'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-coverage-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsCoverage'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-coverage-select'
                    name={'reportVisualsCoverage'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsCoverage']}
                    options={filterOptions['reportVisualsCoverage']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Equipment'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-equipment-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsEquipment'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-equipment-select'
                    name={'reportVisualsEquipment'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsEquipment']}
                    options={filterOptions['reportVisualsEquipment']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Vehicles Excluded'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-vehicles-excluded-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsExcludedVeh'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-vehicles-excluded-select'
                    name={'reportVisualsExcludedVeh'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsExcludedVeh']}
                    options={filterOptions['reportVisualsExcludedVeh']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Blocks Excluded'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-blocks-excluded-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsExcludedBlocks'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-blocks-excluded-select'
                    name={'reportVisualsExcludedBlocks'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsExcludedBlocks']}
                    options={filterOptions['reportVisualsExcludedBlocks']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Fields Excluded'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-fields-excluded-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsExcludedFields'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-fields-excluded-select'
                    name={'reportVisualsExcludedFields'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsExcludedFields']}
                    options={filterOptions['reportVisualsExcludedFields']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Regions Excluded'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-regions-excluded-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsExcludedRegions'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-regions-excluded-select'
                    name={'reportVisualsExcludedRegions'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsExcludedRegions']}
                    options={filterOptions['reportVisualsExcludedRegions']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Task Filter'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-excluded-tasks-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsExcludedTasks'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-excluded-tasks-select'
                    name={'reportVisualsExcludedTasks'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsExcludedTasks']}
                    options={filterOptions['reportVisualsExcludedTasks']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Exclusion Enforced'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-exclusion-enforced-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsEnforceExclusion'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-exclusion-enforced-select'
                    name={'reportVisualsEnforceExclusion'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsEnforceExclusion']}
                    options={filterOptions['reportVisualsEnforceExclusion']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Custom Report'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-custom-report-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsCustomReport'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-custom-report-select'
                    name={'reportVisualsCustomReport'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsCustomReport']}
                    options={filterOptions['reportVisualsCustomReport']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={100}>
                  <CircularProgress className='mt-4 mx-auto d-block' size={80} />
                </TableCell>
              </TableRow>
            ) : (
              <React.Fragment>
                {(rowsPerPage > 0
                  ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : tableData
                ).map((configObj) => {
                  colorFlip = !colorFlip;
                  const rowKey = configObj.email;
                  return (
                    <TableRow key={rowKey} sx={{backgroundColor: colorFlip ? 'rgba(0, 0, 0, 0.05)' : ''}}>
                      <TableCell>{configObj.email}</TableCell>
                      <TableCell>{configObj.coverage}</TableCell>
                      <TableCell>{configObj.equipment}</TableCell>
                      <TableCell>{configObj.excludedVehicles}</TableCell>
                      <TableCell>{configObj.excludedBlocks}</TableCell>
                      <TableCell>{configObj.excludedFields}</TableCell>
                      <TableCell>{configObj.excludedRegions}</TableCell>
                      <TableCell>{configObj.excludedTasks}</TableCell>
                      <TableCell>{configObj.enforceExclusion}</TableCell>
                      <TableCell>{configObj.customReport}</TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  function downloadCsv() {
    const baseUrl = window.location.origin;

    const csvList = [];
    tableData.forEach((configObj) => {
      const row = [
        configObj.email,
        configObj.coverage,
        configObj.equipment,
        configObj.excludedVehicles,
        configObj.excludedBlocks,
        configObj.excludedFields,
        configObj.excludedRegions,
        configObj.excludedTasks,
        configObj.enforceExclusion,
        configObj.customReport,
      ];
      csvList.push(row);
    });

    const filename = 'reportsConfigurations';
    const csvHeaders = [
      'email',
      'coverage',
      'equipment',
      'excludedVehicles',
      'excludedBlocks',
      'excludedFields',
      'excludedRegions',
      'excludedTasks',
      'enforceExclusion',
      'customReport',
    ];

    generateCsv(filename, csvHeaders, csvList);
  }

  /**
   * Returns generated page
   */

  return (
    <div className='tab-wrapper'>
      <div className='mt-1'>
        {(userSettings.roleAccess['reportsBackgroundManagement'] ||
          userSettings.roleAccess['reportsDeliveryManagement'] ||
          userSettings.roleAccess['reportsExceptionsManagement'] ||
          userSettings.roleAccess['reportsCoveragePagesManagement'] ||
          userSettings.roleAccess['reportsVehicleFilterManagement'] ||
          userSettings.roleAccess['reportsTaskFilterManagement']) && (
          <React.Fragment>
            <Button variant='ic-button' color='secondary' title='Reset changes' onClick={resetFormValues}>
              Reset
            </Button>
            <Button
              variant='ic-button'
              color='primary'
              title='Submit changes'
              className='ml-1'
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit
            </Button>
          </React.Fragment>
        )}
      </div>
      {loading ? (
        <CircularProgress className='mt-2 mx-auto d-block' size={80} />
      ) : (
        <React.Fragment>
          <div className='tab-wrapper overflow-auto'>
            {/* GENERAL */}
            {generateGeneralSettings()}

            {/* Equipment Reports */}
            {generateEquipmentSettings()}

            {/* Coverage Reports */}
            {generateCoverageSettings()}

            {/* Reports Config Visualizer*/}
            {generateReportsConfigurationVisualizer()}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export {CoverageEquipmentTab};
