import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Table, TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {Tailselect} from '../../../components/Tailselect';
import {searchFind} from '../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {setInitialValues} from './upcomingModalSlice';
import MyPagination from '../../../components/MyPagination';

function UpcomingTable(props) {
  const dispatch = useDispatch();

  const MAX_ITEMS_PER_PAGE = 50;
  const columnClass = ['upcoming-col-1', 'upcoming-col-2', 'upcoming-col-3', 'upcoming-col-4', 'upcoming-col-5'];

  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });

  const [items, setItems] = useState(0); // Number of pages in pagination
  const [curPage, setCurPage] = useState(1); // Current page in pagination
  const [tableData, setTableData] = useState([]); // Partial data to render in table
  const [units, setUnits] = useState('km');
  const [filters, setFilters] = useState({
    vehicles: [],
    tasks: [],
  });

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  useEffect(() => {
    filterTableRows(props.tableData);
  }, [props.tableData, props.reminderFilter, curPage, filters, searchText]);

  const afterPageClicked = (pageNumber) => {
    // Update Pagination Page number
    setCurPage(pageNumber);
  };

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  async function filterTableRows(tablerow) {
    // Filter props.tablerows based on filters and pagination to render limited amount of data on tables
    // Promise.all is used to avoid desync between states update and rerendering due to slow filter on large array
    await Promise.all(
      // Generate an array based on rowFilter function (return true or false) base on filters
      tablerow.map((rowData) => {
        return rowFilter(rowData, <div>{JSON.stringify(rowData)}</div>);
      })
    ).then((result) => {
      // Filter rows of data based on filter results
      const resultRows = tablerow.filter((row, index) => {
        return result[index] === true;
      });

      // Updating Pagination States based on number of items/rows of data
      let numPages = Math.floor(resultRows.length / MAX_ITEMS_PER_PAGE);
      if (resultRows.length % MAX_ITEMS_PER_PAGE != 0) {
        numPages++;
      }
      setItems(numPages);
      // Navigate to the 1st page if current page is larger than total number of pages
      if (curPage > numPages) {
        setCurPage(1);
      }

      // Update tableData State to render partial rows of data from pagination
      setTableData(resultRows.slice(curPage * MAX_ITEMS_PER_PAGE - MAX_ITEMS_PER_PAGE, curPage * MAX_ITEMS_PER_PAGE));
    });
  }

  function rowFilter(rowData, row) {
    const vehiclesInFilter = filters.vehicles.includes(rowData.vehicle) || filters.vehicles.length == 0;
    const reminderInFilter =
      (props.reminderFilter.includes('overdue') && rowData.overdue) ||
      (props.reminderFilter.includes('upcoming') && rowData.upcoming && !rowData.overdue) ||
      props.reminderFilter.length == 0;
    const taskInFilter = filters.tasks.includes(rowData.task) || filters.tasks.length == 0;
    const search = searchFind(row, searchText.toLowerCase().trim());

    return vehiclesInFilter && reminderInFilter && taskInFilter && search;
  }

  function generateTableData() {
    return (
      <tbody id='upcoming-table-body'>
        {tableData.map((rowData, index) => {
          if (!rowData) {
            return null;
          }
          const row = (
            <tr
              key={index}
              className={
                rowData.overdue
                  ? 'border-left-danger-wide'
                  : rowData.upcoming
                  ? 'border-left-warning-wide'
                  : 'border-left-none'
              }
              onClick={() => {
                if ($(window).width() <= 767) {
                  dispatch(setInitialValues(rowData));
                  $('#shopview-upcoming-data-modal').modal('show');
                }
              }}
            >
              <td className={columnClass[0]}>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-toggle='modal'
                  data-target='#shopview-complete-task-modal'
                  onClick={() => {
                    dispatch(setInitialValues(rowData));
                  }}
                >
                  <FontAwesomeIcon icon='far fa-square' />
                </button>
              </td>
              <td className={columnClass[1]}>
                <div className='mb-2'>
                  <div className='font-weight-bold'>{rowData.vehicle}</div>
                </div>
                {/* <div>
                  <span className={rowData.overdue ? 'rounded-sm p-1 bg-danger text-light' :
                    rowData.upcoming ? 'rounded-sm p-1 bg-warning text-light' : ''}>
                    {rowData.overdue ? 'Overdue' : rowData.upcoming ? 'Upcoming' : ''}
                  </span>
                </div> */}
              </td>
              <td className={columnClass[2]}>
                <div>
                  <div className='font-weight-bold'>{rowData.task}</div>
                </div>
                <div className='ml-2 text-xs upcoming-service-interval'>
                  <div className='text-secondary' dangerouslySetInnerHTML={{__html: rowData.intervalString}}></div>
                </div>
                <div className='upcoming-due-within-service'>
                  <div>
                    <strong>Due in:</strong>
                    <div className='ml-2 text-xs' dangerouslySetInnerHTML={{__html: rowData.dueString}} />
                  </div>
                </div>
              </td>
              <td className={columnClass[3]}>
                <div>
                  <div
                    className='text-secondary font-weight-normal'
                    dangerouslySetInnerHTML={{__html: rowData.dueString}}
                  />
                </div>
              </td>
              <td className={columnClass[4]}>
                {rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold'>Task has no History</div>
                  </div>
                )}
                {!rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold d-inline'>Completed on: </div>
                    <div className='text-primary font-weight-normal d-inline'>{rowData.lastCompletedDate}</div>
                    <div className='d-inline'> | {rowData.lastCompletedTimeDiff}</div>
                  </div>
                )}
                {!rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold d-inline'>Reviewed By: </div>
                    <div className='d-inline'>{rowData.reviewedBy}</div>
                  </div>
                )}
                {!rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold d-inline'>Odometer When Complete: </div>
                    <div className='d-inline'>
                      {rowData.lastCompletedOdometer === '' ? '' : rowData.lastCompletedOdometer + ` ${units}`}
                    </div>
                  </div>
                )}
                {!rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold d-inline'>Engine Hours When Complete: </div>
                    <div className='d-inline'>
                      {rowData.lastCompletedEngineHours === '' ? '' : rowData.lastCompletedEngineHours + ' hrs'}
                    </div>
                  </div>
                )}
              </td>
            </tr>
          );
          return row;
        })}
      </tbody>
    );
  }

  function generateTableHeaders() {
    return (
      <tr>
        <th className={columnClass[0]} width='5%'>
          Done
        </th>
        <th className={columnClass[1]} width='20%'>
          <TableHeadFilterButtonTitleWrapper title='Equipment'>
            <button
              className='btn btn-transparent btn-sm'
              onClick={() => {
                return tail.select('#shopview-upcoming-vehicle-select').toggle();
              }}
            >
              <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.vehicles.length > 0 && '#4e73df'}} />
            </button>
          </TableHeadFilterButtonTitleWrapper>
          <TableHeadHiddenDropdownWrapper>
            <Tailselect
              id='shopview-upcoming-vehicle-select'
              name='vehicles'
              multiple={true}
              search={true}
              value={filters.vehicles}
              options={props.filterOptions.vehicles}
              onChange={handleFilters}
            />
          </TableHeadHiddenDropdownWrapper>
        </th>
        <th className={columnClass[2]} width='20%'>
          <TableHeadFilterButtonTitleWrapper title='Service'>
            <button
              className='btn btn-transparent btn-sm'
              onClick={() => {
                return tail.select('#shopview-upcoming-task-select').toggle();
              }}
            >
              <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.tasks.length > 0 && '#4e73df'}} />
            </button>
          </TableHeadFilterButtonTitleWrapper>
          <TableHeadHiddenDropdownWrapper>
            <Tailselect
              id='shopview-upcoming-task-select'
              name='tasks'
              multiple={true}
              search={true}
              value={filters.tasks}
              options={props.filterOptions.tasks}
              onChange={handleFilters}
            />
          </TableHeadHiddenDropdownWrapper>
        </th>
        <th className={columnClass[3]} width='20%'>
          Due In
        </th>
        <th className={columnClass[4]} width='35%'>
          Last Completed
        </th>
      </tr>
    );
  }

  return (
    <div className='table-responsive'>
      <Table headers={generateTableHeaders()} body={generateTableData()} loading={props.loading} />
      <MyPagination
        totPages={items}
        currentPage={curPage}
        pageClicked={(ele) => {
          afterPageClicked(ele);
        }}
      ></MyPagination>
    </div>
  );
}

export {UpcomingTable};
