import React, {useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalWideColumnSection,
  ModalBody,
} from '../../../components/Modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function UpcomingDataModal(props) {
  const initialValues = useSelector((state) => {
    return state.upcomingModal;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });

  const [units, setUnits] = useState('km');

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  return createPortal(
    <ModalFramework id='shopview-upcoming-data-modal'>
      <ModalHeader title='Service Details'>
        <FontAwesomeIcon
          icon='fas fa-circle'
          style={{color: initialValues.overdue ? '#e74a3b' : initialValues.upcoming ? '#f6c23e' : '#d1d3e2'}}
        />
        <span className='h5 pl-1'>{initialValues.vehicle}</span>
        <div className='small'>{initialValues.task}</div>
        {initialValues.serviceTask.notes && (
          <div className='text-xs font-italic text-break'>{initialValues.serviceTask.notes}</div>
        )}
      </ModalHeader>
      <ModalBody>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Interval</div>
            <div className='small pl-2' dangerouslySetInnerHTML={{__html: initialValues.intervalString}} />
          </ModalWideColumnSection>
        </ModalRowSection>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Due in</div>
            <div className='small pl-2' dangerouslySetInnerHTML={{__html: initialValues.dueString}} />
          </ModalWideColumnSection>
        </ModalRowSection>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Last Completed</div>
            <div className='pl-2'>
              {initialValues.noHistory && (
                <div>
                  <div className='text-secondary small'>Task has no History</div>
                </div>
              )}
              {!initialValues.noHistory && (
                <div>
                  <div className='d-inline small'>Completed on: </div>
                  <div className='text-primary d-inline small'>{initialValues.lastCompletedDate}</div>
                  <div className='d-inline small'> | {initialValues.lastCompletedTimeDiff}</div>
                </div>
              )}
              {!initialValues.noHistory && (
                <div>
                  <div className='d-inline small'>Reviewed By: </div>
                  <div className='d-inline small'>{initialValues.reviewedBy}</div>
                </div>
              )}
              {!initialValues.noHistory && (
                <div>
                  <div className='d-inline small'>Odometer: </div>
                  <div className='d-inline small'>
                    {initialValues.lastCompletedOdometer === ''
                      ? ''
                      : initialValues.lastCompletedOdometer + ` ${units}`}
                  </div>
                </div>
              )}
              {!initialValues.noHistory && (
                <div>
                  <div className='d-inline small'>Engine Hours: </div>
                  <div className='d-inline small'>
                    {initialValues.lastCompletedEngineHours === ''
                      ? ''
                      : initialValues.lastCompletedEngineHours + ' hrs'}
                  </div>
                </div>
              )}
            </div>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter onSubmit={completeModal} submitText='Complete Service' />
    </ModalFramework>,
    document.getElementById('app')
  );

  function completeModal() {
    // Close data modal
    $('#shopview-upcoming-data-modal').modal('hide');
    // Open task complete modal with a timeout to allow animation to complete
    setTimeout(function () {
      $('#shopview-complete-task-modal').modal('show');
    }, 350);
  }
}

export {UpcomingDataModal};
