import React from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalWideColumnSection,
  ModalBody,
} from '../../../components/Modal';

function HistoryDataModal(props) {
  const initialValues = useSelector((state) => {
    return state.historyModal;
  });

  return createPortal(
    <ModalFramework id='shopview-history-data-modal'>
      <ModalHeader title='History Details'>
        <span className='h5'>{initialValues.vehicle}</span>
        <div className='small'>{initialValues.task}</div>
        {initialValues.serviceEntry?.parentTask?.notes && (
          <div className='text-xs font-italic text-break'>{initialValues.serviceEntry.parentTask.notes}</div>
        )}
        <p className='mb-n2 h5 small'>
          <strong>Updated By: </strong>
          {initialValues.user.includes('_icadmin') ? 'IntelliCulture Admin' : initialValues.user}
        </p>
      </ModalHeader>
      <ModalBody>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Date</div>
            <div className='small pl-2'>{initialValues.date}</div>
          </ModalWideColumnSection>
        </ModalRowSection>
        {initialValues.completedString && (
          <ModalRowSection>
            <ModalWideColumnSection>
              <div className='font-weight-bold border-bottom'>Completion</div>
              <div className='small pl-2' dangerouslySetInnerHTML={{__html: initialValues.completedString}} />
            </ModalWideColumnSection>
          </ModalRowSection>
        )}
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Service Details</div>
            <div className='pl-2'>
              <div>
                <div className='d-inline small'>Work Order: </div>
                <div className='d-inline small'>{initialValues.workOrder}</div>
              </div>
              <div>
                <div className='d-inline small'>Invoice Number: </div>
                <div className='d-inline small'>{initialValues.invoice}</div>
              </div>
              <div>
                <div className='d-inline small'>Labor Cost: </div>
                <div className='d-inline small'>
                  {initialValues.laborCost == '' ? '' : '$' + initialValues.laborCost}
                </div>
              </div>
              <div>
                <div className='d-inline small'>Parts Cost: </div>
                <div className='d-inline small'>
                  {initialValues.partsCost == '' ? '' : '$' + initialValues.partsCost}
                </div>
              </div>
              <div>
                <div className='d-inline small'>Labor Time: </div>
                <div className='d-inline small'>
                  {initialValues.laborTime == '' ? '' : initialValues.laborTime + ' hrs'}
                </div>
              </div>
              <div>
                <div className='d-inline small'>Supplier Name: </div>
                <div className='d-inline small'>{initialValues.supplier}</div>
              </div>
            </div>
          </ModalWideColumnSection>
        </ModalRowSection>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Additional Details</div>
            <div className='pl-2'>
              <div>
                <div className='d-inline small'>Reviewed By: </div>
                <div className='d-inline small'>{initialValues.reviewedBy}</div>
              </div>
              <div>
                <div className='d-inline small'>Notes: </div>
                <div className='d-inline small'>{initialValues.notes}</div>
              </div>
            </div>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter onSubmit={completeModal} submitText='Edit History' />
    </ModalFramework>,
    document.getElementById('app')
  );

  function completeModal() {
    // Close data modal
    $('#shopview-history-data-modal').modal('hide');
    // Open edit history modal with a timeout to allow animation to complete
    setTimeout(function () {
      $('#shopview-edit-history-modal').modal('show');
    }, 350);
  }
}

export {HistoryDataModal};
