import React, {useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalWideColumnSection,
  ModalBody,
} from '../../components/Modal';
import {capitalizeFirstLetter, formatTime} from '../../app/utils';
import {DateTime} from 'luxon';

function CoverageDataModal(props) {
  const initialValues = useSelector((state) => {
    return state.coverageModal;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const dates = useSelector((state) => {
    return state.cropview.dates;
  });
  const todayOnly = useSelector((state) => {
    return state.cropview.todayOnly;
  });

  if (initialValues.type == 'equipment') {
    return createPortal(
      <ModalFramework id='cropview-coverage-data-modal'>
        <ModalHeader title='Coverage Details' />
        <ModalBody>
          <ModalRowSection>
            <ModalWideColumnSection>
              <div className='font-weight-bold border-bottom'>Equipment</div>
              <div className='small'>{initialValues.equipment}</div>
            </ModalWideColumnSection>
          </ModalRowSection>
          <ModalRowSection>
            <ModalWideColumnSection>
              <div className='font-weight-bold border-bottom'>Time</div>
              <div className='small'>
                <span className='font-weight-bold'>In Field Time: </span>
                <span>{initialValues.timeInField}</span>
              </div>
              {customerSettings.general.outOfFieldTime && (
                <div className='small'>
                  <span className='font-weight-bold'>Out of Field Time: </span>
                  <span>{initialValues.timeOutField}</span>
                </div>
              )}
              {customerSettings.general.idleTime && (
                <div className='small'>
                  <span className='font-weight-bold'>Idle Time: </span>
                  <span>{initialValues.timeIdle}</span>
                </div>
              )}
              <div className='small'>
                <span className='font-weight-bold'>Total Time: </span>
                <span>{initialValues.timeTotal}</span>
              </div>
            </ModalWideColumnSection>
          </ModalRowSection>
          <ModalRowSection>
            <ModalWideColumnSection>
              <div className='font-weight-bold border-bottom'>Acreage</div>
              <div className='small'>{initialValues.coverage}</div>
            </ModalWideColumnSection>
          </ModalRowSection>
          {todayOnly && (
            <ModalRowSection>
              <ModalWideColumnSection>
                <div className='font-weight-bold border-bottom'>Task</div>
                <div className='small'>{initialValues.task}</div>
              </ModalWideColumnSection>
            </ModalRowSection>
          )}
        </ModalBody>
        <ModalFooter />
      </ModalFramework>,
      document.getElementById('app')
    );
  }

  return createPortal(
    <ModalFramework id='cropview-coverage-data-modal'>
      <ModalHeader title='Coverage Details' />
      <ModalBody>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>{capitalizeFirstLetter(initialValues.type)}</div>
            <div className='small'>{initialValues.zone}</div>
          </ModalWideColumnSection>
        </ModalRowSection>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Coverage</div>
            <div>
              <div className='d-inline small'>Time: </div>
              <div className='d-inline small'>{initialValues.time}</div>
            </div>
            <div>
              <div className='d-inline small'>Area: </div>
              <div className='d-inline small'>{initialValues.coverage}</div>
            </div>
          </ModalWideColumnSection>
        </ModalRowSection>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Equipment Usage</div>
            <div>{listEquipment()}</div>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter />
    </ModalFramework>,
    document.getElementById('app')
  );

  function listEquipment() {
    return initialValues.fullEquipmentList.map((equipment, indexEquip) => {
      return equipment.taskList.map((task, indexTask) => {
        // Calculate acreage rate
        let equipmentAcreageRate = 0;
        if (task.timeTotal) {
          equipmentAcreageRate = (task.acreTotal / task.timeTotal) * 60;
        }

        const entryTime = DateTime.fromISO(task.entryTime).setZone(customerSettings.general.timeZone);
        const exitTime = DateTime.fromISO(task.exitTime).setZone(customerSettings.general.timeZone);
        const selectedDateRangeSameDay = DateTime.fromISO(dates.start)
          .setZone(customerSettings.general.timeZone)
          .hasSame(DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone), 'day');
        const entryExitSameDay = entryTime.hasSame(exitTime, 'day');
        return (
          <div key={indexTask}>
            <span className='font-weight-bold small'>{equipment.name}</span>
            <span className='font-weight-bold small'>
              {task.name ? ' | ' : ''}
              {task.name}:{' '}
            </span>
            <span className='small'>{formatTime(task.timeTotal)}</span>
            <span className='small'> | {Math.round(task.acreTotal * 10) / 10} ac</span>
            <span className='small'> | {Math.round(equipmentAcreageRate * 10) / 10} ac/hr</span>
            {customerSettings.cropview.vehicleAverageSpeedEnabled && userSettings.general.units == 'imperial' ? (
              <span className='small'> | {(equipment.avgSpeed * 0.621).toFixed(1)} mph</span>
            ) : (
              customerSettings.cropview.vehicleAverageSpeedEnabled &&
              userSettings.general.units == 'metric' && (
                <span className='small'> | {equipment.avgSpeed.toFixed(1)} kph</span>
              )
            )}
            {customerSettings.general.zoneEntryAndExitTimeEnabled &&
              (selectedDateRangeSameDay && entryExitSameDay ? (
                <span className='small'>
                  {' '}
                  | {entryTime.toFormat('h:mm a')} - {exitTime.toFormat('h:mm a')}
                </span>
              ) : (
                <span className='small'>
                  {' '}
                  | {entryTime.toFormat('dd/LL/yy h:mm a')} - {exitTime.toFormat('dd/LL/yy h:mm a')}
                </span>
              ))}
          </div>
        );
      });
    });
  }
}

export {CoverageDataModal};
