import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, unitsDisplayConversion, deepCopy, isNumeric} from '../../../app/utils';
import {Tailselect} from '../../../components/Tailselect';
import {genEfficiencyData} from '../dashboardDataProcessing';
import {KpiTooltip, ScorecardSelectHolder} from '../dashboardUtils';
import {Select, MenuItem, Checkbox, FormControlLabel, CircularProgress, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ChartJS from 'chart.js/auto';
import Tooltip from '@mui/material/Tooltip';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiEfficiency(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const taskConfigs = useSelector((state) => {
    return state.dashboard.taskConfigs;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const [toolTipOpen, setTooltipOpen] = useState(false);
  const [selectedInnerSubset, setSelectedInnerSubset] = useState('Total');
  const [selectedZoneLevel, setSelectedZoneLevel] = useState('Region');
  const [targetsObject, setTargetsObject] = useState({});
  const [efficiencyData, setEfficiencyData] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [displayedAxis, setDisplayedAxis] = useState('Ac/Hr');
  const [distanceUnits, setDistanceUnits] = useState('km');
  const [speedUnits, setSpeedUnits] = useState('kph');
  const [scaleId, setScaleId] = useState('AcHr');
  const [hourDollarRate, setHourDollarRate] = useState('');
  const [acreDollarRate, setAcreDollarRate] = useState('');
  const [innerSubsetOptions, setInnerSubsetOptions] = useState([]);
  const [efficiencyFilters, setEfficiencyFilters] = useState({
    efficiencyZoneNames: {
      'Block': [],
      'Field': [],
      'Region': [],
    },
    efficiencyZoneSort: 'Alphabetical',
    efficiencyZoneCount: '10',
    efficiencyZones: [],
    efficiencyRowSpacing: [],
  });
  const [zoneFilterOptions, setZoneFilterOptions] = useState([]);

  useEffect(() => {
    if (selectedZoneLevel == 'Block' && operationsData != undefined && operationsData.hasOwnProperty('blocksObject')) {
      const efficiencyBlocks = Object.keys(deepCopy(operationsData.blocksObject));
      efficiencyBlocks.sort();
      setZoneFilterOptions(efficiencyBlocks);
    }
    if (selectedZoneLevel == 'Field' && operationsData != undefined && operationsData.hasOwnProperty('fieldsObject')) {
      const efficiencyFields = Object.keys(deepCopy(operationsData.fieldsObject));
      efficiencyFields.sort();
      setZoneFilterOptions(efficiencyFields);
    }
    if (
      selectedZoneLevel == 'Region' &&
      operationsData != undefined &&
      operationsData.hasOwnProperty('regionsObject')
    ) {
      const efficiencyRegions = Object.keys(deepCopy(operationsData.regionsObject));
      efficiencyRegions.sort();
      setZoneFilterOptions(efficiencyRegions);
    }
  }, [selectedZoneLevel, operationsData]);

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [
    operationsData,
    taskConfigs,
    selectedZoneLevel,
    selectedInnerSubset,
    efficiencyFilters,
    displayedAxis,
    acreDollarRate,
    hourDollarRate,
  ]);

  useEffect(() => {
    let distUnits = 'km';
    if (unitsSystem == 'imperial') {
      distUnits = 'mi';
    }
    setDistanceUnits(distUnits);

    let spdUnits = 'kph';
    if (unitsSystem == 'imperial') {
      spdUnits = 'mph';
    }
    setSpeedUnits(spdUnits);
  }, [unitsSystem]);

  useEffect(() => {
    if (props.pageStates?.selectedInnerSubset) {
      setSelectedInnerSubset(props.pageStates?.selectedInnerSubset);
    } else {
      setSelectedInnerSubset('Total');
    }
    if (props.pageStates?.selectedZoneLevel) {
      setSelectedZoneLevel(props.pageStates?.selectedZoneLevel);
    } else {
      setSelectedZoneLevel('Region');
    }
    if (props.pageStates?.displayedAxis) {
      setDisplayedAxis(props.pageStates?.displayedAxis);
    } else {
      setDisplayedAxis('Ac/Hr');
    }
    if (props.pageStates?.scaleId) {
      setScaleId(props.pageStates?.scaleId);
    } else {
      setScaleId('AcHr');
    }
    if (props.pageStates?.hourDollarRate) {
      setHourDollarRate(props.pageStates?.hourDollarRate);
    } else {
      setHourDollarRate('');
    }
    if (props.pageStates?.acreDollarRate) {
      setAcreDollarRate(props.pageStates?.acreDollarRate);
    } else {
      setAcreDollarRate('');
    }

    if (props?.pageFilters) {
      setEfficiencyFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageStates, props.pageFilters]);

  function handleHourDollarRateChange(e) {
    if (isNumeric(e.target.value) || e.target.value == '') {
      setAcreDollarRate('');
      setHourDollarRate(e.target.value);
      setDisplayedAxis('Total $');
    }
    if (e.target.value == '' && acreDollarRate == '') {
      setDisplayedAxis('Ac/Hr');
    }
  }
  function handleAcreDollarRateChange(e) {
    if (isNumeric(e.target.value) || e.target.value == '') {
      setHourDollarRate('');
      setAcreDollarRate(e.target.value);
      setDisplayedAxis('Total $');
    }
    if (e.target.value == '' && hourDollarRate == '') {
      setDisplayedAxis('Ac/Hr');
    }
  }

  function generateData() {
    // Get targets
    const targetsObject = {};
    Object.keys(taskConfigs).forEach((taskId) => {
      if (taskConfigs[taskId].hasOwnProperty('acPerHrTarget') || taskConfigs[taskId].hasOwnProperty('avgSpeedTarget')) {
        if (!targetsObject.hasOwnProperty(taskId)) {
          targetsObject[taskId] = {};
        }
        targetsObject[taskId]['Ac/Hr'] = taskConfigs[taskId].acPerHrTarget;
        targetsObject[taskId]['Hr/Ac'] =
          taskConfigs[taskId].acPerHrTarget != 0 && 1 / taskConfigs[taskId].acPerHrTarget;
        targetsObject[taskId]['Speed'] =
          unitsDisplayConversion(taskConfigs[taskId].avgSpeedTarget * 100, distanceUnits) / 100;
      }
    });
    setTargetsObject(targetsObject);
    if (
      props.hasOwnProperty('pageStates') &&
      props.pageStates != undefined &&
      props.pageStates.hasOwnProperty('pageTargets')
    ) {
      setTargetsObject(props.pageStates.pageTargets);
    }

    const efficiencyTasksObject = deepCopy(operationsData.tasksObject);
    const efficiencyBlocksObject = deepCopy(operationsData.blocksObject);
    const efficiencyFieldsObject = deepCopy(operationsData.fieldsObject);
    const efficiencyRegionsObject = deepCopy(operationsData.regionsObject);

    Object.keys(efficiencyTasksObject).forEach((task) => {
      Object.keys(efficiencyTasksObject[task]).forEach((zoneType) => {
        Object.keys(efficiencyTasksObject[task][zoneType]).forEach((zone) => {
          // Zone in filter or the filters for this zonetype are length 0
          const zoneInFilter =
            efficiencyFilters.efficiencyZoneNames[zoneType].includes(zone) ||
            efficiencyFilters.efficiencyZoneNames[zoneType].length == 0;

          if (efficiencyTasksObject[task][zoneType][zone]['acreage'] == 0 || !zoneInFilter) {
            delete efficiencyTasksObject[task][zoneType][zone];
          }
        });
      });
    });

    const filteredRowSpacing = efficiencyFilters.efficiencyRowSpacing.map((rowSpacing) => {
      return parseFloat(rowSpacing);
    });
    Object.keys(efficiencyBlocksObject).forEach((zone) => {
      const zoneNameInFilter =
        efficiencyFilters.efficiencyZoneNames['Block'].includes(zone) ||
        efficiencyFilters.efficiencyZoneNames['Block'].length == 0;

      let zoneRowSpacingInFilter = true;

      if (efficiencyFilters.efficiencyRowSpacing.length > 0) {
        const blockRowSpacing = efficiencyBlocksObject[zone].row_spacing_meters;
        const blockRowSpacingNumeric = isNumeric(blockRowSpacing) ? Math.round(blockRowSpacing * 100) / 100 : 0;
        zoneRowSpacingInFilter = filteredRowSpacing.includes(blockRowSpacingNumeric);
      }

      const zoneInFilter = zoneNameInFilter && zoneRowSpacingInFilter;
      if (efficiencyBlocksObject[zone]['acreage'] == 0 || !zoneInFilter) {
        delete efficiencyBlocksObject[zone];
      }
    });
    Object.keys(efficiencyFieldsObject).forEach((zone) => {
      const zoneInFilter =
        efficiencyFilters.efficiencyZoneNames['Field'].includes(zone) ||
        efficiencyFilters.efficiencyZoneNames['Field'].length == 0;
      if (efficiencyFieldsObject[zone]['acreage'] == 0 || !zoneInFilter) {
        delete efficiencyFieldsObject[zone];
      }
    });
    Object.keys(efficiencyRegionsObject).forEach((zone) => {
      const zoneInFilter =
        efficiencyFilters.efficiencyZoneNames['Region'].includes(zone) ||
        efficiencyFilters.efficiencyZoneNames['Region'].length == 0;
      if (efficiencyRegionsObject[zone]['acreage'] == 0 || !zoneInFilter) {
        delete efficiencyRegionsObject[zone];
      }
    });

    // TODO
    const combined = deepCopy(selectedInnerSubset);
    const combinedObj = {};
    if (combined.constructor === Array && combined.length > 1) {
      combined.forEach((id) => {
        if (efficiencyTasksObject.hasOwnProperty(id)) {
          const curItem = deepCopy(efficiencyTasksObject[id]);
          Object.keys(curItem).forEach((zoneType) => {
            if (!combinedObj.hasOwnProperty(zoneType)) {
              combinedObj[zoneType] = {};
            }
            Object.keys(curItem[zoneType]).forEach((zone) => {
              if (!combinedObj[zoneType].hasOwnProperty(zone)) {
                combinedObj[zoneType][zone] = deepCopy(curItem[zoneType][zone]);
              } else {
                Object.keys(curItem[zoneType][zone]).forEach((key) => {
                  combinedObj[zoneType][zone][key] += deepCopy(curItem[zoneType][zone][key]);
                });
              }
              combinedObj[zoneType][zone]['acPerHr'] =
                combinedObj[zoneType][zone]['acreage'] / combinedObj[zoneType][zone]['duration'];
              combinedObj[zoneType][zone]['avgSpeed'] =
                combinedObj[zoneType][zone]['distance'] / combinedObj[zoneType][zone]['duration'];
            });
          });
        }
      });
      if (Object.keys(combinedObj).length > 0) {
        efficiencyTasksObject['multiSelectedCombined'] = combinedObj;
      }
    }
    //
    // Generate data

    const efficiencyDataTemp = genEfficiencyData(
      efficiencyTasksObject,
      efficiencyBlocksObject,
      efficiencyFieldsObject,
      efficiencyRegionsObject,
      efficiencyFilters,
      displayedAxis,
      unitsSystem,
      customerSettings.dashboard.kpiEfficiencyHoverDetailsEnabled,
      hourDollarRate,
      acreDollarRate,
      taskConfigs
    );
    if (
      selectedInnerSubset.constructor === Array &&
      selectedInnerSubset.length > 1 &&
      efficiencyDataTemp[selectedZoneLevel].hasOwnProperty('multiSelectedCombined')
    ) {
      // Set data
      setEfficiencyData(efficiencyDataTemp[selectedZoneLevel]['multiSelectedCombined']);

      // Set datasets
      const datasetsIncluded = [];
      efficiencyDataTemp[selectedZoneLevel]['multiSelectedCombined'].datasets.forEach((dataset) => {
        if (
          displayedAxis == dataset.label ||
          (dataset.label == 'Target' &&
            targetsObject.hasOwnProperty('multiSelectedCombined') &&
            displayedAxis != 'Total $' &&
            targetsObject['multiSelectedCombined'][[displayedAxis]] != 0)
        ) {
          datasetsIncluded.push(dataset);
        }
      });

      setDatasets(datasetsIncluded);
    } else if (
      Object.prototype.hasOwnProperty.call(efficiencyDataTemp, selectedZoneLevel) &&
      Object.prototype.hasOwnProperty.call(efficiencyDataTemp[selectedZoneLevel], selectedInnerSubset)
    ) {
      // Set data
      setEfficiencyData(efficiencyDataTemp[selectedZoneLevel][selectedInnerSubset]);

      // Set datasets
      const datasetsIncluded = [];
      efficiencyDataTemp[selectedZoneLevel][selectedInnerSubset].datasets.forEach((dataset) => {
        if (
          displayedAxis == dataset.label ||
          (dataset.label == 'Target' &&
            targetsObject.hasOwnProperty(selectedInnerSubset) &&
            displayedAxis != 'Total $' &&
            targetsObject[selectedInnerSubset][[displayedAxis]] != 0)
        ) {
          datasetsIncluded.push(dataset);
        }
      });

      setDatasets(datasetsIncluded);
    } else {
      setEfficiencyData(null);
      setDatasets(null);
    }

    // Populate filters
    const tasksListStart = [{value: 'Total', label: 'Total'}];
    const tasks = [];
    const taskIds = operationsData ? Object.keys(operationsData.tasksObject) : [''];
    taskIds.forEach((taskId) => {
      if (taskId == '') {
        tasksListStart.push({value: taskId, label: 'No Task Found'});
      } else {
        let taskName = '';
        if (typeof taskConfigs[taskId] !== 'undefined') {
          taskName = taskConfigs[taskId].name;
        }
        tasks.push({value: taskId, label: taskName});
      }
    });
    tasks.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    setInnerSubsetOptions(tasksListStart.concat(tasks));
  }

  function handleEfficiencyZonesFilters(select, selectedZoneLevel = null) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    const value = select.multiple ? selected : selected[0];

    if (select.name.includes('efficiencyZoneNames')) {
      setEfficiencyFilters((values) => {
        return {
          ...values,
          efficiencyZoneNames: {
            ...values.efficiencyZoneNames,
            [selectedZoneLevel]: value,
          },
        };
      });
    } else {
      const name = select.name;

      setEfficiencyFilters((values) => {
        return {...values, [name]: value};
      });
    }
  }

  function efficiencyGraph(dollarRateText = null) {
    const dollarRateTextShow = dollarRateText != null;
    let taskHoverDisplay;
    let taskName = selectedInnerSubset;
    if (typeof taskConfigs[selectedInnerSubset] !== 'undefined') {
      taskName = taskConfigs[selectedInnerSubset].name;
    } else if (
      selectedInnerSubset.constructor === Array &&
      selectedInnerSubset.length == 1 &&
      typeof taskConfigs[selectedInnerSubset[0]] !== 'undefined'
    ) {
      taskName = taskConfigs[selectedInnerSubset[0]].name;
    } else if (selectedInnerSubset.constructor === Array && selectedInnerSubset.length > 1) {
      taskHoverDisplay = selectedInnerSubset
        .map((taskId) => {
          if (taskConfigs.hasOwnProperty(taskId)) {
            return ' ' + taskConfigs[taskId].name + ' ';
          } else {
            return ' No Task Found';
          }
        })
        .toString();
      taskName = 'Multiple Tasks Selected' + ` (${selectedInnerSubset.length})`;
    }

    return (
      <div style={{height: '50vh', minHeight: '400px'}}>
        {/* Chart */}
        {props.pageStates && Array.isArray(selectedInnerSubset) && (
          <Tooltip title={taskHoverDisplay}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '20%',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 10,
                opacity: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // Ensure the chart is still interactable
              }}
            ></div>
          </Tooltip>
        )}
        <div style={{position: 'absolute', top: '0', left: 0, width: '100%', height: '100%'}}>
          <BarChart
            data={{
              labels: efficiencyData.labels,
              datasets: datasets,
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                annotation: {
                  annotations: {
                    line1: {
                      type: 'line',
                      scaleID: scaleId,
                      value:
                        targetsObject.hasOwnProperty(selectedInnerSubset) &&
                        targetsObject[selectedInnerSubset][displayedAxis],
                      enValue:
                        targetsObject.hasOwnProperty(selectedInnerSubset) &&
                        targetsObject[selectedInnerSubset][displayedAxis],

                      borderColor: 'rgb(255, 99, 132)',
                      borderWidth: 2,
                      display:
                        targetsObject.hasOwnProperty(selectedInnerSubset) &&
                        typeof targetsObject[selectedInnerSubset][displayedAxis] != 'undefined' &&
                        targetsObject[selectedInnerSubset][displayedAxis] != 0,
                    },
                  },
                },
                subtitle: {
                  display: dollarRateTextShow,
                  text: dollarRateText,
                },
                title: {
                  display: true,
                  text: `Efficiency Baseline${taskName != 'Total' && taskName != '' ? ' By Task' : ''} - ${
                    taskName != '' ? taskName : 'No Task Found'
                  }`,
                  font: {
                    size: customChartProperties.titleFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                legend: {
                  onClick: (e) => {
                    return false;
                  },
                  labels: {
                    font: {
                      size: customChartProperties.legendFontSize,
                    },
                  },
                },
              },
              scales: {
                AcHr: {
                  title: {text: 'Ac / Hr', display: true},
                  display: displayedAxis == 'Ac/Hr',
                  type: 'linear',
                  position: 'left',
                },
                HrAc: {
                  title: {text: 'Hr / Ac', display: true},
                  display: displayedAxis == 'Hr/Ac',
                  type: 'linear',
                  position: 'left',
                },
                Speed: {
                  title: {text: `Avg Speed (${speedUnits})`, display: true},
                  display: displayedAxis == 'Speed',
                  type: 'linear',
                  position: 'left',
                },
                'Total $': {
                  title: {text: `Total $`, display: true},
                  display: displayedAxis == 'Total $',
                  type: 'linear',
                  position: 'left',
                },
                TargetAxis: {
                  title: {text: 'Target', display: false},
                  display: false,
                  type: 'linear',
                  position: 'left',
                },
                x: {
                  title: {text: String(selectedZoneLevel), display: true},
                },
              },
            }}
          />
        </div>
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    const pageStates = props.pageStates;
    let dollarRateText;
    if (pageStates != undefined && pageStates.hasOwnProperty('acreDollarRate') && pageStates.acreDollarRate != '') {
      dollarRateText = `($${pageStates.acreDollarRate} per Acre)`;
    } else if (
      pageStates != undefined &&
      pageStates.hasOwnProperty('acreDollarRate') &&
      pageStates.hourDollarRate != ''
    ) {
      dollarRateText = `($${pageStates.hourDollarRate} per Hour)`;
    }
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiEfficiency' />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!efficiencyData || !datasets ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            efficiencyGraph(dollarRateText)
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className='row d-flex mx-0 px-0 justify-content-center align-items-center mt-1'>
      {/* Dropdowns */}
      <div className='col-12 text-center px-0'>
        <Select
          size='small'
          className='col-6'
          displayEmpty
          multiple
          // Custom styling for selected item
          // Making the selected highlight contrast higher to make it more visible
          MenuProps={{
            sx: {
              '&& .Mui-selected': {
                backgroundColor: '#4e73df',
              },
            },
          }}
          value={selectedInnerSubset.constructor === Array ? selectedInnerSubset : [selectedInnerSubset]}
          onChange={(e) => {
            if (e.target.value.length > 1 && e.target.value.includes('Total')) {
              // If we did have total selected before and selected something else, remove total
              if (selectedInnerSubset.includes('Total')) {
                const totalIndex = e.target.value.indexOf('Total');
                e.target.value.splice(totalIndex, 1);
              }
              // If we didn't have total selected before, then we're trying to select total so make the array just total as it cannot be combined with others
              else {
                e.target.value = ['Total'];
              }
            }
            if (e.target.value.constructor !== Array || e.target.value.length > 0) {
              setSelectedInnerSubset(e.target.value);
            }
          }}
        >
          {innerSubsetOptions.map((option, idx) => {
            return (
              <MenuItem key={idx} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
        <Select
          size='small'
          className='col-6'
          value={selectedZoneLevel}
          onChange={(e) => {
            setSelectedZoneLevel(e.target.value);
          }}
        >
          <MenuItem value={'Region'}>{'Region'}</MenuItem>
          <MenuItem value={'Field'}>{'Field'}</MenuItem>
          <MenuItem value={'Block'}>{'Block'}</MenuItem>
        </Select>
      </div>

      {/* Tooltip */}
      <div className={!toolTipOpen ? 'col-6 px-0' : 'col-12 px-0'}>
        <KpiTooltip clickHandler={setTooltipOpen} selectedSubset='kpiEfficiency' />
      </div>
      {!toolTipOpen && (
        <div className={'col-6 px-0'}>
          <ScorecardSelectHolder
            autofillPageName={'Farming Efficiency'}
            pageFilters={efficiencyFilters}
            pageStates={{
              selectedInnerSubset: selectedInnerSubset,
              selectedZoneLevel: selectedZoneLevel,
              displayedAxis: displayedAxis,
              scaleId: scaleId,
              hourDollarRate: hourDollarRate,
              acreDollarRate: acreDollarRate,
              pageTargets: targetsObject,
            }}
          />
        </div>
      )}

      {!efficiencyData || !datasets ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>{efficiencyGraph()}</div>
          <div className='col-lg-4 flex-col text-center px-0 align-items-center my-auto'>
            <div className='text-center px-0 align-items-center my-auto'>
              {customerSettings.dashboard.efficiencyDollarsCalculatorEnabled && (
                <Grid alignItems={'center'} container spacing={2}>
                  <Grid item xs={5}>
                    <Typography>Acre Rate ($/Ac)</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                      size='small'
                      onChange={handleAcreDollarRateChange}
                      value={acreDollarRate}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Hour Rate ($/Hr)</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField size='small' onChange={handleHourDollarRateChange} value={hourDollarRate} />
                  </Grid>
                </Grid>
              )}
            </div>
            <div className='text-center px-0 align-items-center my-auto'>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('Ac/Hr');
                      setAcreDollarRate('');
                      setHourDollarRate('');
                      setScaleId('AcHr');
                    }}
                    checked={displayedAxis == 'Ac/Hr'}
                  />
                }
                label='Ac/Hr'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('Hr/Ac');
                      setScaleId('HrAc');
                      setAcreDollarRate('');
                      setHourDollarRate('');
                    }}
                    checked={displayedAxis == 'Hr/Ac'}
                  />
                }
                label='Hr/Ac'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('Speed');
                      setScaleId('Speed');
                      setAcreDollarRate('');
                      setHourDollarRate('');
                    }}
                    checked={displayedAxis == 'Speed'}
                  />
                }
                label={`Avg Speed (${speedUnits})`}
              />
            </div>
            {/* Not sure why but when I do this ternary operator inside of the div it doesn't dynamically render when selectedZoneLevel is changed */}
            {selectedZoneLevel == 'Block' && (
              <div className='text-center px-0 align-items-center'>
                <Tailselect
                  id={'dashboard-efficiency-efficiencyZone-filter' + selectedZoneLevel}
                  name='efficiencyZoneNames'
                  multiple={true}
                  search={true}
                  value={efficiencyFilters.efficiencyZoneNames[selectedZoneLevel]}
                  options={zoneFilterOptions}
                  onChange={(s) => {
                    handleEfficiencyZonesFilters(s, selectedZoneLevel);
                  }}
                  placeholder='Filter by Zone Name'
                />
                <Tailselect
                  id='dashboard-efficiency-efficiencyZoneCount-filter'
                  name='efficiencyZoneCount'
                  multiple={false}
                  value={efficiencyFilters.efficiencyZoneCount}
                  options={filterOptions.efficiency.efficiencyZoneCount}
                  onChange={handleEfficiencyZonesFilters}
                  placeholder='Number Of Zones To Show'
                />
                <Tailselect
                  id='dashboard-efficiency-efficiencyZoneSort-filter'
                  name='efficiencyZoneSort'
                  multiple={false}
                  value={efficiencyFilters.efficiencyZoneSort}
                  options={filterOptions.efficiency.efficiencyZoneSort}
                  onChange={handleEfficiencyZonesFilters}
                  placeholder='Sorting method'
                />
                <Tailselect
                  id='dashboard-efficiency-efficiencyZoneRowSpacing-filter'
                  name='efficiencyRowSpacing'
                  multiple={true}
                  value={efficiencyFilters.efficiencyRowSpacing}
                  options={filterOptions.efficiency.efficiencyRowSpacing}
                  onChange={handleEfficiencyZonesFilters}
                  placeholder='Filter by Block Row Spacing'
                />
              </div>
            )}
            {selectedZoneLevel == 'Field' && (
              <div className='text-center px-0 align-items-center'>
                <Tailselect
                  id={'dashboard-efficiency-efficiencyZone-filter' + selectedZoneLevel}
                  name='efficiencyZoneNames'
                  multiple={true}
                  search={true}
                  value={efficiencyFilters.efficiencyZoneNames[selectedZoneLevel]}
                  options={zoneFilterOptions}
                  onChange={(s) => {
                    handleEfficiencyZonesFilters(s, selectedZoneLevel);
                  }}
                  placeholder='Filter by Zone Name'
                />
                <Tailselect
                  id='dashboard-efficiency-efficiencyZoneCount-filter'
                  name='efficiencyZoneCount'
                  multiple={false}
                  value={efficiencyFilters.efficiencyZoneCount}
                  options={filterOptions.efficiency.efficiencyZoneCount}
                  onChange={handleEfficiencyZonesFilters}
                  placeholder='Number Of Zones To Show'
                />
                <Tailselect
                  id='dashboard-efficiency-efficiencyZoneSort-filter'
                  name='efficiencyZoneSort'
                  multiple={false}
                  value={efficiencyFilters.efficiencyZoneSort}
                  options={filterOptions.efficiency.efficiencyZoneSort}
                  onChange={handleEfficiencyZonesFilters}
                  placeholder='Sorting method'
                />
              </div>
            )}
            {selectedZoneLevel == 'Region' && (
              <div className='text-center px-0 align-items-center'>
                <Tailselect
                  id={'dashboard-efficiency-efficiencyZone-filter' + selectedZoneLevel}
                  name='efficiencyZoneNames'
                  multiple={true}
                  search={true}
                  value={efficiencyFilters.efficiencyZoneNames[selectedZoneLevel]}
                  options={zoneFilterOptions}
                  onChange={(s) => {
                    handleEfficiencyZonesFilters(s, selectedZoneLevel);
                  }}
                  placeholder='Filter by Zone Name'
                />
                <Tailselect
                  id='dashboard-efficiency-efficiencyZoneCount-filter'
                  name='efficiencyZoneCount'
                  multiple={false}
                  value={efficiencyFilters.efficiencyZoneCount}
                  options={filterOptions.efficiency.efficiencyZoneCount}
                  onChange={handleEfficiencyZonesFilters}
                  placeholder='Number Of Zones To Show'
                />
                <Tailselect
                  id='dashboard-efficiency-efficiencyZoneSort-filter'
                  name='efficiencyZoneSort'
                  multiple={false}
                  value={efficiencyFilters.efficiencyZoneSort}
                  options={filterOptions.efficiency.efficiencyZoneSort}
                  onChange={handleEfficiencyZonesFilters}
                  placeholder='Sorting method'
                />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export {KpiEfficiency};
