import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import {
  unitsSubmitConversion,
  unitsDisplayConversion,
  rowApplicationTypeMapping,
  fetchPostAuthSafe,
} from '../../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ModalSubmitting, ViewOnlyText} from '../../../../components/GeneralComponents';

function ImplementEditModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [editImplement, setEditImplement] = useState(props.editImplement);
  const [submitStatus, setSubmitStatus] = useState('pending');
  // submitStatus:  "pending" : Filling form
  //                "submitting" : Submitting
  //                "success" : Submission Completed
  //                "error" : Error submission
  const [modalError, setModalError] = useState('');
  const [warningBubbleVisible, setWarningBubbleVisible] = useState(false);
  const editModalOpen = props.editModalOpen;

  useEffect(() => {
    // Reset State of Edit Modal
    setSubmitStatus('pending');
    setEditImplement({
      ...props.editImplement,
      rowApplicationType: props.editImplement?.rowApplicationType ? props.editImplement?.rowApplicationType : 0,
    });
    setWarningBubbleVisible(false);
    setModalError('');
  }, [props.editImplement]);

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smallScreen ? '100%' : 800,
    maxHeight: smallScreen ? '100%' : 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY: 'auto',
    p: 2,
  };

  async function handleEditSubmit() {
    let error = false;
    const originalImplementData = props.editImplement;
    const newImplementData = editImplement;
    if (!newImplementData.name || newImplementData.name == '') {
      setModalError('Please enter a Implement Name');
      error = true;
    }

    // Set width or application rows to default depending on rowApplicationType
    if (newImplementData.rowApplicationType == 2) {
      newImplementData.dimensions.width = 0;
    } else {
      newImplementData.applicationRows = 1;
    }

    // Check that input values are valid
    newImplementData.applicationRows = parseFloat(newImplementData.applicationRows || 0);
    if (
      newImplementData.rowApplicationType == 2 &&
      ((newImplementData.applicationRows != 0.5 && newImplementData.applicationRows % 1 != 0) ||
        newImplementData.applicationRows <= 0)
    ) {
      setModalError('Please enter a row quantity of 0.5 or a postive whole number');
      error = true;
    } else if (newImplementData.dimensions.width < 0) {
      setModalError('Please enter a width greater than or equal to 0');
      error = true;
    }

    if (error) {
      return;
    }

    setSubmitStatus('submitting');

    const implementEditKeys = [
      'rowApplicationType',
      'applicationRows',
      'linkedTaskId',
      'name',
      'vin',
      'make',
      'notes',
      'archived',
    ];

    const updateDocs = [];
    const implementDiff = {serialNumber: newImplementData.serialNumber};
    const diffDoc = {};

    for (let i = 0; i < implementEditKeys.length; i++) {
      if (
        newImplementData[implementEditKeys[i]] !== originalImplementData[implementEditKeys[i]] ||
        typeof originalImplementData[implementEditKeys[i]] == 'undefined' ||
        originalImplementData.serialNumber == ''
      ) {
        diffDoc[implementEditKeys[i]] = newImplementData[implementEditKeys[i]];
      }
    }

    // Process Width Changes
    let dimensions = {
      width: 0,
      height: 0,
      length: 0,
    };
    if (originalImplementData?.dimensions != undefined) {
      dimensions = originalImplementData.dimensions;
    }
    if (newImplementData.dimensions.width != dimensions.width) {
      dimensions.width = newImplementData.dimensions.width;
    }
    Object.keys(dimensions).forEach((key) => {
      dimensions[key] =
        unitsSystem == 'imperial'
          ? unitsSubmitConversion(parseFloat(dimensions[key]), 'ft')
          : parseFloat(dimensions[key]);
    });
    diffDoc['dimensions'] = dimensions;

    // New imp creation always use the archived status inputted
    if (implementDiff.serialNumber == '') {
      implementDiff.serialNumber = 'TBD_NewImplement';
      diffDoc['archived'] = editImplement['archived'];
    }
    implementDiff.diffDoc = diffDoc;
    updateDocs.push(implementDiff);

    const result = await postImplementUpdates(updateDocs);

    props.getTaskConfigsImplementsDataFromApi();
    setSubmitStatus('success');
  }

  function modalClose() {
    setSubmitStatus('pending');
    setEditImplement({
      name: '',
      vin: '',
      make: '',
      notes: '',
      serialNumber: '',
      dimensions: {
        'width': 0,
        'height': 0,
        'length': 0,
      },
      linkedTaskId: '',
      archived: false,
      rowApplicationType: 0,
      applicationRows: 4,
      qrLabelId: '',
      btTagId: '',
    });
    setModalError('');
    return props.handleEditModalOpen(false);
  }

  /**
   *
   * @param {*} updateDoc
   */
  async function postImplementUpdates(updateDoc) {
    if (Object.keys(updateDoc).length > 0) {
      const postData = updateDoc;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: JSON.stringify(postData),
      };
      const url = '/settings/updateImplementDocs';

      const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
      const result = await response.json();
      if (result.errorMsg) {
        navigate('/error', {state: {errorMsg: result.errorMsg}});
      }

      if (result.status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
      }

      return result;
    } else {
      return {success: false, status: 400, error: 'Request body is empty'};
    }
  }

  function renderTaskOptions() {
    const options = [];
    options.push({
      value: '',
      label: '----',
    });

    const optionsKeyValue = [];

    Object.keys(taskConfigDict).forEach((taskId) => {
      if (taskConfigDict[taskId]?.archived != true) {
        optionsKeyValue.push({
          taskId: taskId,
          name: taskConfigDict[taskId].name,
        });
      } else if (taskId == editImplement.linkedTaskId) {
        optionsKeyValue.push({
          taskId: taskId,
          name: `${taskConfigDict[taskId].name} - Archived`,
        });
      }
    });

    optionsKeyValue.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    for (let i = 0; i < optionsKeyValue.length; i++) {
      options.push({
        value: optionsKeyValue[i].taskId,
        label: optionsKeyValue[i].name,
      });
    }

    return options.map((option) => {
      return option;
    });
  }

  if (submitStatus != 'pending') {
    return (
      <Modal
        open={editModalOpen}
        onClose={modalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={boxStyle}>
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            {submitStatus == 'submitting' && <ModalSubmitting />}
            {submitStatus == 'success' && (
              <div>
                <div style={{color: 'green', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-check' />
                </div>
                <span>Update Completed.</span>
              </div>
            )}
            {submitStatus == 'error' && (
              <div>
                <div style={{color: 'red', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-xmark' />
                </div>
                <span>An error has occured, please try again.</span>
              </div>
            )}
          </div>
          {submitStatus != 'submitting' && (
            <div style={{marginTop: '20px'}} className='flex-row mb-3 col-12 px-1'>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <Button variant='contained' color='success' onClick={modalClose}>
                  Close
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  const viewOnly = !userSettings.roleAccess['implementManagement'];
  return (
    <Modal
      open={editModalOpen}
      onClose={modalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={boxStyle}>
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
          <h3>
            <strong>{`${editImplement.serialNumber != '' ? 'Edit' : 'Create'}`} Implement</strong>
          </h3>
          <div style={{color: 'black'}}>
            <strong>{editImplement.vehicleName}</strong>
          </div>
        </div>

        {viewOnly && <ViewOnlyText />}
        <div className='flex-row mb-3 col-12 px-1'>
          <label className='mb-0 mt-2'>
            <strong>Implement Name</strong>
          </label>
          <div className='mt-1'>
            <TextField
              disabled={viewOnly}
              size='small'
              fullWidth
              type='text'
              name='implement-edit-name'
              value={editImplement.name}
              onChange={(e) => {
                setEditImplement({
                  ...editImplement,
                  name: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <label className='mb-0 mt-2'>
            <strong>Task </strong>
          </label>
          <Autocomplete
            size='small'
            disabled={viewOnly}
            value={editImplement.linkedTaskId}
            options={renderTaskOptions()}
            getOptionLabel={(option) => {
              if (typeof option == 'object') {
                return option.label;
              } else {
                const optionObj = renderTaskOptions().find((opt) => {
                  return opt.value == option;
                });
                if (optionObj) {
                  return optionObj.label;
                } else {
                  return '';
                }
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} placeholder='Search...' />;
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value === value;
            }}
            onChange={(event, value) => {
              if (value === null) {
                value = {value: ''};
              }
              setEditImplement({...editImplement, linkedTaskId: value.value});
            }}
          />
        </div>
        <div className='row px-1'>
          <div className='flex-row mb-3 col-6'>
            <label className='mb-0 mt-2'>
              <strong>VIN</strong>
            </label>
            <div className='mt-1'>
              <TextField
                disabled={viewOnly}
                size='small'
                fullWidth
                type='text'
                name='implement-edit-vin'
                value={editImplement.vin}
                onChange={(e) => {
                  setEditImplement({
                    ...editImplement,
                    vin: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className='flex-row mb-3 col-6'>
            <label className='mb-0 mt-2'>
              <strong>Make</strong>
            </label>
            <div className='mt-1'>
              <TextField
                disabled={viewOnly}
                size='small'
                fullWidth
                type='text'
                name='implement-edit-make'
                value={editImplement.make}
                onChange={(e) => {
                  setEditImplement({
                    ...editImplement,
                    make: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className='row px-1'>
          <div className='flex-row mb-3 col-8'>
            <label className='mb-0 mt-2'>
              <strong>Acre Calculation </strong>
            </label>
            <Select
              disabled={viewOnly}
              size='small'
              fullWidth
              value={editImplement.rowApplicationType}
              onChange={(e) => {
                setEditImplement({...editImplement, rowApplicationType: parseInt(e.target.value)});
              }}
            >
              {Object.keys(rowApplicationTypeMapping).map((i) => {
                if (
                  rowApplicationTypeMapping[i] == 'Limited' &&
                  rowApplicationTypeMapping[editImplement.rowApplicationType] != 'Limited'
                ) {
                  return null;
                }
                return (
                  <MenuItem key={i} value={i}>
                    {rowApplicationTypeMapping[i]}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          {editImplement.rowApplicationType == 2 ? (
            <div className='flex-row mb-3 col-4'>
              <label className='mb-0 mt-2'>
                <strong>#Rows</strong>
              </label>
              <TextField
                disabled={viewOnly}
                size='small'
                fullWidth
                type='number'
                name='implement-edit-applicationRows'
                value={editImplement.applicationRows}
                InputProps={{inputProps: {step: 1, min: 0}}}
                onChange={(e) => {
                  setEditImplement({
                    ...editImplement,
                    applicationRows: e.target.value,
                  });
                }}
              />
            </div>
          ) : (
            <div className='flex-row mb-3 col-4'>
              <label className='mb-0 mt-2'>
                <strong>Width {unitsSystem == 'metric' ? '(m)' : '(ft)'}</strong>
              </label>
              <TextField
                disabled={viewOnly}
                size='small'
                fullWidth
                type='number'
                name='implement-edit-width'
                value={editImplement.dimensions.width}
                InputProps={{inputProps: {step: 0.01, min: 0}}}
                onChange={(e) => {
                  const dimensions = {
                    ...editImplement.dimensions,
                  };
                  dimensions.width = e.target.value;
                  setEditImplement({
                    ...editImplement,
                    dimensions: dimensions,
                  });
                }}
              />
            </div>
          )}
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <label className='mb-0 mt-2'>
            <strong>Notes</strong>
          </label>
          <div className='mt-1'>
            <TextField
              disabled={viewOnly}
              size='small'
              fullWidth
              type='text'
              name='implement-edit-notes'
              value={editImplement.notes}
              onChange={(e) => {
                setEditImplement({
                  ...editImplement,
                  notes: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className='row px-1'>
          <div className='flex-row mb-3 col-6'>
            <label className='mb-0 mt-2'>
              <strong>QR Assigned</strong>
            </label>
            <div className='mt-1'>{editImplement.qrLabelId}</div>
          </div>
          {customerSettings.general.enableBluetoothTags && (
            <div className='flex-row mb-3 col-6'>
              <label className='mb-0 mt-2'>
                <strong>BT Tag Assigned</strong>
              </label>
              <div className='mt-1'>{editImplement.btTagId}</div>
            </div>
          )}
        </div>
        <div className='col-12 px-1 mb-3'>
          <div style={{textAlign: 'center', padding: '5px'}}>
            Archived
            <Switch
              name='archived'
              key={editImplement.archived}
              checked={editImplement.archived}
              disabled={viewOnly}
              onChange={(e) => {
                setEditImplement({...editImplement, archived: e.target.checked});
              }}
            />
          </div>
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <Button variant='ic-button' color='secondary' onClick={modalClose}>
              Cancel
            </Button>
            {!viewOnly && (
              <Button variant='ic-button' color='primary' onClick={handleEditSubmit}>
                Submit
              </Button>
            )}
          </div>
        </div>
        <div style={{color: 'red', textAlign: 'center'}} className='flex-row mb-3 col-12 px-1'>
          {modalError}
        </div>
      </Box>
    </Modal>
  );
}

export {ImplementEditModal};
