import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useSearchParams, useNavigate} from 'react-router-dom';
import {DateTime, Interval, Duration} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {sendGAEvent, sortVehicleNamesHelper, fetchPostAuthSafe} from '../../app/utils';
import {
  updateLoading,
  updateDates,
  updateCoverageData,
  updateClearData,
  updateDisplayedTable,
  updateDisplayedColumn,
  updateSortMethod,
  updateMapView,
  updateTodayOnly,
  updateEditCropview,
  updateZoneZoom,
  updateLastTripTaskEndTime,
  updateReiActive,
  updateRefreshRequired,
  updateVehicleSNDict,
  updateGpsLoaded,
} from './cropviewSlice';

// Declare abort controllers
let abortController;

function Menu(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const dates = useSelector((state) => {
    return state.cropview.dates;
  });
  const loading = useSelector((state) => {
    return state.cropview.loading;
  });
  const zonesData = useSelector((state) => {
    return state.cropview.zonesData;
  });
  const zoneAnalytics = useSelector((state) => {
    return state.cropview.zoneAnalytics;
  });
  const zoneNameToIntelliBlockNums = useSelector((state) => {
    return state.cropview.zoneNameToIntelliBlockNums;
  });
  const equipmentAnalytics = useSelector((state) => {
    return state.cropview.equipmentAnalytics;
  });
  const todayOnly = useSelector((state) => {
    return state.cropview.todayOnly;
  });
  const editCropview = useSelector((state) => {
    return state.cropview.editCropview;
  });
  const editCropviewValues = useSelector((state) => {
    return state.cropview.editCropviewValues;
  });
  const editCropviewRecValues = useSelector((state) => {
    return state.cropview.editCropviewRecValues;
  });
  const recList = useSelector((state) => {
    return state.cropview.recList;
  });
  const displayedTable = useSelector((state) => {
    return state.cropview.displayedTable;
  });
  const displayedColumn = useSelector((state) => {
    return state.cropview.displayedColumn;
  });
  const sortMethod = useSelector((state) => {
    return state.cropview.sortMethod;
  });
  const taskAcreageCapping = useSelector((state) => {
    return state.cropview.taskAcreageCapping;
  });
  const taskAcreageCapTracker = useSelector((state) => {
    return state.cropview.taskAcreageCapTracker;
  });
  const taskAcreageCapTrackerTask = useSelector((state) => {
    return state.cropview.taskAcreageCapTrackerTask;
  });
  const gpsLoaded = useSelector((state) => {
    return state.cropview.gpsLoaded;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const keyRef = useRef(DateTime.now()); // Ref for triggering updates to date picker settings

  const [searchParams, setSearchParams] = useSearchParams();
  const [locate, setLocate] = useState('');

  const [datesCache, setDatesCache] = useState({
    dates: {start: dates.start, end: dates.end, max: dates.max},
  });

  const [tableCache, setTableCache] = useState('block');
  const [refreshTimeoutId, setRefreshTimeoutId] = useState(0);

  // Get new coverage data when date range changes
  useEffect(() => {
    if (dates.start == null || dates.end == null) {
      // Null values means initilization still needs to occur
      initCoverageData();
    }
  }, []);

  useEffect(() => {
    if (dates.start !== null && dates.end !== null) {
      // Clear existing timeout and refreah status
      clearTimeout(refreshTimeoutId);
      dispatch(updateRefreshRequired(false));

      getCoverageData();

      // If our queried time range is within the current shift we want the refresh indicator, otherwise it is not needed
      const [shiftStartDate, shiftEndDate] = getShiftDates();
      const endRange = DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone);
      if (endRange > shiftStartDate) {
        // Start timer for triggering a refresh required indicator
        const newTimoutId = setTimeout(function () {
          dispatch(updateRefreshRequired(true));
        }, 3600000); // 1 hour

        // Set existing timeout
        setRefreshTimeoutId(newTimoutId);
      }
    }
  }, [dates]);

  // The class names with 'md' are specifically for small screen sizes (< 767 px)
  return (
    <React.Fragment>
      <div className='row my-1 mx-0'>
        <div className='col-6 col-md-5 px-0'>{datePickerRender()}</div>
        <div className='btn-group col-6 col-md-auto px-0 px-md-1'>
          <button
            disabled={editCropview ? true : false}
            type='button'
            className='btn border-dark btn-light cropview-menu-text'
            onClick={() => {
              if (displayedTable == 'equipment') {
                dispatch(updateDisplayedColumn('coverage'));
              }
              toggleTableDisplay('block');
            }}
          >
            <FontAwesomeIcon icon='fas fa-square' size='xs' />
          </button>
          <button
            disabled={editCropview ? true : false}
            type='button'
            className='btn border-dark btn-light cropview-menu-text'
            onClick={() => {
              if (displayedTable == 'equipment') {
                dispatch(updateDisplayedColumn('coverage'));
              }
              toggleTableDisplay('field');
            }}
          >
            <FontAwesomeIcon icon='fas fa-square' />
          </button>
          <button
            disabled={editCropview ? true : false}
            type='button'
            className='btn border-dark btn-light cropview-menu-text'
            onClick={() => {
              if (displayedTable == 'equipment') {
                dispatch(updateDisplayedColumn('coverage'));
              }
              toggleTableDisplay('region');
            }}
          >
            <FontAwesomeIcon icon='fas fa-square' size='xl' />
          </button>
          <button
            disabled={editCropview ? true : false}
            type='button'
            className='btn border-dark btn-light cropview-menu-text'
            onClick={() => {
              if (displayedTable != 'equipment') {
                dispatch(updateDisplayedColumn('timeInField'));
              }
              toggleTableDisplay('equipment');
            }}
          >
            <FontAwesomeIcon icon='fas fa-fw fa-tractor' />
          </button>
        </div>
        {customerSettings.cropview.enableTaskCycleTable && (
          <div className='col-2 col-md-auto px-0 px-md-1' style={{maxWidth: '12.5%'}}>
            <button
              disabled={editCropview ? true : false}
              type='button'
              className={
                `btn border-dark col-12 cropview-menu-text ` +
                `${displayedTable == 'spray' ? 'btn-primary' : 'btn-light'}`
              }
              onClick={() => {
                toggleSprayTable();
              }}
            >
              <FontAwesomeIcon icon='fas fa-spray-can' />
            </button>
          </div>
        )}
        <div className='col-2 col-md-auto px-0 px-md-1 dropdown' style={{maxWidth: '12.5%'}}>
          <button
            className='btn border-dark btn-light col-12 cropview-menu-text'
            data-toggle='dropdown'
            disabled={editCropview || displayedTable == 'spray' ? true : false}
          >
            <FontAwesomeIcon icon='fas fa-sort-amount-down' />
          </button>
          <div className='dropdown-menu border-dark' style={{minWidth: '0'}}>
            <div className='dropdown-header cropview-menu-text px-3'>Sort By</div>
            <button
              className='dropdown-item cropview-menu-text px-3'
              onClick={() => {
                dispatch(updateSortMethod('name'));
              }}
            >
              Name {sortMethod == 'name' && <FontAwesomeIcon icon='fas fa-check' />}
            </button>
            {displayedTable != 'equipment' ? (
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  dispatch(updateSortMethod('time'));
                }}
              >
                Time {sortMethod == 'time' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
            ) : (
              <React.Fragment>
                <button
                  className='dropdown-item cropview-menu-text px-3'
                  onClick={() => {
                    dispatch(updateSortMethod('type'));
                  }}
                >
                  Vehicle Type {sortMethod == 'type' && <FontAwesomeIcon icon='fas fa-check' />}
                </button>
                <button
                  className='dropdown-item cropview-menu-text px-3'
                  onClick={() => {
                    dispatch(updateSortMethod('timeInField'));
                  }}
                >
                  In Field Time {sortMethod == 'timeInField' && <FontAwesomeIcon icon='fas fa-check' />}
                </button>
                {customerSettings.general.outOfFieldTime && (
                  <button
                    className='dropdown-item cropview-menu-text px-3'
                    onClick={() => {
                      dispatch(updateSortMethod('timeOutField'));
                    }}
                  >
                    Out of Field Time {sortMethod == 'timeOutField' && <FontAwesomeIcon icon='fas fa-check' />}
                  </button>
                )}
                {customerSettings.general.idleTime && (
                  <button
                    className='dropdown-item cropview-menu-text px-3'
                    onClick={() => {
                      dispatch(updateSortMethod('timeIdle'));
                    }}
                  >
                    Idle Time {sortMethod == 'timeIdle' && <FontAwesomeIcon icon='fas fa-check' />}
                  </button>
                )}
                <button
                  className='dropdown-item cropview-menu-text px-3'
                  onClick={() => {
                    dispatch(updateSortMethod('timeTotal'));
                  }}
                >
                  Total Time {sortMethod == 'timeTotal' && <FontAwesomeIcon icon='fas fa-check' />}
                </button>
              </React.Fragment>
            )}
            <button
              className='dropdown-item cropview-menu-text px-3'
              onClick={() => {
                dispatch(updateSortMethod('acreage'));
              }}
            >
              Acreage {sortMethod == 'acreage' && <FontAwesomeIcon icon='fas fa-check' />}
            </button>
            {displayedTable != 'equipment' && (
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  dispatch(updateSortMethod('percent'));
                }}
              >
                Coverage Percent {sortMethod == 'percent' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
            )}
          </div>
        </div>
        <div className='col-2 col-md-auto px-0 px-md-1' style={{maxWidth: '12.5%'}}>
          <button
            disabled={editCropview || displayedTable == 'spray' ? true : false}
            type='button'
            className='btn border-dark btn-light col-12 cropview-menu-text'
            onClick={downloadCsv}
          >
            <FontAwesomeIcon icon='fas fa-download' />
          </button>
        </div>
        <div className='col-2 col-md-auto px-0 px-md-1' style={{maxWidth: '12.5%'}}>
          <button
            type='button'
            className={`btn border-dark col-12 cropview-menu-text ${editCropview ? 'btn-primary' : 'btn-light'}`}
            onClick={toggleEdit}
            disabled={(displayedTable == 'equipment' && !todayOnly) || displayedTable == 'spray' ? true : false}
          >
            <FontAwesomeIcon icon='fas fa-edit' />
          </button>
        </div>
        <div className='col-2 col-md-auto px-0 px-md-1'>
          <button
            type='button'
            className={`btn border-dark col-12 cropview-menu-text ${
              props.mapModeEnabled && !smallScreen ? 'btn-primary' : 'btn-light'
            }`}
            onClick={async () => {
              if (!smallScreen) {
                props.toggleShowMap();
              } else {
                dispatch(updateMapView());
              }
              if (!gpsLoaded) {
                await getGpsData();
              }
            }}
          >
            <FontAwesomeIcon icon='fas fa-map' />
          </button>
        </div>
        <div className='col-2 col-md-auto px-0 px-md-1'>
          <button
            disabled={editCropview || displayedTable == 'spray' ? true : false}
            type='button'
            className={`btn border-dark col-12 cropview-menu-text ${todayOnly ? 'btn-primary' : 'btn-light'}`}
            onClick={toggleTodaysActivity}
          >
            Today
          </button>
        </div>
        {smallScreen && (
          <div className='col-2 col-md-auto px-0 px-md-1'>
            <select
              disabled={editCropview || displayedTable == 'spray' ? true : false}
              className='btn border-dark btn-light col-12 cropview-menu-text'
              value={displayedColumn}
              onChange={(e) => {
                dispatch(updateDisplayedColumn(e.target.value));
              }}
              style={{height: '100%'}}
            >
              {displayedTable != 'equipment' ? (
                <React.Fragment>
                  <option value='coverage'>Coverage</option>
                  <option value='equipment'>Equipment</option>
                  {customerSettings.cropview.vehicleAverageSpeedEnabled && (
                    <option value='avgSpeed'>Average Speed</option>
                  )}
                  <option value='task'>Task</option>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <option value='timeInField'>In Field Time</option>
                  {customerSettings.general.outOfFieldTime && <option value='timeOutField'>Out of Field Time</option>}
                  {customerSettings.general.idleTime && <option value='timeIdle'>Idle Time</option>}
                  <option value='timeTotal'>Total Time</option>
                  {todayOnly && <option value='task'>Task</option>}
                  <option value='acreageRate'>Acreage Rate</option>
                </React.Fragment>
              )}
            </select>
          </div>
        )}
      </div>
    </React.Fragment>
  );

  function datePickerRender() {
    let buttonDisplayString = '';

    if (
      typeof dates.start !== 'undefined' &&
      typeof dates.end !== 'undefined' &&
      dates.start != null &&
      dates.end != null
    ) {
      const startDateObj = DateTime.fromISO(dates.start).setZone(customerSettings.general.timeZone);
      const endDateObj = DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone);
      buttonDisplayString =
        ` ${startDateObj.toFormat('L/d/yy h:mm a')} ` + `- ${endDateObj.toFormat('L/d/yy h:mm a')} `;

      // Often for when selecting a single day
      // Due to a certain shift time the start and end time will be the same
      // This is to prevent selection of a 0 minute range
      let zeroMinuteRange = false;
      if (startDateObj.hasSame(endDateObj, 'minute')) {
        zeroMinuteRange = true;
      }

      return (
        <DateRangePicker
          key={keyRef.current}
          onApply={dateSelection}
          initialSettings={{
            startDate: DateTime.fromISO(dates.start)
              .setZone(customerSettings.general.timeZone)
              .toFormat('MM/dd/yyyy HH:mm'),
            endDate: DateTime.fromISO(dates.end)
              .setZone(customerSettings.general.timeZone)
              .toFormat('MM/dd/yyyy HH:mm'),
            maxDate: DateTime.fromISO(dates.max)
              .setZone(customerSettings.general.timeZone)
              .toFormat('MM/dd/yyyy HH:mm'),
            timePicker: true,
            timePicker24Hour: true,
            locale: {
              format: 'MM/DD/YYYY HH:mm',
            },
          }}
          style={{height: '100%'}}
        >
          <button
            className='btn border-dark btn-light col-12 cropview-menu-text'
            disabled={editCropview || displayedTable == 'spray' ? true : false}
          >
            <FontAwesomeIcon icon='fas fa-calendar-alt' />
            {zeroMinuteRange ? (
              <span className='text-danger font-weight-bold'> 0 MIN TIME RANGE SELECTED</span>
            ) : (
              buttonDisplayString
            )}
          </button>
        </DateRangePicker>
      );
    }
  }

  function dateSelection(event, picker) {
    // Clear the filters
    if (Object.prototype.hasOwnProperty.call(props, 'setFilterDefault')) {
      props.setFilterDefault();
    }

    const startDate = DateTime.fromISO(picker.startDate.toISOString()).setZone(customerSettings.general.timeZone, {
      keepLocalTime: true,
    });
    const endDate = DateTime.fromISO(picker.endDate.toISOString())
      .set({
        second: 59,
        millisecond: 999,
      })
      .setZone(customerSettings.general.timeZone, {keepLocalTime: true});

    // Get shift dates
    const [shiftStartDate, shiftEndDate] = getShiftDates();

    const newDataDates = {
      start: startDate.toISO(),
      end: endDate.toISO(),
      max: shiftEndDate.toISO(),
    };

    if (startDate.equals(shiftStartDate) && endDate.equals(shiftEndDate)) {
      dispatch(updateTodayOnly(true));
    } else {
      dispatch(updateTodayOnly(false));
    }
    dispatch(updateDates(newDataDates));
    dispatch(updateEditCropview(false));

    setDatesCache({
      dates: newDataDates,
    });
  }

  function getShiftDates() {
    // Get startDate for current shift
    const now = DateTime.local({zone: customerSettings.general.timeZone});
    let shiftStartDate = DateTime.local({
      zone: customerSettings.general.timeZone,
    }).set({
      hour: customerSettings.general.hourOffSet,
      minute: customerSettings.general.minuteOffSet,
      second: 0,
      millisecond: 0,
    });

    // Shift started previous day if startDate is greater than now
    if (shiftStartDate > now) {
      shiftStartDate = shiftStartDate.plus({days: -1});
    }

    // Get endDate for current shift
    const shiftEndDate = shiftStartDate.plus({days: 1, milliseconds: -1});

    return [shiftStartDate, shiftEndDate];
  }

  function downloadCsv() {
    if (taskAcreageCapping) {
      downloadCappedCoverageCsv();
    } else {
      downloadCoverageCsv();
    }

    const delayInMilliseconds = 200;
    setTimeout(function () {
      downloadEquipmentCsv();
    }, delayInMilliseconds);

    sendGAEvent('csv_download', 'coverage', 'cropview');
    sendGAEvent('csv_download', 'equipment', 'cropview');
  }

  function downloadCoverageCsv() {
    const blocks = [];
    for (let i = 0; i < zoneAnalytics.length; i++) {
      for (let j = 0; j < zoneAnalytics[i].subZoneList.length; j++) {
        for (let k = 0; k < zoneAnalytics[i].subZoneList[j].subZoneList.length; k++) {
          if (
            Math.round(
              (zoneAnalytics[i].subZoneList[j].subZoneList[k].acreTotal /
                zoneAnalytics[i].subZoneList[j].subZoneList[k].area) *
                100
            ) > 0
          ) {
            const blockData = JSON.parse(JSON.stringify(zoneAnalytics[i].subZoneList[j].subZoneList[k]));
            blockData.parentField = zoneAnalytics[i].subZoneList[j].name;
            blockData.parentRegion = zoneAnalytics[i].name;
            blocks.push(blockData);
          }
        }
      }
    }

    // Sort the categorized data by operation time
    blocks.sort((a, b) => {
      return a.timeTotal < b.timeTotal ? 1 : -1;
    });

    // Push the block based data into the csv data table
    const csvList = [];

    for (let i = 0; i < blocks.length; i++) {
      for (let j = 0; j < blocks[i].vehicleList.length; j++) {
        for (let k = 0; k < blocks[i].vehicleList[j].taskList.length; k++) {
          let entryTimeStr = '';
          let exitTimeStr = '';
          if (blocks[i].vehicleList[j].taskList[k].entryTime && blocks[i].vehicleList[j].taskList[k].exitTime) {
            const entryTimeObj = DateTime.fromISO(blocks[i].vehicleList[j].taskList[k].entryTime).setZone(
              customerSettings.general.timeZone
            );
            const exitTimeObj = DateTime.fromISO(blocks[i].vehicleList[j].taskList[k].exitTime).setZone(
              customerSettings.general.timeZone
            );
            entryTimeStr = entryTimeObj.toLocaleString(DateTime.DATETIME_SHORT);
            exitTimeStr = exitTimeObj.toLocaleString(DateTime.DATETIME_SHORT);
          }
          csvList.push([
            [
              `\"${blocks[i].parentRegion}\"`,
              `\"${blocks[i].parentField}\"`,
              `\"${blocks[i].name}\"`,
              `\"${blocks[i].vehicleList[j].name}\"`,
              blocks[i].vehicleList[j].taskList[k].name == 'Unspecified'
                ? ''
                : `\"${blocks[i].vehicleList[j].taskList[k].name}\"`,
              blocks[i].vehicleList[j].taskList[k].timeTotal,
              Math.round(blocks[i].vehicleList[j].taskList[k].acreTotal * 10) / 10,
              ...(customerSettings.general.zoneEntryAndExitTimeEnabled ? [`\"${entryTimeStr}\"`] : []),
              ...(customerSettings.general.zoneEntryAndExitTimeEnabled ? [`\"${exitTimeStr}\"`] : []),
              userSettings.general.units == 'imperial'
                ? `${(blocks[i].vehicleList[j].taskList[k].avgSpeed * 0.621).toFixed(1)}`
                : `${blocks[i].vehicleList[j].taskList[k].avgSpeed.toFixed(1)}`,
            ],
          ]);
        }
      }
    }

    // Construct the final csv by delimiting the sorted data array
    let csvHeader = `\"Region Name\",\"Field Name\",\"Block Name\",\"Equipment Name\",\"Task Name\",\"Coverage Minutes\",\"Coverage Acreage\",`;

    if (customerSettings.general.zoneEntryAndExitTimeEnabled) {
      csvHeader = csvHeader + `\"Entry Date\",\"Exit Date\",`;
    }
    if (userSettings.general.units == 'imperial') {
      csvHeader = csvHeader + `\"In Field Speed (mph)\",`;
    } else {
      csvHeader = csvHeader + `\"In Field Speed (kph)\",`;
    }
    csvHeader = csvHeader + '\n';

    let csv = csvHeader;

    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';

    let start = datesCache.dates.start;
    let end = datesCache.dates.end;

    if (todayOnly) {
      start = dates.start;
      end = dates.end;
    }

    const fileName = `coverage_${DateTime.fromISO(start)
      .setZone(customerSettings.general.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}_${DateTime.fromISO(end)
      .setZone(customerSettings.general.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}`;
    hiddenElement.download = `${fileName}.csv`;
    hiddenElement.click();
  }

  function downloadCappedCoverageCsv() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const blocks = [];
    for (let i = 0; i < zoneAnalytics.length; i++) {
      for (let j = 0; j < zoneAnalytics[i].subZoneList.length; j++) {
        for (let k = 0; k < zoneAnalytics[i].subZoneList[j].subZoneList.length; k++) {
          if (
            Math.round(
              (zoneAnalytics[i].subZoneList[j].subZoneList[k].acreTotal /
                zoneAnalytics[i].subZoneList[j].subZoneList[k].area) *
                100
            ) > 0
          ) {
            const blockData = JSON.parse(JSON.stringify(zoneAnalytics[i].subZoneList[j].subZoneList[k]));
            blockData.parentField = zoneAnalytics[i].subZoneList[j].name;
            blockData.parentRegion = zoneAnalytics[i].name;
            blocks.push(blockData);
          }
        }
      }
    }

    // Sort the categorized data by operation time
    blocks.sort((a, b) => {
      return a.timeTotal < b.timeTotal ? 1 : -1;
    });

    // Push the block based data into the csv data table
    const csvList = [];

    for (let i = 0; i < blocks.length; i++) {
      const tasksTotals = {};
      // Collect data from all vehicle in block with task into same value
      for (let j = 0; j < blocks[i].vehicleList.length; j++) {
        for (let k = 0; k < blocks[i].vehicleList[j].taskList.length; k++) {
          // Skip any non in task blocks
          if (blocks[i].vehicleList[j].taskList[k].name != taskAcreageCapTrackerTask) {
            continue;
          }

          if (!tasksTotals.hasOwnProperty(blocks[i].vehicleList[j].taskList[k].name)) {
            tasksTotals[blocks[i].vehicleList[j].taskList[k].name] = {
              timeTotal: blocks[i].vehicleList[j].taskList[k].timeTotal,
              acreTotal: Math.round(blocks[i].vehicleList[j].taskList[k].acreTotal * 100) / 100,
            };
          } else {
            tasksTotals[blocks[i].vehicleList[j].taskList[k].name].timeTotal +=
              blocks[i].vehicleList[j].taskList[k].timeTotal;
            tasksTotals[blocks[i].vehicleList[j].taskList[k].name].acreTotal +=
              Math.round(blocks[i].vehicleList[j].taskList[k].acreTotal * 100) / 100;
          }
        }
      }

      const tasksTotalsKeys = Object.keys(tasksTotals);
      tasksTotalsKeys.forEach((taskName) => {
        const tasksTotalsObj = tasksTotals[taskName];

        let blockDisplayedCappedAc = tasksTotalsObj.acreTotal;
        let blockDisplayedCappedAcText = '';
        if (
          taskAcreageCapTracker.hasOwnProperty(taskName) &&
          taskAcreageCapTracker[taskName].hasOwnProperty(blocks[i].name)
        ) {
          blockDisplayedCappedAc = taskAcreageCapTracker[taskName][blocks[i].name];
          blockDisplayedCappedAcText = `\"Zone Completed - Acre Cap Applied\"`;
        }
        csvList.push([
          [
            `\"${blocks[i].parentRegion}\"`,
            `\"${blocks[i].parentField}\"`,
            `\"${blocks[i].name}\"`,
            taskName,
            tasksTotalsObj.timeTotal,
            blockDisplayedCappedAc,
            blockDisplayedCappedAcText,
          ],
        ]);
      });
    }

    // Construct the final csv by delimiting the sorted data array
    let csvHeader = `\"Region Name\",\"Field Name\",\"Block Name\",\"Task Name\",\"Coverage Minutes\",\"Coverage Acreage\",\"Notes\",`;

    csvHeader = csvHeader + '\n';

    let csv = csvHeader;

    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';

    let start = datesCache.dates.start;
    let end = datesCache.dates.end;

    if (todayOnly) {
      start = dates.start;
      end = dates.end;
    }

    const fileName = `coverage_cappedAcre_${DateTime.fromISO(start)
      .setZone(customerSettings.general.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}_${DateTime.fromISO(end)
      .setZone(customerSettings.general.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}`;
    hiddenElement.download = `${fileName}.csv`;
    hiddenElement.click();
  }

  function downloadEquipmentCsv() {
    // Make copy of list or else will get error when trying to sort
    const equipmentAnalyticsNameSorted = JSON.parse(JSON.stringify(equipmentAnalytics));
    // Sort by name
    equipmentAnalyticsNameSorted.sort((a, b) => {
      return sortVehicleNamesHelper(a.name, b.name);
    });

    const csvList = [];
    for (let i = 0; i < equipmentAnalyticsNameSorted.length; i++) {
      let timetotal = equipmentAnalyticsNameSorted[i].timeInField + equipmentAnalyticsNameSorted[i].timeOutField;

      let acPerHour = 0;
      let inFieldSpeed = 0;
      if (equipmentAnalyticsNameSorted[i].timeInField) {
        acPerHour = (equipmentAnalyticsNameSorted[i].acreTotal / equipmentAnalyticsNameSorted[i].timeInField) * 60;
        inFieldSpeed =
          equipmentAnalyticsNameSorted[i].distanceInField / (equipmentAnalyticsNameSorted[i].timeInField / 60.0);
      }

      if (customerSettings.general.idleTime) {
        timetotal += equipmentAnalyticsNameSorted[i].timeIdle;
      }

      const csvRow = [
        `\"${equipmentAnalyticsNameSorted[i].name}\"`,
        `\"${Math.round(equipmentAnalyticsNameSorted[i].acreTotal * 10) / 10}\"`,
        `\"${equipmentAnalyticsNameSorted[i].timeInField}\"`,
        `\"${Math.round(acPerHour * 10) / 10}\"`,
        userSettings.general.units == 'imperial'
          ? `${(inFieldSpeed * 0.621).toFixed(1)}`
          : `${inFieldSpeed.toFixed(1)}`,
      ];

      if (customerSettings.general.outOfFieldTime) {
        csvRow.push(`\"${equipmentAnalyticsNameSorted[i].timeOutField}\"`);
      }
      if (customerSettings.general.idleTime) {
        csvRow.push(`\"${equipmentAnalyticsNameSorted[i].timeIdle}\"`);
      }

      csvRow.push(`\"${timetotal}\"`);

      csvList.push(csvRow);
    }

    // Construct the final csv by delimiting the sorted data array
    let csvHeader = `\"Equipment\",\"Field Acreage (ac)\",\"In Field Time (min)\",\"Acres/Hour (ac/hr)\",`;

    if (userSettings.general.units == 'imperial') {
      csvHeader = csvHeader + `\"In Field Speed (mph)\",`;
    } else {
      csvHeader = csvHeader + `\"In Field Speed (kph)\",`;
    }
    if (customerSettings.general.outOfFieldTime) {
      csvHeader = csvHeader + `\"Out of Field Time (min)\",`;
    }
    if (customerSettings.general.idleTime) {
      csvHeader = csvHeader + `\"Idle Time (min)\",`;
    }
    csvHeader = csvHeader + `\"Total Time (min)\",\n`;

    let csv = csvHeader;
    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';

    let start = datesCache.dates.start;
    let end = datesCache.dates.end;

    if (todayOnly) {
      start = dates.start;
      end = dates.end;
    }

    const fileName = `equipment_${DateTime.fromISO(start)
      .setZone(customerSettings.general.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}_${DateTime.fromISO(end)
      .setZone(customerSettings.general.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}`;
    hiddenElement.download = `${fileName}.csv`;
    hiddenElement.click();
  }

  function toggleTableDisplay(table) {
    dispatch(updateDisplayedTable(table));
    setTableCache(table);
    sendGAEvent('coverage_table_view', table, 'cropview');
  }

  function toggleSprayTable() {
    // Go to cached table if untoggling spray table
    if (displayedTable == 'spray') {
      dispatch(updateDisplayedTable(tableCache));
      sendGAEvent('coverage_table_view', tableCache, 'cropview');
    } else {
      dispatch(updateDisplayedTable('spray'));
      sendGAEvent('coverage_table_view', 'spray', 'cropview');
    }
  }

  async function toggleEdit() {
    if (editCropview === false) {
      dispatch(updateEditCropview(true));
    } else {
      const startTime = DateTime.fromISO(dates.start).setZone(customerSettings.general.timeZone);
      const endTime = DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone);

      for (let i = 0; i < editCropviewValues.length; i++) {
        if (displayedTable == 'equipment' && todayOnly) {
          await createTaskEvent(editCropviewValues[i]);
        } else {
          await updateSelectedTask(editCropviewValues[i], startTime, endTime);
        }
      }

      for (let i = 0; i < editCropviewRecValues.length; i++) {
        await createRecEvent(editCropviewRecValues[i]);
      }

      if (displayedTable !== 'equipment') {
        // Create a buffer around the start and end times just to be sure
        // we don't miss any aggregated documents for the time ranges at the ends
        let bufferedDeleteStartDate = startTime.minus({days: 1});
        bufferedDeleteStartDate = bufferedDeleteStartDate.setZone(customerSettings.general.timeZone).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        let bufferedDeleteEndDate = endTime.plus({days: 1});
        bufferedDeleteEndDate = bufferedDeleteEndDate.setZone(customerSettings.general.timeZone).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        });

        const dateRange = Interval.fromDateTimes(bufferedDeleteStartDate, bufferedDeleteEndDate);
        const splitInterval = Duration.fromObject({days: 1}); // 1 day
        const intervals = dateRange.splitBy(splitInterval);

        const allIntervals = [];

        console.log('Reprocessing KPIs');

        // KPI Re process
        const kpiPost = {
          startTime: startTime,
          endTime: endTime,
        };

        const kpiOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(kpiPost),
        };
      }
      dispatch(updateEditCropview(false));
      getCoverageData();
      sendGAEvent('edit_cropview_save', displayedTable, 'cropview');
    }
  }

  function toggleTodaysActivity(e) {
    dispatch(updateEditCropview(false));
    if (todayOnly === false) {
      // Set today only to be true to disable view buttons other than equipment and add task header to the equipment tab
      dispatch(updateTodayOnly(true));

      const [shiftStartDate, shiftEndDate] = getShiftDates();

      setDatesCache({
        dates: {
          start: DateTime.fromISO(dates.start).setZone(customerSettings.general.timeZone),
          end: DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone),
          max: DateTime.fromISO(dates.max).setZone(customerSettings.general.timeZone),
        },
      });

      const newTodaysDate = {
        start: shiftStartDate.toISO(),
        end: shiftEndDate.toISO(),
        max: shiftEndDate.toISO(),
      };

      keyRef.current = DateTime.now();
      dispatch(updateDates(newTodaysDate));

      sendGAEvent('todays_activity', 'selected', 'cropview');
    } else {
      // If toggled off, set to previous dates
      dispatch(updateDisplayedTable(tableCache));
      keyRef.current = DateTime.now();

      if (typeof datesCache.dates.start === 'string') {
        dispatch(
          updateDates({
            start: datesCache.dates.start,
            end: datesCache.dates.end,
            max: datesCache.dates.max,
          })
        );
      } else {
        dispatch(
          updateDates({
            start: datesCache.dates.start.toISO(),
            end: datesCache.dates.end.toISO(),
            max: datesCache.dates.max.toISO(),
          })
        );
      }

      const [shiftStartDate, shiftEndDate] = getShiftDates();

      if (typeof datesCache.dates.start === 'string') {
        const shiftStartDateString = shiftStartDate.toISO();
        const shiftEndDateString = shiftEndDate.toISO();
        if (!(datesCache.dates.start == shiftStartDateString && datesCache.dates.end == shiftEndDateString)) {
          dispatch(updateTodayOnly(false));
        }
      } else {
        if (!(datesCache.dates.start.equals(shiftStartDate) && datesCache.dates.end.equals(shiftEndDate))) {
          dispatch(updateTodayOnly(false));
        }
      }

      sendGAEvent('todays_activity', 'unselected', 'cropview');
    }
  }

  function initCoverageData() {
    // Init using current shift
    const [shiftStartDate, shiftEndDate] = getShiftDates();

    // Check if values were inputted through url
    const startParam = decodeURIComponent(searchParams.get('start'));
    const endParam = decodeURIComponent(searchParams.get('end'));
    setLocate(decodeURIComponent(searchParams.get('locate')));

    let startTime = DateTime.fromISO(startParam).setZone(customerSettings.general.timeZone);
    let endTime = DateTime.fromISO(endParam).setZone(customerSettings.general.timeZone);

    // Ensure the url values are valid or else they need to be re-initilizationed
    if (!startTime.isValid || !endTime.isValid || (startTime >= shiftEndDate && endTime >= shiftEndDate)) {
      // If dates are after the lastUploadTime, or the values are invalid, set to the 24hr before
      startTime = shiftStartDate;
      endTime = shiftEndDate;
    } else if (startTime.isValid && endTime.isValid && startTime < shiftEndDate && endTime >= shiftEndDate) {
      // Otherwise the dates are valid, check if the end time is after the lastUploadTime
      endTime = shiftEndDate;
    }

    // Detect if the initialied date is today
    if (startTime.equals(shiftStartDate) && endTime.equals(shiftEndDate)) {
      dispatch(updateTodayOnly(true));
    } else {
      dispatch(updateTodayOnly(false));
    }

    keyRef.current = DateTime.now();
    const newDates = {
      start: startTime.toISO(),
      end: endTime.toISO(),
      max: shiftEndDate.toISO(),
    };
    dispatch(updateDates(newDates));
    setDatesCache({
      dates: newDates,
    });
  }

  async function getCoverageData() {
    if (loading && typeof abortController !== 'undefined') {
      abortController.abort();
    }

    // Create abort controller
    abortController = new AbortController();

    dispatch(updateLoading(true));
    // Update url and replace in history
    window.history.replaceState(
      null,
      '',
      window.location.origin +
        window.location.pathname +
        `?start=${encodeURIComponent(dates.start)}&end=${encodeURIComponent(dates.end)}&locate=${encodeURIComponent(
          locate
        )}`
    );

    const startDateObj = DateTime.fromISO(dates.start).setZone(customerSettings.general.timeZone);
    const endDateObj = DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone);

    // Often for when selecting a single day
    // Due to a certain shift time the start and end time will be the same
    // In this case don't perform a query and just clear out the data
    if (startDateObj.hasSame(endDateObj, 'minute')) {
      dispatch(updateClearData());
    } else {
      // Get cropview data
      let cropviewDataResponse = {};
      try {
        cropviewDataResponse = await fetch(
          // Remove noGps when cropview fully migrated to gps processing
          `/cropview/getCropviewData?start=${encodeURIComponent(dates.start)}&end=${encodeURIComponent(
            dates.end
          )}&noGps=${encodeURIComponent(!props.gpsModeEnabled)}`,
          {cache: 'no-store', signal: abortController.signal}
        );
      } catch (err) {
        if (err.name == 'AbortError') {
          // handle abort()
          console.log('Aborting getting coverage data for old range');
          return;
        } else {
          console.error(err);
        }
      }

      // Separate cropview data
      const cropviewData = await cropviewDataResponse.json();
      const vehicleSNDict = cropviewData.vehicleSNDict ?? {};
      const implementSNDict = cropviewData.implementSNDict ?? {};
      const zonesData = cropviewData.zonesData ?? {};
      const taskConfigIdDict = cropviewData.taskConfigIdDict ?? {};
      const geoFences = cropviewData.geoFences ?? {};
      const zoneAnalytics = cropviewData.zoneAnalytics ?? {};
      const equipmentAnalytics = cropviewData.equipmentAnalytics ?? {};
      const vehiclePathSNDict = cropviewData.vehiclePathSNDict ?? {};
      const vehicleRowLinesDict = cropviewData.vehicleRowLinesDict ?? {};
      const latestTripTaskEndTimeStr = cropviewData.latestTripTaskEndTimeStr ?? '';
      const recList = cropviewData.recList ?? [];
      const reiActive = cropviewData.reiActive ?? [];

      dispatch(
        updateCoverageData({
          vehicleSNDict,
          implementSNDict,
          zonesData,
          taskConfigIdDict,
          geoFences,
          zoneAnalytics,
          equipmentAnalytics,
          vehiclePathSNDict,
          vehicleRowLinesDict,
          recList,
        })
      );
      dispatch(updateGpsLoaded(props.gpsModeEnabled));
      dispatch(updateLastTripTaskEndTime(latestTripTaskEndTimeStr));
      dispatch(updateReiActive(reiActive));
    }

    dispatch(updateZoneZoom(locate));
    dispatch(updateLoading(false));
  }

  async function getGpsData() {
    if (loading && typeof abortController !== 'undefined') {
      abortController.abort();
    }

    // Create abort controller
    abortController = new AbortController();

    dispatch(updateLoading(true));
    // Update url and replace in history
    window.history.replaceState(
      null,
      '',
      window.location.origin +
        window.location.pathname +
        `?start=${encodeURIComponent(dates.start)}&end=${encodeURIComponent(dates.end)}&locate=${encodeURIComponent(
          locate
        )}`
    );

    const startDateObj = DateTime.fromISO(dates.start).setZone(customerSettings.general.timeZone);
    const endDateObj = DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone);

    // Often for when selecting a single day
    // Due to a certain shift time the start and end time will be the same
    // In this case don't perform a query and just clear out the data
    if (startDateObj.hasSame(endDateObj, 'minute')) {
      dispatch(updateClearData());
    } else {
      // Get cropview data
      let cropviewGpsOnlyDataResponse = {};
      try {
        cropviewGpsOnlyDataResponse = await fetch(
          // Remove noGps when cropview fully migrated to gps processing
          `/cropview/getCropviewDataGpsOnly?start=${encodeURIComponent(dates.start)}&end=${encodeURIComponent(
            dates.end
          )}`,
          {cache: 'no-store', signal: abortController.signal}
        );
      } catch (err) {
        if (err.name == 'AbortError') {
          // handle abort()
          console.log('Aborting getting coverage data for old range');
          return;
        } else {
          console.error(err);
        }
      }

      const cropviewGpsOnlyData = await cropviewGpsOnlyDataResponse.json();

      dispatch(updateVehicleSNDict(cropviewGpsOnlyData));
    }

    dispatch(updateGpsLoaded(true));
    dispatch(updateZoneZoom(locate));
    dispatch(updateLoading(false));
  }

  async function updateSelectedTask(selectValues, startTime, endTime) {
    const intelliblockNums = zoneNameToIntelliBlockNums[selectValues.type][selectValues.zoneName];
    // POST the data to the backend to allow server to update the DB
    const postData = {
      intelliblockNums: intelliblockNums,
      startTime: startTime.toISO(),
      endTime: endTime.toISO(),
      oldTaskId: selectValues.taskId,
      taskId: selectValues.newTaskId,
      selectedVeh: selectValues.vehicleSN,
      zoneLevel: selectValues.type,
      zoneName: selectValues.zoneName,
      lastChunk: true,
      implementSN: selectValues.implementSN,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    };

    const response = await fetchPostAuthSafe(
      '/cropview/updateSelectedTask',
      options,
      userSettings.username,
      userSettings.databaseName
    );

    const result = await response.json();
    if (result.success !== true) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }
  }

  async function createTaskEvent(selectValues) {
    // POST the data to the backend to allow server to update the DB

    // Get the shift end date
    const [_, shiftEndDate] = getShiftDates();

    const postData = {
      selectedVeh: selectValues.vehicleSN,
      taskId: selectValues.newTaskId,
      status: 'on',
      eventInfo: 'vehSel',
      shiftEndTime: shiftEndDate.toISO(),
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    };

    const response = await fetchPostAuthSafe(
      '/cropview/addTaskEvent',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }
  }

  async function createRecEvent(selectValues) {
    const blockIdList = [];
    let startTime;
    let endTime;
    let reiHours = 0;

    // Find rec object
    const recObj = recList.find((obj) => {
      return obj.id == selectValues.recId;
    });
    if (typeof recObj == 'undefined' && selectValues.recId != '') {
      return;
    } else if (typeof recObj != 'undefined') {
      reiHours = recObj.reentryHours;
    }

    if (selectValues.type == 'equipment') {
      const startTimeObj = DateTime.now();
      const [_, endTimeObj] = getShiftDates();
      startTime = startTimeObj.setZone('utc').toISO();
      endTime = endTimeObj.setZone('utc').toISO();

      blockIdList.push('');
    } else {
      startTime = selectValues.entryTime;
      endTime = selectValues.exitTime;

      // Find blocks for which the rec applies
      if (selectValues.type == 'block') {
        const blockDoc = zonesData.blocks.find((block) => {
          return block.block_name == selectValues.zoneName;
        });
        if (typeof blockDoc == 'undefined') {
          return;
        }
        blockIdList.push(blockDoc.block_id);
      } else if (selectValues.type == 'field') {
        const fieldDoc = zonesData.fields.find((field) => {
          return field.field_name == selectValues.zoneName;
        });
        if (typeof fieldDoc == 'undefined') {
          return;
        }
        zonesData.blocks.forEach((blockDoc) => {
          if (blockDoc.parent_field_id == fieldDoc.field_id) {
            blockIdList.push(blockDoc.block_id);
          }
        });
      } else if (selectValues.type == 'region') {
        const regionDoc = zonesData.regions.find((region) => {
          return region.region_name == selectValues.zoneName;
        });
        if (typeof regionDoc == 'undefined') {
          return;
        }
        const targetFieldIds = [];
        zonesData.fields.forEach((fieldDoc) => {
          if (fieldDoc.parent_region_id == regionDoc.region_id) {
            targetFieldIds.push(fieldDoc.field_id);
          }
        });
        zonesData.blocks.forEach((blockDoc) => {
          if (targetFieldIds.includes(blockDoc.parent_field_id)) {
            blockIdList.push(blockDoc.block_id);
          }
        });
      }
    }

    const postData = {
      zoneIdList: blockIdList,
      zoneLevel: 'block',
      startTime: startTime,
      endTime: endTime,
      vehicleSN: selectValues.vehicleSN,
      recId: selectValues.recId,
      reiHours: reiHours,
      user: userSettings.username,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    };

    const response = await fetchPostAuthSafe(
      '/cropview/addRecEvent',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }
  }

  function findTripTasksDocs(zoneLevel, zoneName, vehicleSN, taskId) {
    /*
    TODO: This function can really be just converted to calling findZoneData,
    then applying 1 more layer of filtering on vehicleSN
    instead of doing another full layer of recursive serach and relying on:
    zoneData[i].vehicleList[j].taskList[k].docList data structure
    */

    // First find the correct zone object to be operating on
    let zoneTarget = findTargetZoneData(zoneName, zoneLevel, zoneAnalytics);

    // There should only be a single matching structure detected
    if (zoneTarget.length > 0) {
      zoneTarget = zoneTarget[0];
    } else {
      return [];
    }

    const docListCompiled = compileTripTasksDocs(vehicleSN, taskId, zoneTarget);
    // Collect the relevant docList objects
    return docListCompiled;
  }

  function findTargetZoneData(zoneName, zoneLevel, zoneData) {
    const zoneDataMatched = [];
    // Recursively try to determine the list of all docs
    if (
      Object.prototype.hasOwnProperty.call(zoneData, 'zoneLevel') &&
      typeof zoneData.zoneLevel !== 'undefined' &&
      zoneData.zoneLevel === zoneLevel &&
      zoneData.name === zoneName
    ) {
      // If matched level and name, this is the structure under search
      return [zoneData];
    } else if (
      Object.prototype.hasOwnProperty.call(zoneData, 'zoneLevel') &&
      typeof zoneData.zoneLevel !== 'undefined' &&
      zoneData.zoneLevel == zoneLevel &&
      zoneData.name != zoneName
    ) {
      // If matched level but not name, do not search deeper
      return [];
    } else if (Object.prototype.hasOwnProperty.call(zoneData, 'subZoneList') && zoneData.subZoneList.length > 0) {
      // If subZoneList exists and havent already matched, must be currently operating on a higher level field
      for (let i = 0; i < zoneData.subZoneList.length; i++) {
        const zoneobj = findTargetZoneData(zoneName, zoneLevel, zoneData.subZoneList[i]);
        zoneDataMatched.push(...zoneobj);
      }
    } else if (zoneData && zoneData.length > 0) {
      // If neither of these exists, must be operating zoneData which is a list of region zoneData items
      for (let i = 0; i < zoneData.length; i++) {
        const zoneobj = findTargetZoneData(zoneName, zoneLevel, zoneData[i]);
        zoneDataMatched.push(...zoneobj);
      }
    }
    return zoneDataMatched;
  }

  function compileTripTasksDocs(vehicleSN, taskId, zoneData) {
    const docListCompiled = [];
    if (zoneData.zoneLevel == 'block') {
      // the zoneData level is block, thus try to match and collect docList data
      for (let j = 0; j < zoneData.vehicleList.length; j++) {
        for (let k = 0; k < zoneData.vehicleList[j].taskList.length; k++) {
          if (zoneData.vehicleList[j].serialNumber == vehicleSN && zoneData.vehicleList[j].taskList[k].id == taskId) {
            // The found docList should be completely unqiue, thus can be returned immediately
            return zoneData.vehicleList[j].taskList[k].docList;
          }
        }
      }
    } else {
      // the request level is higher than block, thus loop on lower level
      for (let i = 0; i < zoneData.subZoneList.length; i++) {
        const tempDocs = compileTripTasksDocs(vehicleSN, taskId, zoneData.subZoneList[i]);
        docListCompiled.push(...tempDocs);
      }
    }
    return docListCompiled;
  }
}

export {Menu};
