import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {FilterCard} from '../../../components/Card';
import {numberWithCommas, arrayToggle, unitsDisplayConversion, sortVehicleNamesHelper} from '../../../app/utils';
import {UpcomingTable} from './UpcomingTable';
import {DateTime} from 'luxon';

function UpcomingTab(props) {
  const shopviewData = useSelector((state) => {
    return state.shopview;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });

  const [tableData, setTableData] = useState([]);
  const [cardValues, setCardValues] = useState({
    overdue: 0,
    upcoming: 0,
  });
  const [reminderFilter, setReminderFilter] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    vehicles: [],
    tasks: [],
  });
  const [units, setUnits] = useState('km');

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  useEffect(() => {
    if (shopviewData.initialized) {
      getUpcoming();
    }
  }, [shopviewData]);

  function handleFilterButtons(event) {
    const newFilters = arrayToggle(reminderFilter, event.currentTarget.name);
    setReminderFilter(newFilters);
  }

  const UPCOMING_ENG_HR_THRESH = customerSettings.shopview?.upcomingEngHrThresh || 10;
  const UPCOMING_ODO_THRESH = customerSettings.shopview?.upcomingOdoThresh || 500;

  function getUpcoming() {
    // Parse shopview data and update component states
    const vehiclesLookUp = shopviewData.vehiclesLookUp;
    const serviceTasks = shopviewData.serviceTasks;
    const serviceEntriesLookUp = shopviewData.serviceEntriesLookUp;
    const currOdometerDict = shopviewData.currOdometerDict;
    const currEngineHoursDict = shopviewData.currEngineHoursDict;

    let overdueCnt = 0;
    let upcomingCnt = 0;
    let rowList = [];
    const filterOptionsVehicle = [];
    const filterOptionsTask = [];

    const localTimeZone = DateTime.local().zoneName;
    const currDateObj = DateTime.local({zone: localTimeZone}).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    // Loop through all the serviceTasks to find the last know serviceEntry
    for (let i = 0; i < serviceTasks.length; i++) {
      const serviceTask = serviceTasks[i].data;
      let overdue = false;
      let upcoming = false;
      let noHistory = false;
      let latestDate = null;
      let latestOdometer = null;
      let latestEngineHours = null;
      let latestReviewedBy = null;
      let timeDiff = Infinity;
      let odometerDiff = Infinity;
      let engineHoursDiff = Infinity;

      // Match the service task with a vehicle
      if (
        !Object.prototype.hasOwnProperty.call(serviceTask, 'vehicleSN') ||
        !Object.prototype.hasOwnProperty.call(vehiclesLookUp, serviceTask.vehicleSN)
      ) {
        console.log('Task ', serviceTask.name, ' is missing its vehicle - SN: ', serviceTask.vehicleSN);
        continue;
      }

      if (!Object.prototype.hasOwnProperty.call(serviceTask, 'deleted') || serviceTask.deleted !== false) {
        continue;
      }

      const vehicle = vehiclesLookUp[serviceTask.vehicleSN];
      if (!vehicle) {
        continue;
      }

      // Skip Vehicle based on custom vehicle filter or vehicle not set to active for shopview
      if (vehicle?.shopActive === false) {
        continue;
      }

      const currOdometer = currOdometerDict[vehicle.serialNumber] || 0;
      const currEngineHours = currEngineHoursDict[vehicle.serialNumber] || 0;
      // Find latest history entry
      if (typeof serviceEntriesLookUp[serviceTask.id] === 'undefined') {
        noHistory = true;
        latestDate = serviceTask.start_date;
        latestOdometer = serviceTask.start_odometer;
        latestEngineHours = serviceTask.start_engine_hours;
        latestReviewedBy = '';
      } else {
        for (let j = 0; j < serviceEntriesLookUp[serviceTask.id].length; j++) {
          const serviceEntryMatch = serviceEntriesLookUp[serviceTask.id][j];

          if (latestDate == null) {
            latestDate = serviceEntryMatch.date;
            latestOdometer = serviceEntryMatch.odometer;
            latestEngineHours = serviceEntryMatch.engine_hours;
            latestReviewedBy = serviceEntryMatch.reviewed_by;
          } else {
            /*
          Date values from entries are going to be at the equivalent date value but shifted at midnight UTC
          To properly extract,
          the value will need to be re-created at the same date value but local timezone midnight
          */
            const newLatestDateObj = DateTime.fromISO(serviceEntryMatch.date['@ts'].slice(0, 10), 'yyyy-MM-dd', {
              zone: localTimeZone,
            }).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
            const oldLatestDateObj = DateTime.fromISO(latestDate['@ts'].slice(0, 10), 'yyyy-MM-dd', {
              zone: localTimeZone,
            }).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });

            // if the dates are the same then we only want to proceed if the engine hour / odo value are greater
            if (newLatestDateObj >= oldLatestDateObj) {
              if (latestOdometer <= serviceEntryMatch.odometer || latestEngineHours <= serviceEntryMatch.engine_hours) {
                latestOdometer = serviceEntryMatch.odometer;
                latestEngineHours = serviceEntryMatch.engine_hours;
                latestDate = serviceEntryMatch.date;
                latestReviewedBy = serviceEntryMatch.reviewed_by;
              }
            }
          }
        }
      }

      // Service Task Intervals
      let intervalString = '';
      if (serviceTask.time_interval) {
        intervalString = intervalString + `Every ${serviceTask.time_interval} ${serviceTask.time_units}`;
      }
      if (serviceTask.odometer_interval) {
        intervalString =
          intervalString +
          (intervalString != '' ? '<br>' : '') +
          `Every ${numberWithCommas(unitsDisplayConversion(serviceTask.odometer_interval, units))} ${units}`;
      }
      if (serviceTask.engine_hours_interval) {
        intervalString =
          intervalString +
          (intervalString != '' ? '<br>' : '') +
          `Every ${numberWithCommas(serviceTask.engine_hours_interval)} engine hours`;
      }

      // Due In Calculations
      let dueString = '';
      let nextServiceDate = null;
      let latestDateObj = null;
      if (serviceTask.next_service_date) {
        nextServiceDate = DateTime.fromISO(serviceTask.next_service_date['@ts'], {zone: localTimeZone}).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }
      if (latestDate) {
        latestDateObj = DateTime.fromISO(latestDate['@ts'], {zone: localTimeZone}).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      if (serviceTask.next_service_date && noHistory) {
        timeDiff = nextServiceDate.diff(currDateObj, 'days').toObject();
        // Use absolute value for proper rounding
        timeDiff = Math.ceil(Math.abs(timeDiff.days)) * Math.sign(timeDiff.days);

        let unitsDisplay = 'Days';
        let timeDisplay = Math.abs(timeDiff);
        if (timeDisplay >= 30) {
          unitsDisplay = 'Months';
          timeDisplay = Math.floor(timeDisplay / 30);
        } else if (timeDisplay >= 7) {
          unitsDisplay = 'Weeks';
          timeDisplay = Math.floor(timeDisplay / 7);
        }

        if (timeDiff < 0) {
          dueString += `<b>${timeDisplay} ${unitsDisplay} Overdue</b>`;
          overdue = true;
        } else {
          dueString += `${timeDisplay} ${unitsDisplay}`;
          if (timeDiff < 7) {
            upcoming = true;
          }
        }
      } else if (serviceTask.time_interval) {
        let tempInterval = serviceTask.time_interval;
        if (serviceTask.time_units == 'weeks') {
          tempInterval = serviceTask.time_interval * 7;
        } else if (serviceTask.time_units == 'months') {
          tempInterval = serviceTask.time_interval * 30;
        }

        if (noHistory) {
          const startDate = DateTime.fromISO(serviceTask.start_date['@ts'], {zone: localTimeZone}).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

          nextServiceDate = startDate.plus({days: tempInterval});
        } else {
          latestDateObj = DateTime.fromFormat(latestDate['@ts'].slice(0, 10), 'yyyy-MM-dd', {
            zone: localTimeZone,
          }).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

          nextServiceDate = latestDateObj.plus({days: tempInterval});
        }

        timeDiff = nextServiceDate.diff(currDateObj, 'days').toObject();
        // Use absolute value for proper rounding
        timeDiff = Math.ceil(Math.abs(timeDiff.days)) * Math.sign(timeDiff.days);

        let unitsDisplay = 'Days';
        let timeDisplay = Math.abs(timeDiff);
        if (timeDisplay >= 30) {
          unitsDisplay = 'Months';
          timeDisplay = Math.floor(timeDisplay / 30);
        } else if (timeDisplay >= 7) {
          unitsDisplay = 'Weeks';
          timeDisplay = Math.floor(timeDisplay / 7);
        }

        if (timeDiff < 0) {
          dueString += `<b>${timeDisplay} ${unitsDisplay} Overdue</b>`;
          overdue = true;
        } else {
          dueString += `${timeDisplay} ${unitsDisplay}`;
          if (timeDiff < 7) {
            upcoming = true;
          }
        }
      }

      if (serviceTask.next_service_odometer && noHistory) {
        odometerDiff = serviceTask.next_service_odometer - currOdometer;

        if (odometerDiff < 0) {
          dueString +=
            (dueString != '' ? '<br>' : '') +
            `<b>${numberWithCommas(Math.abs(unitsDisplayConversion(odometerDiff, units)))} ${units} Overdue</b>`;
          overdue = true;
        } else {
          dueString +=
            (dueString != '' ? '<br>' : '') +
            `${numberWithCommas(Math.abs(unitsDisplayConversion(odometerDiff, units)))} ${units}`;
          if (Math.abs(unitsDisplayConversion(odometerDiff, units)) < UPCOMING_ODO_THRESH) {
            upcoming = true;
          }
        }
      } else if (serviceTask.odometer_interval) {
        if (currOdometer < Math.floor(latestOdometer) && !noHistory) {
          // Something is VERY wrong here simply report and update to show the interval
          dueString +=
            (dueString != '' ? '<br>' : '') +
            `<b style='color:red !important;'> Odometer records are larger than measured! </b>`;
        } else if (noHistory /* && currOdometer < latestOdometer */) {
          const nextServiceOdometer = serviceTask.start_odometer + serviceTask.odometer_interval;
          odometerDiff = nextServiceOdometer - currOdometer;

          if (odometerDiff < 0) {
            dueString +=
              (dueString != '' ? '<br>' : '') +
              `<b>${numberWithCommas(Math.abs(unitsDisplayConversion(odometerDiff, units)))} ${units} Overdue</b>`;
            overdue = true;
          } else {
            dueString +=
              (dueString != '' ? '<br>' : '') +
              `${numberWithCommas(Math.abs(unitsDisplayConversion(odometerDiff, units)))} ${units}`;
            if (Math.abs(unitsDisplayConversion(odometerDiff, units)) < UPCOMING_ODO_THRESH) {
              upcoming = true;
            }
          }
        } else {
          odometerDiff = latestOdometer + serviceTask.odometer_interval - currOdometer;

          if (odometerDiff < 0) {
            dueString +=
              (dueString != '' ? '<br>' : '') +
              `<b>${numberWithCommas(Math.abs(unitsDisplayConversion(odometerDiff, units)))} ${units} Overdue</b>`;
            overdue = true;
          } else {
            dueString +=
              (dueString != '' ? '<br>' : '') +
              `${numberWithCommas(Math.abs(unitsDisplayConversion(odometerDiff, units)))} ${units}`;
            if (Math.abs(unitsDisplayConversion(odometerDiff, units)) < UPCOMING_ODO_THRESH) {
              upcoming = true;
            }
          }
        }
      }

      if (serviceTask.next_service_engine_hours && noHistory) {
        engineHoursDiff = serviceTask.next_service_engine_hours - currEngineHours;

        if (engineHoursDiff < 0) {
          dueString +=
            (dueString != '' ? '<br>' : '') +
            `<b>${numberWithCommas(Math.round(Math.abs(engineHoursDiff)))} Engine Hours Overdue</b>`;
          overdue = true;
        } else {
          dueString +=
            (dueString != '' ? '<br>' : '') + `${numberWithCommas(Math.round(Math.abs(engineHoursDiff)))} Engine Hours`;
          if (engineHoursDiff < UPCOMING_ENG_HR_THRESH) {
            upcoming = true;
          }
        }
      } else if (serviceTask.engine_hours_interval) {
        if (currEngineHours < Math.floor(latestEngineHours) && !noHistory) {
          // Something is VERY wrong here simply report and update to show the interval
          dueString +=
            (dueString != '' ? '<br>' : '') +
            `<b style='color:red !important;'> Engine hour records are larger than measured! </b>`;
        } else if (noHistory /* && currEngineHours < latestEngineHours*/) {
          const nextServiceEngineHours = serviceTask.start_engine_hours + serviceTask.engine_hours_interval;
          engineHoursDiff = nextServiceEngineHours - currEngineHours;

          if (engineHoursDiff < 0) {
            dueString +=
              (dueString != '' ? '<br>' : '') +
              `<b>${numberWithCommas(Math.round(Math.abs(engineHoursDiff)))} Engine Hours Overdue</b>`;
            overdue = true;
          } else {
            dueString +=
              (dueString != '' ? '<br>' : '') +
              `${numberWithCommas(Math.round(Math.abs(engineHoursDiff)))} Engine Hours`;
            if (engineHoursDiff < UPCOMING_ENG_HR_THRESH) {
              upcoming = true;
            }
          }
        } else {
          engineHoursDiff = latestEngineHours + serviceTask.engine_hours_interval - currEngineHours;

          if (engineHoursDiff < 0) {
            dueString +=
              (dueString != '' ? '<br>' : '') +
              `<b>${numberWithCommas(Math.round(Math.abs(engineHoursDiff)))} Engine Hours Overdue</b>`;
            overdue = true;
          } else {
            dueString +=
              (dueString != '' ? '<br>' : '') +
              `${numberWithCommas(Math.round(Math.abs(engineHoursDiff)))} Engine Hours`;
            if (engineHoursDiff < UPCOMING_ENG_HR_THRESH) {
              upcoming = true;
            }
          }
        }
      }

      // Last Completed Calculations
      const lastCompletedDate = latestDate['@ts'].slice(0, 10);
      const lastCompletedOdometer =
        latestOdometer === '' ? '' : numberWithCommas(unitsDisplayConversion(latestOdometer, units));
      const lastCompletedEngineHours = latestEngineHours === '' ? '' : numberWithCommas(Math.round(latestEngineHours));

      const latestCompletedDateObj = DateTime.fromISO(latestDate['@ts'], {zone: localTimeZone}).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let timeDiffToLast = currDateObj.diff(latestCompletedDateObj, 'days').toObject();
      // Use absolute value for proper rounding
      timeDiffToLast = Math.floor(Math.abs(timeDiffToLast.days)) * Math.sign(timeDiffToLast.days);

      let unitsDisplay = 'Days';
      let timeDisplay = Math.abs(timeDiffToLast);
      if (timeDisplay >= 30) {
        unitsDisplay = 'Months';
        timeDisplay = Math.floor(timeDisplay / 30);
      } else if (timeDisplay >= 7) {
        unitsDisplay = 'Weeks';
        timeDisplay = Math.floor(timeDisplay / 7);
      }
      const lastCompletedTimeDiff = `${timeDisplay} ${unitsDisplay} Ago`;

      const rowInfo = {
        serviceTask: serviceTask,
        vehicle: vehicle.name,
        vehicleSN: vehicle.serialNumber,
        machineType: vehicle.machineType,
        task: serviceTask.name,
        overdue: overdue,
        upcoming: upcoming,
        intervalString: intervalString,
        dueString: dueString,
        noHistory: noHistory,
        lastCompletedDate: lastCompletedDate,
        lastCompletedTimeDiff: lastCompletedTimeDiff,
        lastCompletedOdometer: lastCompletedOdometer,
        lastCompletedEngineHours: lastCompletedEngineHours,
        reviewedBy: latestReviewedBy,
        timeDiffDays: timeDiff,
        odometerDiff: odometerDiff,
        engineHoursDiff: engineHoursDiff,
      };
      rowInfo.serviceTask = {
        ...rowInfo.serviceTask,
        currOdometer: currOdometer,
        currEngineHours: currEngineHours,
      };
      rowList.push(rowInfo);

      // Add to overdue and upcoming counters
      if (overdue) {
        overdueCnt += 1;
      } else if (upcoming) {
        upcomingCnt += 1;
      }

      // Update filter options
      filterOptionsVehicle.push(vehicle.name);
      filterOptionsTask.push(serviceTask.name);
    }

    // Add filter options to dropdowns
    filterOptionsVehicle.sort((a, b) => {
      return sortVehicleNamesHelper(a, b);
    });
    filterOptionsTask.sort();
    setFilterOptions((values) => {
      return {...values, vehicles: [...new Set(filterOptionsVehicle)], tasks: [...new Set(filterOptionsTask)]};
    });

    // Sort rows based on overdue, upcoming, and no tags
    const rowListOverdue = rowList.filter((row) => {
      return row.overdue;
    });
    const rowListUpcoming = rowList.filter((row) => {
      return row.upcoming && !row.overdue;
    });
    const rowListNormal = rowList.filter((row) => {
      return !row.upcoming && !row.overdue;
    });

    rowListOverdue.sort((a, b) => {
      return Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff) >
        Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff)
        ? 1
        : Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff) >
          Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff)
        ? -1
        : 0;
    });
    rowListUpcoming.sort((a, b) => {
      return Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff) >
        Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff)
        ? 1
        : Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff) >
          Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff)
        ? -1
        : 0;
    });
    rowListNormal.sort((a, b) => {
      return Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff) >
        Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff)
        ? 1
        : Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff) >
          Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff)
        ? -1
        : 0;
    });
    rowList = rowListOverdue.concat(rowListUpcoming, rowListNormal);

    setTableData(rowList);
    setCardValues({
      overdue: overdueCnt,
      upcoming: upcomingCnt,
    });
  }

  function calculateTimeUntilNextService(nextServiceDateTime) {
    const currentDate = DateTime.now().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const diffInDays = nextServiceDateTime.diff(currentDate, 'days').toObject().days;
    const overdue = diffInDays < 0;
    let result = '';
    let unitsDisplay = 'Days';
    let timeDisplay = Math.abs(diffInDays);

    if (timeDisplay >= 30) {
      unitsDisplay = 'Months';
      timeDisplay = Math.floor(timeDisplay / 30);
    } else if (timeDisplay >= 7) {
      unitsDisplay = 'Weeks';
      timeDisplay = Math.floor(timeDisplay / 7);
    }

    if (overdue) {
      result = `<b>${timeDisplay} ${unitsDisplay} Overdue</b>`;
    } else {
      result = `${timeDisplay} ${unitsDisplay}`;
    }

    return {timeUntilNextService: result, overdue, diffInDays};
  }

  return (
    <div className='tab-wrapper'>
      {/* Card for OverDue etc. in Shopview */}
      <div className='row my-3 my-sm-3 tab-top-row'>
        <div className='col-6 my-1'>
          <FilterCard
            color='danger'
            value={cardValues.overdue}
            text='Overdue'
            icon='fa-exclamation-circle'
            name='overdue'
            filterOnClick={handleFilterButtons}
            filterActive={reminderFilter.includes('overdue')}
          />
        </div>
        <div className='col-6 my-1'>
          <FilterCard
            color='warning'
            value={cardValues.upcoming}
            text='Upcoming'
            icon='fa-stopwatch'
            name='upcoming'
            filterOnClick={handleFilterButtons}
            filterActive={reminderFilter.includes('upcoming')}
          />
        </div>
      </div>
      <UpcomingTable
        tableData={tableData}
        filterOptions={filterOptions}
        reminderFilter={reminderFilter}
        loading={shopviewData.loading}
      />
    </div>
  );
}

export {UpcomingTab};
