import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {numberWithCommas, unitsDisplayConversion, sortVehicleNamesHelper} from '../../../app/utils';
import {HistoryTable} from './HistoryTable';

function HistoryTab(props) {
  const shopviewData = useSelector((state) => {
    return state.shopview;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });

  const [tableData, setTableData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    vehicles: [],
    tasks: [],
  });
  const [units, setUnits] = useState('km');

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  useEffect(() => {
    if (shopviewData.initialized) {
      getHistory();
    }
  }, [shopviewData]);

  return (
    <div className='tab-wrapper'>
      <div className='row my-2'>
        <div className='row ml-auto mx-0 col-md-8 col-xl-4'>
          <button type='button' className='btn border-dark btn-light col-6' onClick={downloadCSV}>
            Download CSV
          </button>
          <button
            type='button'
            className='btn border-dark btn-light col-6'
            data-toggle='modal'
            data-target='#shopview-add-history-modal'
          >
            Add History
          </button>
        </div>
      </div>
      <HistoryTable tableData={tableData} filterOptions={filterOptions} loading={shopviewData.loading} />
    </div>
  );

  function downloadCSV() {
    const csvList = [];
    tableData.map((rowData, index) => {
      let exportOdoOverValue =
        rowData.odometerOver || rowData?.odometerOver == 0 ? unitsDisplayConversion(rowData.odometerOver, units) : '';
      let exportEngOverValue = rowData.engineHoursOver;

      if (
        exportOdoOverValue == '' &&
        rowData.serviceEntry?.parentTask?.odometer_interval != '' &&
        typeof rowData.serviceEntry?.parentTask?.odometer_interval != 'undefined'
      ) {
        exportOdoOverValue = 0;
      }

      if (
        exportEngOverValue == '' &&
        rowData.serviceEntry?.parentTask?.engine_hours_interval != '' &&
        typeof rowData.serviceEntry?.parentTask?.engine_hours_interval != 'undefined'
      ) {
        exportEngOverValue = 0;
      }

      csvList.push([
        [
          `\"${rowData.vehicle}\"`,
          `\"${rowData.task}\"`, // Used to ignore commas and breaks
          unitsDisplayConversion(rowData.odometer, units),
          exportOdoOverValue,
          rowData.engineHours,
          exportEngOverValue,
          rowData.dateCompleted,
          rowData.timeOver,
          rowData.timeUnits,
          rowData.workOrder,
          rowData.invoice,
          rowData.laborCost,
          rowData.partsCost,
          rowData.laborTime,
          `\"${rowData.supplier}\"`,
          `\"${rowData.reviewedBy}\"`,
          `\"${rowData.notes}\"`,
        ],
      ]);
    });

    // eslint-disable-next-line max-len
    let csv = `Equipment,Service,Odometer When Complete (${units}),Odometer Over Target (${units}), Engine Hours When Complete (hr),Engine Hours Over Target (hr),Date Completed,Time Over Target,Time Over Units,Work Order,Invoice Number,Labor Cost ($),Parts Cost ($),Labor Time (hrs),Supplier,Reviewed By,Notes\n`;
    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';
    hiddenElement.download = 'history.csv';
    hiddenElement.click();
  }

  function getHistory() {
    // parse shopview data and update component states
    const vehiclesLookUp = shopviewData.vehiclesLookUp;
    const serviceTasks = shopviewData.serviceTasks;
    const serviceEntries = shopviewData.serviceEntries;

    // Create service task lookup for easy acccess
    const taskLookup = {};
    for (let i = 0; i < serviceTasks.length; i++) {
      const serviceTask = serviceTasks[i].data;
      if (!(serviceTask.id in taskLookup)) {
        taskLookup[serviceTask.id] = serviceTask;
      }
    }

    const rowList = [];
    const filterOptionsVehicle = [];
    const filterOptionsTask = [];

    for (let i = 0; i < serviceEntries.length; i++) {
      let serviceEntry = serviceEntries[i].data;

      // Hide hidden archived historic logs
      if (typeof serviceEntry.archived !== 'undefined' && serviceEntry.archived === true) {
        continue;
      }

      // Append the related info to the object immediately
      const serviceTask = taskLookup[serviceEntry.service_task_id];
      if (!serviceTask) {
        console.log("Entry's service task id is missing: ", serviceEntry.service_task_id);
        console.log('Entry: ', serviceEntry);
        continue;
      }

      const vehicle = vehiclesLookUp[serviceTask.vehicleSN];

      if (!vehicle) {
        console.log('Task ', serviceTask.name, ' is missing its vehicle - SN: ', serviceTask.vehicleSN);
        continue;
      }

      const vehicleShopActive = !(vehicle?.shopActive === false);
      // Skip Vehicle if vehicle not set to active for shopview
      if (!vehicleShopActive) {
        continue;
      }

      serviceEntry = {
        ...serviceEntry,
        parentTask: serviceTask,
        vehicleSN: serviceTask.vehicleSN,
        task: serviceTask.name,
      };

      // Collect the serviceEntries that are for the same vehicle
      const serviceTasksSameVeh = serviceTasks.filter((serviceTask) => {
        return serviceTask.data.vehicleSN == serviceEntry.vehicleSN;
      });
      const serviceTasksSameVehIds = serviceTasksSameVeh.map((serviceTask) => {
        return serviceTask.data.id;
      });
      const serviceEntriesSameVeh = serviceEntries.filter((serviceEntry) => {
        return serviceTasksSameVehIds.includes(serviceEntry.data.service_task_id);
      });

      // Check for consistency values
      // const [inconsistentOdo, inconsistentEngHr] = await checkOdoEngHrsHistConsistencyPastToCurrent(
      //   serviceEntriesSameVeh,
      //   serviceEntry
      // );

      const inconsistentOdo = 0;
      const inconsistentEngHr = 0;

      // Task Intervals
      let completedString = '';
      completedString =
        serviceEntry.odometer === ''
          ? completedString
          : completedString +
            `Odometer When Completed: ${numberWithCommas(
              unitsDisplayConversion(serviceEntry.odometer, units)
            )} ${units}`;
      completedString =
        inconsistentOdo === 0 || serviceEntry.odometer === ''
          ? completedString
          : completedString +
            `<br><b style='color:red !important;'>Odometer entered shows some historic inconsistency</b>`;
      completedString =
        serviceEntry.engine_hours === ''
          ? completedString
          : completedString +
            `<br>Engine Hours When Completed: ${numberWithCommas(Math.round(serviceEntry.engine_hours))} hrs`;
      completedString =
        inconsistentEngHr === 0 || serviceEntry.engine_hours === ''
          ? completedString
          : completedString +
            `<br><b style='color:red !important;'>Engine hours entered shows some historic inconsistency</b>`;

      rowList.push({
        serviceEntry: serviceEntry,
        vehicle: vehicle.name,
        vehicleSN: vehicle.serialNumber,
        task: serviceTask.name,
        completedString: completedString,
        odometer: serviceEntry.odometer,
        engineHours: serviceEntry.engine_hours,
        dateCompleted: serviceEntry.date['@ts'].slice(0, 10),
        workOrder: serviceEntry.work_order,
        invoice: serviceEntry.invoice,
        laborCost: serviceEntry.labor_cost,
        partsCost: serviceEntry.parts_cost,
        laborTime: serviceEntry.labor_time,
        supplier: serviceEntry.supplier,
        reviewedBy: serviceEntry.reviewed_by,
        notes: serviceEntry.notes,
        user: serviceEntry.updated_by,
        timeOver: serviceEntry.timeOver,
        timeUnits: serviceEntry.timeUnits,
        odometerOver: serviceEntry.odometerOver,
        engineHoursOver: serviceEntry.engineHoursOver,
      });

      // Update filter options
      filterOptionsVehicle.push(vehicle.name);
      filterOptionsTask.push(serviceTask.name);
    }

    // Add filter options to dropdowns
    filterOptionsVehicle.sort((a, b) => {
      return sortVehicleNamesHelper(a, b);
    });
    filterOptionsTask.sort();
    setFilterOptions((values) => {
      return {...values, vehicles: [...new Set(filterOptionsVehicle)], tasks: [...new Set(filterOptionsTask)]};
    });

    rowList.sort((a, b) => {
      // Sort rows based on date completed
      if (a.dateCompleted < b.dateCompleted) {
        return 1;
      }
      if (a.dateCompleted > b.dateCompleted) {
        return -1;
      }

      // Sort by stats if possible
      if (a.engineHours < b.engineHours) {
        return 1;
      }
      if (a.engineHours > b.engineHours) {
        return -1;
      }

      if (a.odometer < b.odometer) {
        return 1;
      }
      if (a.odometer > b.odometer) {
        return -1;
      }

      return 0;
    });

    setTableData(rowList);
  }
}

export {HistoryTab};
