import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Card} from '../../../components/Card';
import {DiagnosticsTable} from './DiagnosticsTable';
import {DateTime} from 'luxon';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {sortVehicleNamesHelper} from '../../../app/utils';

function DiagnosticsTab(props) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState({
    start: DateTime.local().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
    end: DateTime.local().set({hour: 23, minute: 59, second: 59, millisecond: 999}),
  });
  const [cardValues, setCardValues] = useState({
    codes: 0,
    affected: 0,
  });
  const [filterOptions, setFilterOptions] = useState({
    vehicles: [],
    faults: [],
  });
  const vehiclesLookUp = useSelector((state) => {
    return state.shopview.vehiclesLookUp;
  });
  const shopviewData = useSelector((state) => {
    return state.shopview;
  });

  // Get new diagnostics data when date range changes
  useEffect(() => {
    if (shopviewData.initialized) {
      getDiagnostics();
    }
  }, [dates, shopviewData.initialized]);

  return (
    <div className='tab-wrapper'>
      {/* Card for OverDue etc. in Shopview */}
      <div className='row my-2 my-sm-3'>
        <div className='col-6'>
          <Card color='danger' value={cardValues.codes} text='Fault Codes' icon='fa-exclamation-circle' />
        </div>
        <div className='col-6'>
          <Card color='warning' value={cardValues.affected} text='Affected Vehicles' icon='fas fa-tractor' />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='row col-md-5 col-xl-3 mx-0'>
          <DateRangePicker
            onApply={dateSelection}
            initialSettings={{
              startDate: dates.start.toFormat('MM/dd/yyyy'),
              endDate: dates.start.toFormat('MM/dd/yyyy'),
              maxDate: DateTime.local(),
              autoApply: true,
            }}
          >
            <button className='btn border-dark btn-light col-12'>
              <FontAwesomeIcon icon='fas fa-calendar-alt' />
              {` ${dates.start.toLocaleString(DateTime.DATE_MED)} - ${dates.end.toLocaleString(DateTime.DATE_MED)} `}
              <FontAwesomeIcon icon='fas fa-caret-down' />
            </button>
          </DateRangePicker>
        </div>
      </div>
      <DiagnosticsTable tableData={tableData} filterOptions={filterOptions} loading={loading} />
    </div>
  );

  function dateSelection(event, picker) {
    const startDate = DateTime.fromISO(picker.startDate.toISOString());
    const endDate = DateTime.fromISO(picker.endDate.toISOString()).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    setDates({
      start: startDate,
      end: endDate,
    });
  }

  async function getDiagnostics() {
    // Fetch data based on data range picker and update component states
    setLoading(true);

    // Get diagnostics data
    const getDiagnosticsResponse = await fetch(
      '/shopview/getDiagnostics?' +
        new URLSearchParams({startDate: dates.start, endDate: dates.end}, {cache: 'no-store'})
    );
    const getDiagnosticsResult = await getDiagnosticsResponse.json();

    // Filter duplicate entries
    const faultsFiltered = getDiagnosticsResult.faultList.filter((item, index, self) => {
      return (
        index ==
        self.findIndex((findItem) => {
          return findItem.vehicle == item.vehicle && findItem.faultCode == item.faultCode;
        })
      );
    });

    const rowList = [];
    const affectedVehicleList = [];
    const filterOptionsVehicle = [];
    const filterOptionsFault = [];

    for (let i = 0; i < faultsFiltered.length; i++) {
      const fault = faultsFiltered[i];
      const vehicle = vehiclesLookUp[fault.vehicleSN];
      if (!vehicle) {
        continue;
      }
      const vehicleShopActive = !(vehicle?.shopActive === false);
      // Skip Vehicle if vehicle not set to active for shopview
      if (!vehicleShopActive) {
        continue;
      }

      if (!affectedVehicleList.includes(fault.vehicle)) {
        affectedVehicleList.push(fault.vehicle);
      }

      // Update filter options and table Data
      rowList.push(fault);
      filterOptionsVehicle.push(fault.vehicle);
      filterOptionsFault.push(fault.faultCode);
    }

    // Add filter options to dropdowns
    filterOptionsVehicle.sort((a, b) => {
      return sortVehicleNamesHelper(a, b);
    });
    filterOptionsFault.sort();
    setFilterOptions((values) => {
      return {...values, vehicles: [...new Set(filterOptionsVehicle)], faults: [...new Set(filterOptionsFault)]};
    });

    // Sort rows based on vehicle name
    rowList.sort((a, b) => {
      return sortVehicleNamesHelper(a.vehicle, b.vehicle);
    });

    setTableData(rowList);
    setLoading(false);
    setCardValues({
      codes: rowList.length,
      affected: affectedVehicleList.length,
    });
  }
}

export {DiagnosticsTab};
