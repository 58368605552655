import {createSlice} from '@reduxjs/toolkit';
import {machineTypeMapping} from '../../app/utils';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: true,
    scorecards: [],
    scorecardsIdDict: {},
    kpiLandingPageOn: true,
    selectedKPI: 'Farming',
    subsetOptions: [],
    selectedSubset: '',
    selectedZoneLevel: 'Region',
    servicesData: null,
    efficiencyAxisDisplay: {
      'Speed': false,
      'Ac/Hr': true,
      'Hr/Ac': false,
    },
    operationsData: null,
    taskConfigs: {},
    inspectionsData: null,
    daysDiff: 1,
    inOutTime: null,
    inspectionsDict: null,
    selectedMachineType: 'All',
    selectedInnerSubset: 'Total',
    selectedFavoriteId: 'placeholder',
    vehicleSNDict: {},
    installTypes: {},
    selectedScorecardPageIndex: null,
    selectedScorecardId: null,
    scorecardsIdDict: {},
    editScorecardsIdDict: {},
    reportFilters: {
      vehicles: {
        usageVehicleSNs: [],
        usageVehicleType: [],
        usageVehicleCount: '25',
        usageVehicleSort: 'Desc',
        // Sort Options: 'Desc', 'Asc', 'Alphabetical', 'DistDesc', 'DistAsc'
        efficiencyVehicleSNs: [],
        efficiencyVehicleType: [],
        efficiencyVehicleCount: '25',
        efficiencyVehicleSort: 'Desc',
      },
      efficiency: {
        efficiencyZoneSort: 'Alphabetical',
        efficiencyZoneCount: '10',
        efficiencyZones: [],
      },
      tasks: {
        fields: [],
        fieldsCount: '10',
        fieldsSort: 'Desc',
        regions: [],
        regionsCount: '10',
        regionsSort: 'Desc',
        missingTaskVehicleSNs: [],
        missingTaskVehicleType: [],
        missingTaskVehicleCount: '25',
        missingTaskVehicleSort: 'Desc',
      },
    },
    filterOptions: {
      vehicles: {
        vehicleSNs: [],
        vehicleType: Object.keys(machineTypeMapping).map((machineType) => {
          return {value: machineType, text: machineTypeMapping[machineType]};
        }),
        vehicleCount: [
          {value: 0, text: 'Show All Vehicles'},
          {value: 10, text: 'Show 10 Vehicles'},
          {value: 25, text: 'Show 25 Vehicles'},
          {value: 50, text: 'Show 50 Vehicles'},
        ],
        usageVehicleSort: [
          {value: 'Desc', text: 'Sort: Hours Descending'},
          {value: 'Asc', text: 'Sort: Hours Ascending'},
          {value: 'DistDesc', text: 'Sort: Distance Descending'},
          {value: 'DistAsc', text: 'Sort: Distance Ascending'},
          {value: 'Alphabetical', text: 'Sort: Alphabetical'},
        ],
        efficiencyVehicleSort: [
          {value: 'Desc', text: 'Sort: Descending'},
          {value: 'Asc', text: 'Sort: Ascending'},
          {value: 'Alphabetical', text: 'Sort: Alphabetical'},
        ],
        serviceHealthVehicleSort: [
          {value: 'Desc', text: 'Sort: Overdue Descending'},
          {value: 'Asc', text: 'Sort: Overdue Ascending'},
          {value: 'Alphabetical', text: 'Sort: Alphabetical'},
        ],
      },
      efficiency: {
        efficiencyZoneSort: [
          {value: 'Desc', text: 'Sort: Descending'},
          {value: 'Asc', text: 'Sort: Ascending'},
          {value: 'Alphabetical', text: 'Sort: Alphabetical'},
        ],
        efficiencyZoneCount: [
          {value: 0, text: 'Show All Zones'},
          {value: 10, text: 'Show 10 Zones'},
          {value: 25, text: 'Show 25 Zones'},
          {value: 50, text: 'Show 50 Zones'},
        ],
        efficiencyRowSpacing: [],
      },
      tasks: {
        missingTaskVehicleNames: [],
        fields: [],
        regions: [],
        fieldsCount: [
          {value: 0, text: 'Show All Fields'},
          {value: 10, text: 'Show 10 Fields'},
          {value: 25, text: 'Show 25 Fields'},
          {value: 50, text: 'Show 50 Fields'},
        ],
        regionsCount: [
          {value: 0, text: 'Show All Regions'},
          {value: 10, text: 'Show 10 Regions'},
          {value: 25, text: 'Show 25 Regions'},
          {value: 50, text: 'Show 50 Regions'},
        ],
        fieldsSort: [
          {value: 'Desc', text: 'Sort: Hours Descending'},
          {value: 'Asc', text: 'Sort: Hours Ascending'},
          {value: 'Alphabetical', text: 'Sort: Alphabetical'},
        ],
        regionsSort: [
          {value: 'Desc', text: 'Sort: Hours Descending'},
          {value: 'Asc', text: 'Sort: Hours Ascending'},
          {value: 'Alphabetical', text: 'Sort: Alphabetical'},
        ],
      },
      // vehicle Names filter options in both vehicle graph and inspection are the same.
    },
    dates: {start: null, end: null},
    activeDevices: {
      'clearpath': {},
      'geotab': {},
    },
  },
  reducers: {
    setScorecards(state, action) {
      state.scorecards = action.payload;
      if (action.payload.length > 0) {
        const newScorecardsIdDict = {};
        for (let i = 0; i < action.payload.length; i++) {
          const tempScorecardId = action.payload[i]['data'].id;
          newScorecardsIdDict[tempScorecardId] = action.payload[i]['data'];
          newScorecardsIdDict[tempScorecardId].pages.forEach((page, index) => {
            page.originalPageIndex = index;
          });
        }
        state.scorecardsIdDict = newScorecardsIdDict;
        state.editScorecardsIdDict = newScorecardsIdDict;
      }
    },
    setEditScorecardsIdDict(state, action) {
      state.editScorecardsIdDict = action.payload;
    },
    setSelectedScorecardId(state, action) {
      state.selectedScorecardId = action.payload;
    },
    setEfficiencyAxisDisplay(state, action) {
      state.efficiencyAxisDisplay = action.payload;
    },
    updateVehicleFilters(state, action) {
      const key = action.payload.key;
      const value = action.payload.value;
      state.reportFilters.vehicles[key] = value;
    },
    updateEfficiencyFilters(state, action) {
      const key = action.payload.key;
      const value = action.payload.value;
      state.reportFilters.efficiency[key] = value;
    },
    updateTasksFilters(state, action) {
      const key = action.payload.key;
      const value = action.payload.value;
      state.reportFilters.tasks[key] = value;
    },
    setReportFilters(state, action) {
      state.reportFilters = action.payload;
    },
    setInOutTime(state, action) {
      state.inOutTime = action.payload;
    },
    setVehicles(state, action) {
      state.vehicleSNDict = action.payload;
    },
    setSelectedKPI(state, action) {
      state.selectedKPI = action.payload;
    },
    setSubsetOptions(state, action) {
      state.subsetOptions = action.payload;
    },
    setSelectedSubset(state, action) {
      state.selectedSubset = action.payload;
    },
    setSelectedInnerSubset(state, action) {
      state.selectedInnerSubset = action.payload;
    },
    setSelectedZoneLevel(state, action) {
      state.selectedZoneLevel = action.payload;
    },
    setSelectedMachineType(state, action) {
      state.selectedMachineType = action.payload;
    },
    setServicesData(state, action) {
      state.servicesData = action.payload;
    },
    setOperationsData(state, action) {
      const operationsData = action.payload;

      Object.keys(operationsData.vehiclesObject).forEach((vehicleSN) => {
        operationsData.vehiclesObject[vehicleSN].totalDuration =
          operationsData.vehiclesObject[vehicleSN].duration + operationsData.vehiclesObject[vehicleSN].idleTimeInHours;
        operationsData.vehiclesObject[vehicleSN].drivingDuration = operationsData.vehiclesObject[vehicleSN].duration;
        delete operationsData.vehiclesObject[vehicleSN].duration;
      });

      state.operationsData = operationsData;
    },
    setTaskConfigs(state, action) {
      state.taskConfigs = action.payload;
    },
    setSelectedScorecardPageIndex(state, action) {
      state.selectedScorecardPageIndex = action.payload;
    },
    setInspectionsData(state, action) {
      state.inspectionsData = action.payload;
    },
    setFilterOptions(state, action) {
      state.filterOptions = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setInstallTypes(state, action) {
      state.installTypes = action.payload;
    },
    setDaysDiff(state, action) {
      state.daysDiff = action.payload;
    },
    setDatesCache(state, action) {
      state.dates = action.payload;
    },
    setInspectionsDict(state, action) {
      state.inspectionsDict = action.payload;
    },
    setSelectedFavoriteId(state, action) {
      state.selectedFavoriteId = action.payload;
    },
    setkpiLandingPageOn(state, action) {
      state.kpiLandingPageOn = action.payload;
    },
    updateActiveDevices(state, action) {
      const devicesActive = JSON.parse(JSON.stringify(action.payload));
      state.activeDevices = devicesActive;
    },
  },
});

export const {
  setSelectedKPI,
  setSubsetOptions,
  setSelectedSubset,
  setSelectedZoneLevel,
  setServicesData,
  setOperationsData,
  setTaskConfigs,
  setInspectionsData,
  setFilterOptions,
  setFilters,
  resetFilters,
  setLoading,
  setInstallTypes,
  setDatesCache,
  setDaysDiff,
  setInOutTime,
  setSelectedMachineType,
  setVehicles,
  setSelectedInnerSubset,
  setInspectionsDict,
  setEfficiencyAxisDisplay,
  updateVehicleFilters,
  updateEfficiencyFilters,
  updateTasksyFilters,
  setReportFilters,
  setSelectedFavoriteId,
  updateTasksFilters,
  setkpiLandingPageOn,
  updateActiveDevices,
  setScorecards,
  setSelectedScorecardId,
  setSelectedScorecardPageIndex,
  setEditScorecardsIdDict,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
