import {createSlice} from '@reduxjs/toolkit';

const historyModalSlice = createSlice({
  name: 'historyModal',
  initialState: {
    vehicle: '',
    vehicleSN: '',
    task: '',
    engineHours: '',
    odometer: '',
    date: '',
    workOrder: '',
    invoice: '',
    laborCost: '',
    partsCost: '',
    laborTime: '',
    supplier: '',
    reviewedBy: '',
    notes: '',
    completedString: '',
    user: '',
    serviceEntry: {},
  },
  reducers: {
    setInitialValues(state, action) {
      const entryData = action.payload;
      state.vehicle = entryData.vehicle;
      state.vehicleSN = entryData.vehicleSN;
      state.task = entryData.task;
      state.engineHours = entryData.engineHours;
      state.odometer = entryData.odometer;
      state.date = entryData.dateCompleted;
      state.workOrder = entryData.workOrder;
      state.invoice = entryData.invoice;
      state.laborCost = entryData.laborCost;
      state.partsCost = entryData.partsCost;
      state.laborTime = entryData.laborTime;
      state.supplier = entryData.supplier;
      state.reviewedBy = entryData.reviewedBy;
      state.notes = entryData.notes;
      state.completedString = entryData.completedString;
      state.user = entryData.user;
      state.serviceEntry = entryData.serviceEntry;
    },
  },
});

export const {setInitialValues} = historyModalSlice.actions;
export default historyModalSlice.reducer;
