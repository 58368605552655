import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BulkEditInputs} from '../BulkEditInputs';
import {getCustomerAndUserInfo} from '../../../appSlice';
import {
  getSettingsData,
  updateBulkEditInspectionItems,
  initializeBulkEditInspectionItems,
  setInspectionItemsBulkEditMode,
  setInspectionItemsBulkEditErrors,
  updateTableLoading,
  updateInspectionItemsData,
} from '../settingsSlice';

import {
  searchFind,
  machineTypeMapping,
  modalBoxStyle,
  squareButton,
  fetchPostAuthSafe,
  hasDuplicates,
  deepCopy,
} from '../../../app/utils';
import {Tailselect} from '../../../components/Tailselect';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../../components/CustomTablePagination';
import {BulkUploadModal} from '../../../components/BulkUploadModal';
import {ModalSubmitting, SettingsText, SettingsSelect, ViewOnlyText} from '../../../components/GeneralComponents';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Modal,
  Box,
  Button,
  Chip,
  CircularProgress,
} from '@mui/material';

function InspectionTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.tableLoading.inspectionItems;
  });
  const inspectionItems = useSelector((state) => {
    return state.settings.inspectionItems;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const inspectionItemsBulkEditMode = useSelector((state) => {
    return state.settings.inspectionItemsBulkEditMode;
  });
  const inspectionItemsBulkEditErrors = useSelector((state) => {
    return state.settings.inspectionItemsBulkEditErrors;
  });

  const [submitting, setSubmitting] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [inspectionItemsNamesList, setInspectionItemsNamesList] = useState([]);
  const [propagateAllColumns, setPropagateAllColumns] = useState({});

  const [modalFormValues, setModalFormValues] = useState({
    id: '',
    name: '',
    vehicleType: [],
  });

  const [filterOptions, setFilterOptions] = useState({
    inspectionIdFilter: [],
    inspectionItemMachineType: Object.keys(machineTypeMapping).map((key) => {
      return machineTypeMapping[key];
    }),
  });

  const [filters, setFilters] = useState({
    inspectionIdFilter: [],
    inspectionItemMachineType: [],
  });

  function resetBulkEdits() {
    const tempTableData = [];
    inspectionItems.forEach((inspectionItem) => {
      if (inspectionItem?.archived != true) {
        tempTableData.push(inspectionItem);
      }
    });
    tempTableData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    const inspectionFilterOptions = tempTableData.map((inspectionItem) => {
      const tempInspectionOption = {
        value: inspectionItem.id,
        text: inspectionItem.name,
      };
      return tempInspectionOption;
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(tempTableData);

    const inspectionItemsDict = {};

    tempTableData.forEach((inspectionItem) => {
      inspectionItemsDict[inspectionItem.id] = inspectionItem;
    });

    dispatch(initializeBulkEditInspectionItems(inspectionItemsDict));
  }

  useEffect(async () => {
    const tempTableData = [];
    inspectionItems.forEach((inspectionItem) => {
      if (inspectionItem?.archived != true) {
        tempTableData.push(inspectionItem);
      }
    });
    tempTableData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    const inspectionFilterOptions = tempTableData.map((inspectionItem) => {
      const tempInspectionOption = {
        value: inspectionItem.id,
        text: inspectionItem.name,
      };
      return tempInspectionOption;
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(tempTableData);

    const inspectionItemsDict = {};

    tempTableData.forEach((inspectionItem) => {
      inspectionItemsDict[inspectionItem.id] = inspectionItem;
    });

    dispatch(initializeBulkEditInspectionItems(inspectionItemsDict));

    const tempInspectionItemsNamesList = inspectionItems.map((inspectionItem) => {
      return inspectionItem.name;
    });

    setInspectionItemsNamesList(tempInspectionItemsNamesList);
    setFilterOptions({
      ...filterOptions,
      inspectionIdFilter: inspectionFilterOptions,
    });
  }, [inspectionItems]);

  useEffect(async () => {
    const newItem = !modalItem;
    const itemData = inspectionItems.find((item) => {
      return item.id == modalItem;
    });

    setModalFormValues({
      id: newItem ? '' : itemData.id,
      name: newItem ? '' : itemData.name,
      vehicleType: newItem ? [] : itemData.vehicleType,
    });
  }, [modalItem]);

  const handleModalChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setModalFormValues((values) => {
      return {...values, [name]: value};
    });
  };

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  async function handleModalSubmit() {
    setSubmitting(true);

    // Create doc for asset label
    const updateDoc = {
      id: modalFormValues.id,
      name: modalFormValues.name,
      vehicleType: modalFormValues.vehicleType,
    };

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(updateDoc),
    };

    const response = await fetchPostAuthSafe(
      '/settings/updateInspectionItem',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    } else if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    refreshData();
    setModalOpen(false);
    setSubmitting(false);
    setModalItem('');
  }

  async function handleDelete() {
    setSubmitting(true);

    // Create doc for asset label
    const updateDoc = {
      id: modalFormValues.id,
      archived: true,
    };

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(updateDoc),
    };

    const response = await fetchPostAuthSafe(
      '/settings/updateInspectionItem',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    } else if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    dispatch(getCustomerAndUserInfo());
    dispatch(getSettingsData());
    setModalOpen(false);
    setSubmitting(false);
    setModalItem('');
  }

  function refreshData() {
    dispatch(getCustomerAndUserInfo());
    dispatch(getSettingsData());
  }

  function handleBulkUploadModalOpen(openState) {
    setBulkUploadModalOpen(openState);
  }

  const inspectionItemsCsvColumns = [
    {
      key: 'inspectionName',
      required: true,
      description: 'Name of the Inspection Item',
    },
    {
      key: 'vehicleTypes',
      required: false,
      description: `Vehicle Type(s) for the Inspection, Accepted Values: 
        ${Object.keys(machineTypeMapping)
          .map((key) => {
            return `'${machineTypeMapping[key]}'`;
          })
          .join(' ,')}, separated by ',' for multiple types.`,
    },
  ];

  function bulkUploadDataPreview(rowData, index, totalCount) {
    return (
      <div className='card mb-2 text-left' key={index}>
        <div className='card-header row mx-0 px-0'>
          <div className='col-8'>
            Name: {rowData.name}{' '}
            {rowData.errors && rowData.errors.length > 0 ? (
              <FontAwesomeIcon className='text-danger' icon='fas fa-warning' />
            ) : rowData.warnings && rowData.warnings.length > 0 ? (
              <FontAwesomeIcon className='text-warning' icon='fas fa-warning' />
            ) : (
              <FontAwesomeIcon className='text-success' icon='fas fa-check-circle' />
            )}
          </div>
          <div className='col-4 text-right'>
            {index + 1}/{totalCount}
          </div>
        </div>
        <div className='row mx-0 px-0'>
          <div className='col-md-6'>
            {'Vehicle Types: '}
            {rowData.vehicleType
              .map((machineType) => {
                return machineTypeMapping[machineType];
              })
              .join(', ')}
          </div>
        </div>
        {((rowData.errors && rowData.errors.length > 0) || (rowData.warnings && rowData.warnings.length > 0)) && (
          <div className='card-footer'>
            {rowData.errors.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
            {rowData.warnings.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
          </div>
        )}
      </div>
    );
  }

  function validateAndMapBulkUploadedData(data, dupCheckObject) {
    const requiredColumns = inspectionItemsCsvColumns
      .filter((column) => {
        return column.required;
      })
      .map((column) => {
        return column.key;
      });

    const noDupColumns = ['inspectionName'];

    const defaultInspectionItem = {
      'name': '',
      'vehicleType': [],
      'archived': false,
    };

    const tempDoc = {
      ...defaultInspectionItem,
      ...(Object.hasOwnProperty.call(data, 'inspectionName') ? {name: data['inspectionName']} : {}),
    };

    const errors = [];
    const warnings = [];
    noDupColumns.forEach((col) => {
      if (!Object.keys(dupCheckObject).includes(col)) {
        dupCheckObject[col] = [];
        dupCheckObject[col].push(data[col]);
      } else if (dupCheckObject[col].includes(data[col])) {
        errors.push(`Duplicate ${col}`);
      } else {
        dupCheckObject[col].push(data[col]);
      }
    });

    if (Object.hasOwnProperty.call(data, 'vehicleTypes')) {
      const machineTypeList = Object.keys(machineTypeMapping);
      const vehicleTypeInputList = data['vehicleTypes'].split(',');
      const vehicleTypeParsedList = [];

      for (let i = 0; i < vehicleTypeInputList.length; i++) {
        const enteredVehicleType = vehicleTypeInputList[i].trim();

        if (enteredVehicleType === '') {
          continue;
        }

        let machineTypeFound = false;
        for (let j = 0; j < machineTypeList.length; j++) {
          const machineTypeName = machineTypeMapping[machineTypeList[j]];
          if (enteredVehicleType.toLowerCase() == machineTypeName.toLowerCase()) {
            if (!vehicleTypeParsedList.includes(machineTypeList[j])) {
              vehicleTypeParsedList.push(machineTypeList[j]);
            }
            machineTypeFound = true;
            break;
          }
        }
        if (!machineTypeFound) {
          warnings.push(`Vehicle Type cannot be found, Entered value: ${enteredVehicleType}`);
        }
      }
      tempDoc.vehicleType = vehicleTypeParsedList;
    }

    if (
      Object.hasOwnProperty.call(data, 'inspectionName') &&
      inspectionItemsNamesList.includes(data['inspectionName'])
    ) {
      errors.push(`Inspection Item with the same name already Exist, Entered value: ${data['inspectionName']}`);
    }

    for (let i = 0; i < requiredColumns.length; i++) {
      if (!data[requiredColumns[i]] || data[requiredColumns[i]] == '') {
        errors.push(`Column ${requiredColumns[i]} from CSV cannot be empty`);
      }
    }

    tempDoc.errors = errors;
    tempDoc.warnings = warnings;

    return tempDoc;
  }

  async function postBulkInspectionItems(newInspectionItemsDocs) {
    const postData = {docsList: newInspectionItemsDocs, uploadType: 'inspectionItems'};
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(postData),
    };
    const url = '/settings/bulkUpload';
    const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    }

    return result;
  }

  function handleBulkEditTextFields(entityIdName, entityId, propertyName, propertyValue, newVal, deletedVal) {
    const inspectionItemsBulkEditErrorsCopy = deepCopy(inspectionItemsBulkEditErrors);
    let updateRowsId = [entityId];
    const filteredTableData = tableData.filter((item) => {
      return filterRow(item);
    });
    if (propagateAllColumns.hasOwnProperty(propertyName) && propagateAllColumns[propertyName]) {
      updateRowsId = filteredTableData.map((insp) => {
        return insp.id;
      });
    }
    if (propertyName == 'vehicleType' && newVal != null) {
      propertyValue = newVal;
    }

    updateRowsId.forEach((inspId) => {
      const inspectionItemHasError =
        inspectionItemsBulkEditErrors.hasOwnProperty(inspId) &&
        inspectionItemsBulkEditErrors[inspId].hasOwnProperty(propertyName);
      if (inspectionItemHasError) {
        delete inspectionItemsBulkEditErrorsCopy[inspId][propertyName];
        if (Object.keys(inspectionItemsBulkEditErrorsCopy[inspId]).length == 0) {
          delete inspectionItemsBulkEditErrorsCopy[inspId];
        }
      }

      if (propertyName == 'vehicleType' && deletedVal != null) {
        dispatch(
          updateBulkEditInspectionItems({
            [entityIdName]: inspId,
            data: {
              [propertyName]: propertyValue,
              deletedVal: deletedVal,
            },
          })
        );
      } else {
        dispatch(
          updateBulkEditInspectionItems({
            [entityIdName]: inspId,
            data: {
              [propertyName]: propertyValue,
            },
          })
        );
      }
    });
  }

  function handleToggleBulkEditPropagateColumn(propertyName) {
    setPropagateAllColumns((value) => {
      return {
        ...value,
        [propertyName]: value.hasOwnProperty(propertyName) ? !value[propertyName] : true,
      };
    });
  }

  function generateModal() {
    const viewOnly = !userSettings.roleAccess['inspectionManagement'];
    return (
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setModalItem('');
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='overflow-auto'
      >
        <Box sx={modalBoxStyle}>
          {submitting ? (
            <ModalSubmitting />
          ) : (
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
              <h3>
                <strong>Inspection Item Settings</strong>
              </h3>
              {viewOnly && <ViewOnlyText />}
              <Table aria-label='simple table'>
                <TableBody>
                  <SettingsText
                    label='Name'
                    setting='name'
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                  />
                  <SettingsSelect
                    label='Vehicle Types'
                    setting='vehicleType'
                    multiple
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                    options={Object.keys(machineTypeMapping).map((key) => {
                      return {value: parseInt(key), label: machineTypeMapping[key]};
                    })}
                  />
                </TableBody>
              </Table>

              <div className='flex-row my-3 col-12 px-1'>
                {modalFormValues.id != '' && !viewOnly && (
                  <Button variant='ic-button' color='danger' className='mr-5' onClick={handleDelete}>
                    Delete
                  </Button>
                )}
                <Button
                  variant='ic-button'
                  color='secondary'
                  className='mx-1'
                  onClick={() => {
                    setModalOpen(false);
                    setModalItem('');
                  }}
                >
                  Cancel
                </Button>
                {!viewOnly && (
                  <Button variant='ic-button' color='primary' className='mx-1' onClick={handleModalSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  function generateMenu() {
    return (
      <React.Fragment>
        <h5 className='mt-2' style={{color: 'black'}}>
          Inspection Items Management
        </h5>
        <div>
          {userSettings.roleAccess['inspectionManagement'] && (
            <div className='row mx-0 px-0'>
              <Button
                sx={squareButton}
                variant='ic-button'
                color='success'
                title='Add inspection item'
                onClick={() => {
                  setModalOpen(true);
                  setModalItem('');
                }}
              >
                <FontAwesomeIcon icon='fas fa-plus' />
              </Button>
              <Button
                sx={squareButton}
                variant='ic-button'
                color='success'
                title='Bulk Upload'
                className='ml-1'
                onClick={() => {
                  handleBulkUploadModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon='fas fa-upload' />
              </Button>
              <Button
                sx={{...squareButton, marginLeft: '3px'}}
                variant='ic-button'
                color={inspectionItemsBulkEditMode ? 'danger' : 'secondary'}
                onClick={() => {
                  dispatch(setInspectionItemsBulkEditMode(!inspectionItemsBulkEditMode));
                }}
                title='Bulk Edit Mode'
              >
                {inspectionItemsBulkEditMode ? (
                  <FontAwesomeIcon icon='fas fa-xmark-circle' />
                ) : (
                  <FontAwesomeIcon icon='fas fa-edit' />
                )}
              </Button>
              {inspectionItemsBulkEditMode && (
                <React.Fragment>
                  <BulkEditSubmitButton />
                  <Button
                    sx={squareButton}
                    variant='ic-button'
                    color='secondary'
                    title='Bulk Edit Reset'
                    className='ml-1'
                    onClick={() => {
                      resetBulkEdits();
                      dispatch(setInspectionItemsBulkEditErrors({}));
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-trash-can' />
                  </Button>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  function generateTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <TableHeadFilterButtonTitleWrapper
              title={'Name'}
              bulkEditMode={inspectionItemsBulkEditMode}
              propagateAllColumns={propagateAllColumns}
              handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
              bulkEditPropertyName={'name'}
            >
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#inspection-item-id-select').toggle();
                }}
              >
                <FontAwesomeIcon
                  icon='fas fa-filter'
                  style={{color: filters['inspectionIdFilter'].length > 0 && '#4e73df'}}
                />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='inspection-item-id-select'
                name={'inspectionIdFilter'}
                multiple={true}
                search={true}
                value={filters['inspectionIdFilter']}
                options={filterOptions['inspectionIdFilter']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell>
            <TableHeadFilterButtonTitleWrapper
              title={'Vehicle Types'}
              bulkEditMode={inspectionItemsBulkEditMode}
              propagateAllColumns={propagateAllColumns}
              handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
              bulkEditPropertyName={'vehicleType'}
            >
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#inspection-item-machine-type-select').toggle();
                }}
              >
                <FontAwesomeIcon
                  icon='fas fa-filter'
                  style={{color: filters['inspectionItemMachineType'].length > 0 && '#4e73df'}}
                />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='inspection-item-machine-type-select'
                name={'inspectionItemMachineType'}
                multiple={true}
                search={true}
                value={filters['inspectionItemMachineType']}
                options={filterOptions['inspectionItemMachineType']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          {!inspectionItemsBulkEditMode && <TableCell></TableCell>}
        </TableRow>
      </TableHead>
    );
  }

  function renderVehicleTypeOptions(type) {
    const options = Object.keys(machineTypeMapping).map((typeValue) => {
      const label = machineTypeMapping[typeValue];
      return {
        label: label,
        value: parseInt(typeValue),
      };
    });
    return options;
  }

  function generateTableBody() {
    let colorFlip = false;
    return (
      <React.Fragment>
        {loading ? (
          <TableRow>
            <TableCell colSpan={100}>
              <CircularProgress className='mt-2 mx-auto d-block' size={80} />
            </TableCell>
          </TableRow>
        ) : (
          <React.Fragment>
            {tableData.map((item, idx) => {
              const rowBulkEditErrors = inspectionItemsBulkEditErrors.hasOwnProperty(item.id)
                ? inspectionItemsBulkEditErrors[item.id]
                : {};
              colorFlip = !colorFlip;
              if (!filterRow(item)) {
                return null;
              }
              return (
                <TableRow
                  style={{backgroundColor: colorFlip ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0)'}}
                  key={item.id}
                >
                  {!inspectionItemsBulkEditMode ? (
                    <TableCell>{item.name}</TableCell>
                  ) : (
                    <TableCell>
                      <BulkEditInputs
                        type='text'
                        handleBulkEditUpdate={handleBulkEditTextFields}
                        bulkEditStateName='bulkEditInspectionItems'
                        entityId={item.id}
                        entityIdName='id'
                        defaultValue={item.name}
                        propertyName='name'
                        submissionErrors={rowBulkEditErrors['name']}
                      />
                    </TableCell>
                  )}
                  {!inspectionItemsBulkEditMode ? (
                    <TableCell>
                      {item.vehicleType.map((type) => {
                        return (
                          <Chip
                            key={type}
                            size='small'
                            variant='outlined'
                            className='mr-1'
                            label={machineTypeMapping[type]}
                            color='info'
                          />
                        );
                      })}
                    </TableCell>
                  ) : (
                    <TableCell>
                      <BulkEditInputs
                        type='multiSelect'
                        handleBulkEditUpdate={handleBulkEditTextFields}
                        bulkEditStateName='bulkEditInspectionItems'
                        entityId={item.id}
                        entityIdName='id'
                        multiple
                        renderOptions={renderVehicleTypeOptions}
                        defaultValue={item.vehicleType}
                        propertyName='vehicleType'
                        submissionErrors={rowBulkEditErrors['vehicleType']}
                      />
                    </TableCell>
                  )}
                  {!inspectionItemsBulkEditMode && (
                    <TableCell>
                      <button
                        className='btn btn-light'
                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                        onClick={() => {
                          setModalOpen(true);
                          setModalItem(item.id);
                        }}
                      >
                        <FontAwesomeIcon icon='fa fa-edit' />
                      </button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  function generateTable() {
    return (
      <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
        {generateTableHead()}
        <TableBody>{generateTableBody()}</TableBody>
      </Table>
    );
  }

  function filterRow(inspectionItem) {
    const inspectionVehicleTypeArray = inspectionItem.vehicleType.map((type) => {
      return machineTypeMapping[type];
    });
    // Convert Properties to string for search
    const rowObj = {
      ...inspectionItem,
      inspectionItemMachineType: inspectionVehicleTypeArray,
    };
    // remove 'archived' properties from rowObj so searching 'archived' does not show both status
    delete rowObj.archived;
    delete rowObj.vehicleType;

    const search = searchFind(<div>{JSON.stringify(rowObj)}</div>, searchText.toLowerCase().trim());
    const inspectionIdInFilter =
      filters.inspectionIdFilter.includes(inspectionItem.id) || filters.inspectionIdFilter.length == 0;
    // Use Array.some to find if any of inspectionVehicleTypeArray is in filter
    const inspectionMachineTypeInFilter =
      inspectionVehicleTypeArray.some((type) => {
        return filters.inspectionItemMachineType.includes(type);
      }) || filters.inspectionItemMachineType.length == 0;
    const inspectionInFilter = inspectionIdInFilter && inspectionMachineTypeInFilter;

    return inspectionInFilter && search;
  }

  return (
    <React.Fragment>
      {generateModal()}
      <TabMenuTableWrapper
        menu={generateMenu()}
        table={generateTable()}
        pagination={
          <CustomTablePagination
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      />
      <BulkUploadModal
        entityName={'Inspection Items'}
        modalOpen={bulkUploadModalOpen}
        handleModalOpen={handleBulkUploadModalOpen}
        dataValidationAndMapping={validateAndMapBulkUploadedData}
        dataPreview={bulkUploadDataPreview}
        acceptedColumnHeaders={inspectionItemsCsvColumns}
        bulkUploadSubmit={postBulkInspectionItems}
        refreshData={refreshData}
      />
    </React.Fragment>
  );
}

function BulkEditSubmitButton(props) {
  const dispatch = useDispatch();
  const bulkEditInspectionItems = useSelector((state) => {
    return state.settings.bulkEditInspectionItems;
  });
  const inspectionItemsDict = useSelector((state) => {
    return state.settings.inspectionItemsDict;
  });
  const inspectionItemsBulkEditErrors = useSelector((state) => {
    return state.settings.inspectionItemsBulkEditErrors;
  });
  const inspectionItemsBulkEditMode = useSelector((state) => {
    return state.settings.inspectionItemsBulkEditMode;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  async function submitBulkEdits() {
    const validationErrors = {};
    const editSubmissionList = [];
    const inspectionItemIdList = Object.keys(inspectionItemsDict);
    Object.keys(bulkEditInspectionItems).forEach((id) => {
      const newInspectionItem = bulkEditInspectionItems[id];
      const oldInspectionItem = inspectionItemsDict[id];
      const inspectionItemUpdateObj = {};

      if (newInspectionItem.name != oldInspectionItem.name) {
        inspectionItemUpdateObj.name = newInspectionItem.name;
      }

      let arrDiff = false;
      oldInspectionItem.vehicleType.forEach((type) => {
        if (!newInspectionItem.vehicleType.includes(type)) {
          arrDiff = true;
        }
      });
      newInspectionItem.vehicleType.forEach((type) => {
        if (!oldInspectionItem.vehicleType.includes(type)) {
          arrDiff = true;
        }
      });

      if (arrDiff) {
        inspectionItemUpdateObj.vehicleType = newInspectionItem.vehicleType;
      }

      if (Object.keys(inspectionItemUpdateObj).length > 0) {
        inspectionItemUpdateObj.id = id;
        editSubmissionList.push(inspectionItemUpdateObj);
      }
    });
    dispatch(setInspectionItemsBulkEditErrors(validationErrors));

    if (Object.keys(validationErrors).length == 0 && editSubmissionList.length != 0) {
      dispatch(setInspectionItemsBulkEditMode(false));
      dispatch(updateTableLoading({table: 'inspectionItems', status: true}));
      const editSubmissionListParsed = editSubmissionList.map((editObj) => {
        const diffDoc = deepCopy(editObj);
        return diffDoc;
      });
      const promises = editSubmissionListParsed.map(postBulkEditInspectionItems);
      const results = await Promise.all(promises);
      // REFRESH DATA
      const getInspectionItemsRequest = await fetch('/getInspectionItems', {cache: 'no-store'});
      const res = await getInspectionItemsRequest.json();
      dispatch(updateInspectionItemsData(res));
      dispatch(updateTableLoading({table: 'inspectionItems', status: false}));
    } else if (Object.keys(validationErrors).length == 0 && editSubmissionList.length == 0) {
      dispatch(setInspectionItemsBulkEditMode(false));
    } else {
      console.log(validationErrors);
    }
  }

  async function postBulkEditInspectionItems(obj) {
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(obj),
    };

    const response = await fetchPostAuthSafe(
      '/settings/updateInspectionItem',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    return result;
  }

  return (
    <Button
      sx={squareButton}
      variant='ic-button'
      color='primary'
      title='Bulk Edit Submit'
      className='ml-1'
      onClick={() => {
        submitBulkEdits();
      }}
    >
      <FontAwesomeIcon icon='fas fa-floppy-disk' />
    </Button>
  );
}

export {InspectionTab};
