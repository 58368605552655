import React, {useState} from 'react';
import {createPortal} from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {fetchPostAuthSafe} from '../../../app/utils';
import {ModalFramework, ModalHeader, ModalFooter, ModalBody} from '../../../components/Modal';
import {InspectionReportDetailTable} from './inspectionReportDetailTable';
import {setInspectionReportsModalValues} from './inspectionReportsModalSlice';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CircularProgress} from '@mui/material';
import {DateTime} from 'luxon';

function InspectionReportsModal(props) {
  const dispatch = useDispatch();

  const initialValues = useSelector((state) => {
    return state.inspectionReportsModal;
  });
  const vehiclesLookUp = useSelector((state) => {
    return state.shopview.vehiclesLookUp;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [loading, setLoading] = useState(false);
  const [urlOpened, setUrlOpened] = useState(false);

  openInspectionModalByUrlParam();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  async function openInspectionModalByUrlParam() {
    // Extract inspectionReportId from Url Param
    const query = useQuery();
    const inspectionId = query.get('inspectionReportId');

    if (inspectionId && !urlOpened && Object.keys(vehiclesLookUp).length) {
      setUrlOpened(true);

      // Fetch Inspection Report Data if there is no Initial Value Set
      const getInspectionReportResponse = await fetch(
        '/getInspectionReportById?' + new URLSearchParams({id: inspectionId}, {cache: 'no-store'})
      );
      const getInspectionReportResult = await getInspectionReportResponse.json();

      if (getInspectionReportResult.length > 0) {
        // Initialize Inspection Report Modal Data
        const reportDoc = getInspectionReportResult[0];
        const vehicle = vehiclesLookUp[reportDoc.data.selectedVeh];
        if (vehicle) {
          const rowData = formatRowData(vehicle, reportDoc);
          // dispatch inspection report data to redux and open modal
          dispatch(setInspectionReportsModalValues(rowData));
          $('#shopview-inspection-reports-modal').modal('show');
        }
      }
    }
  }

  async function toggleTicketState() {
    // Set loading
    setLoading(true);

    // Determine new closed state
    const newClosedBool = initialValues.status != 'closed';

    // Update backend
    const postData = {
      reportDocId: initialValues.reportDocId,
      closed: newClosedBool,
    };
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(postData),
    };
    // Post inspectionReport data
    const response = await fetchPostAuthSafe(
      '/updateInspectionReport',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    } else {
      const reportDoc = result.updatedDoc;
      const vehicle = vehiclesLookUp[reportDoc.data.selectedVeh];
      if (vehicle) {
        const rowData = formatRowData(vehicle, reportDoc);
        dispatch(setInspectionReportsModalValues(rowData));
      }
    }

    // Get inspection reports for table
    setLoading(false);
    props.getInspectionReports();
  }

  function formatRowData(vehicle, reportDoc) {
    const report = reportDoc.data;
    const reportDocId = reportDoc.ref['@ref'].id;

    let failCount = 0;
    for (let index = 0; index < report.items.length; index++) {
      if (report.items[index].passed === false) {
        failCount++;
      }
    }

    let reportStatus = 'passed';
    if (failCount > 0) {
      reportStatus = 'failed';
      if (report.closed) {
        reportStatus = 'closed';
      }
    }

    // Parse reports time to correct user time
    const inspectedDate = DateTime.fromISO(report.date_time['@ts']).setZone(customerSettings.general.timeZone);
    const inspectedDateString = inspectedDate.toFormat('yyyy-MM-dd hh:mm a');

    let closedDateString = '';
    if (report.closedDatetime) {
      const closedDate = DateTime.fromISO(report.closedDatetime['@ts']).setZone(customerSettings.general.timeZone);
      closedDateString = closedDate.toFormat('yyyy-MM-dd hh:mm a');
    }

    const rowData = {
      vehicle: vehicle.name,
      items: report.items,
      inspectedDate: inspectedDateString,
      closedDate: closedDateString,
      date_time: report.date_time,
      status: reportStatus,
      failCount: failCount,
      inspectedByUser: report.inspectedByUser,
      closedByUser: report.closedByUser,
      comment: report.comment,
      reportDocId: reportDocId,
      inspectionNameInput: report.inspectionNameInput,
    };

    return rowData;
  }

  function inspectionStatus(rowData) {
    if (rowData.status == 'closed') {
      return (
        <span className='text-primary'>
          <FontAwesomeIcon icon='fa-solid fa-circle-check fa-lg' />
        </span>
      );
    } else if (rowData.status == 'failed') {
      return (
        <span className='text-danger'>
          <FontAwesomeIcon icon='fa-solid fa-circle-xmark fa-lg' />
        </span>
      );
    } else {
      return (
        <span className='text-success'>
          <FontAwesomeIcon icon='fa-solid fa-circle-check fa-lg' />
        </span>
      );
    }
  }

  return createPortal(
    <ModalFramework id='shopview-inspection-reports-modal'>
      {loading ? (
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
          <CircularProgress className='my-4 mx-auto d-block' size={80} />
          <span className='my-3'>Submitting ...</span>
        </div>
      ) : (
        <React.Fragment>
          <ModalHeader title='Inspection Report'>
            {inspectionStatus(initialValues)}
            <span className='h5 pl-1'>{initialValues.vehicle}</span>
            <p className='mb-0 h5 small'>Inspected By: {initialValues.inspectedByUser}</p>
            <p className='mb-0 h5 small'>Closed By: {initialValues.closedByUser}</p>
            <p className='mb-0 h5 small'>Closed Date: {initialValues.closedDate}</p>
            {customerSettings.inspection.inspectionReportNameInput && (
              <p className='mb-0 h5 small'>
                Inspection Name Entered: {initialValues.inspectionNameInput || 'Not Entered'}
              </p>
            )}
          </ModalHeader>
          <ModalBody>
            <InspectionReportDetailTable data={initialValues} />
          </ModalBody>
          <ModalFooter
            onLeftSideButton={initialValues.status != 'passed' ? toggleTicketState : null}
            leftSideButtonText={initialValues.status == 'closed' ? 'Reopen Ticket' : 'Close Ticket'}
          />
        </React.Fragment>
      )}
    </ModalFramework>,
    document.getElementById('app')
  );
}

export {InspectionReportsModal};
