import {createSlice} from '@reduxjs/toolkit';

const coverageModalSlice = createSlice({
  name: 'coverageModal',
  initialState: {
    type: '',
    zone: '',
    time: '',
    timeInField: '',
    timeOutField: '',
    timeIdle: '',
    timeTotal: '',
    coverage: '',
    fullEquipmentList: [],
    equipment: '',
    task: '',
  },
  reducers: {
    setInitialValues(state, action) {
      const modalData = action.payload;
      state.type = modalData.type;

      if (modalData.type == 'equipment') {
        state.equipment = modalData.equipment;
        state.timeInField = modalData.timeInField;
        state.timeOutField = modalData.timeOutField;
        state.timeIdle = modalData.timeIdle;
        state.timeTotal = modalData.timeTotal;
        state.coverage = modalData.coverage;
        state.task = modalData.task;
      } else {
        state.zone = modalData.zone;
        state.time = modalData.time;
        state.coverage = modalData.coverage;
        state.fullEquipmentList = modalData.fullEquipmentList;
      }
    },
  },
});

export const {setInitialValues} = coverageModalSlice.actions;
export default coverageModalSlice.reducer;
