import {DateTime, Interval, Duration} from 'luxon';

// Define images used for vehicle markers
const imgTracGreen = new Image();
imgTracGreen.src = '/img/tractor_green.png';
const imgTrailer = new Image();
imgTrailer.src = '/img/trailer.png';
const imgPassenger = new Image();
imgPassenger.src = '/img/passenger-vehicle.png';
const imgATV = new Image(); // TBD
imgATV.src = '/img/atv.png';
const imgPlatform = new Image();
imgPlatform.src = '/img/platform.png';
const imgConstruction = new Image();
imgConstruction.src = '/img/construction.png';

// Colors used for Vehicle Marker Outlione
const vehicleMarkerRed = '#ff3b35';
const vehicleMarkerOrange = '#ff9900';

function drawCanvas(vehicle, selected, taskActive) {
  // Default Vehicle Markers (Tractor Image for Farm Vehicles and Unknown)
  let img = imgTracGreen;
  // Other Vehicle Markers
  switch (vehicle?.machineType) {
    case 1:
      // Road Vehicle
      img = imgPassenger;
      break;
    case 3:
      // Trailer
      img = imgTrailer;
      break;
    case 4:
      // ATV
      img = imgATV;
      break;
    case 5:
      // Platform
      img = imgPlatform;
      break;
    case 6:
      // Construction
      img = imgConstruction;
      break;
  }
  let bearing = vehicle.bearing;
  if (vehicle.gpsCoords.length > 1) {
    const startPoint = vehicle.gpsCoords[vehicle.gpsCoords.length - 2];
    const endPoint = vehicle.gpsCoords[vehicle.gpsCoords.length - 1];
    // Check if last two latest GPS points are identical, if yes then use bearing from vehicleData
    if (startPoint.latitude != endPoint.latitude || startPoint.longitude != endPoint.longitude) {
      // Use the last two latest GPS points to calculate direction/bearing for vehicle Marker
      bearing = haversineBearing(startPoint, endPoint);
    }
  }
  // Adding the image to a canvas allows for rotation of the markers
  const canvas = document.createElement('canvas');
  // Make canvas a square with side lengths equal to the larger value between height and width
  canvas.width = img.height > img.width ? img.height : img.width;
  canvas.height = img.height > img.width ? img.height : img.width;
  const context = canvas.getContext('2d');
  context.clearRect(0, 0, canvas.width, canvas.height);
  const centerX = canvas.width / 2;
  const centerY = canvas.height / 2;
  // Rotate canvas about center
  context.save();
  context.translate(centerX, centerY);
  context.rotate((bearing * Math.PI) / 180);
  context.translate(-centerX, -centerY);
  context.drawImage(img, canvas.width / 2 - img.width / 2, 0);

  // Outline the Icons to indicate status of the vehicle
  // None - Regular/Normal
  // Orange - Selected
  // Red - Selected AND taskActive
  if (selected || (selected && taskActive)) {
    let outlineColor = vehicleMarkerOrange;
    if (selected && taskActive) {
      outlineColor = vehicleMarkerRed;
    }

    // Use Solid Shadow to Draw outline around icons
    // outline thickness
    const offset = 3;
    // Loop to offset shadow in both x and y direction to generate outline
    for (let x = -offset; x <= offset; x++) {
      for (let y = -offset; y <= offset; y++) {
        // Set shadow offset
        context.shadowColor = outlineColor;
        context.shadowBlur = 0;
        context.shadowOffsetX = x;
        context.shadowOffsetY = y;
        // Draw image with shadow
        context.drawImage(img, canvas.width / 2 - img.width / 2, 0);
      }
    }
  }
  context.restore();
  return canvas;
}

function createIconImage(maps, canvas) {
  const iconImage = {
    url: canvas.toDataURL(),
    // This marker is 20 pixels wide by 32 pixels high.
    size: new maps.Size(canvas.width, canvas.height),
    // The origin for this image is (0, 0).
    origin: new maps.Point(0, 0),
    // The anchor for this image is the base of the flagpole at (0, 32).
    anchor: new maps.Point(canvas.width / 2, canvas.height / 2),
  };
  return iconImage;
}

function drawVehiclePath(map, maps, vehicle, selected, taskActive) {
  let pathLine = null;

  if (vehicle.gpsCoords != undefined && vehicle.gpsCoords.length > 0) {
    // Load the new gps coords
    const elapsedPathCoordinates = [];
    for (let i = 0; i < vehicle.gpsCoords.length; i++) {
      const latLng = {lat: vehicle.gpsCoords[i].latitude, lng: vehicle.gpsCoords[i].longitude};
      elapsedPathCoordinates.push(latLng);
    }

    // Determine vehicle pathline colour based on selected and taskActive statuses
    let lineColor = '#0FFF00 ';
    let zIndex = 1;
    if (selected && taskActive) {
      lineColor = '#FF0000';
      zIndex = 2;
    } else if (selected) {
      lineColor = '#F6C23E';
      zIndex = 2;
    }

    // Add new pathline
    pathLine = new maps.Polyline({
      path: elapsedPathCoordinates,
      geodesic: true,
      strokeColor: lineColor,
      strokeOpacity: 1.0,
      strokeWeight: 2,
      zIndex: zIndex,
      map,
    });

    // Create line symbols for directionality
    google.maps.event.addListener(map, 'zoom_changed', function () {
      const zoom = map.getZoom();
      if (zoom > 14) {
        pathLine.setOptions({
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 1.2,
                fillOpacity: 1,
                fillColor: '#ffffff',
                strokeColor: '#ffffff',
              },
              repeat: '100px',
            },
          ],
        });
      } else {
        pathLine.setOptions({
          icons: [],
        });
      }
    });
  }

  return pathLine;
}

function drawHazardMarker(map, maps, hazard) {
  // Add new marker
  const marker = new maps.Marker({
    position: hazard.hazardCoordinate,
    icon: '/img/warning_icon.png',
    map,
  });

  // Create infowindow for when marker is clicked on
  const contentString =
    '<div id="content">' + '<h6 id="firstHeading" class="firstHeading">' + hazard.hazardType + '</h6>' + '</div>';
  const infowindow = new maps.InfoWindow({
    content: contentString,
  });

  // This listener checks if markers gets clicked
  marker.addListener('click', () => {
    infowindow.open(map, marker);
  });
  // This listener checks if map gets clicked
  map.addListener('click', () => {
    infowindow.close();
  });

  return marker;
}

function haversineBearing(startPoint, endPoint) {
  // Calculate bearing using two gps points
  const startLat = startPoint.latitude;
  const startLong = startPoint.longitude;
  const endLat = endPoint.latitude;
  const endLong = endPoint.longitude;

  const y = Math.sin(endLong - startLong) * Math.cos(endLat);
  const x =
    Math.cos(startLat) * Math.sin(endLat) - Math.sin(startLat) * Math.cos(endLat) * Math.cos(endLong - startLong);
  const angle = Math.atan2(y, x);
  const bearing = ((angle * 180) / Math.PI + 360) % 360; // in degrees
  return bearing;
}

export {drawVehiclePath, drawHazardMarker, drawCanvas, createIconImage};
