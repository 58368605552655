import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import {setTaskActive, setSelectedVehicle, setSelectedTask} from './cabviewSlice';
import CircularProgress from '@mui/material/CircularProgress';

function Menu({socket, forceMenuOpen}) {
  const dispatch = useDispatch();

  const authenticationStatus = useSelector((state) => {
    return state.app.authenticationStatus;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const vehicleData = useSelector((state) => {
    return state.cabview.vehicles;
  });
  const taskData = useSelector((state) => {
    return state.cabview.tasks;
  });
  const implementData = useSelector((state) => {
    return state.cabview.implements;
  });
  const taskActive = useSelector((state) => {
    return state.cabview.taskActive;
  });
  const selectedVehicle = useSelector((state) => {
    return state.cabview.selectedVehicle;
  });
  const selectedTask = useSelector((state) => {
    return state.cabview.selectedTask;
  });

  const [menuToggled, setMenuToggled] = useState(false);
  const [permanentTaskActive, setPermanentTaskActive] = useState(false);
  const [selectOptions, setSelectOptions] = useState({
    vehicles: [],
    tasks: [],
  });
  const [displayValues, setDisplayValues] = useState({
    vehicle: '',
    task: '',
    time: '',
    coverage: '',
    coverageDataAvailable: false,
  });

  useEffect(() => {
    // Update selector options if new data comes in
    updateSelectOptions();
  }, [vehicleData, taskData]);

  useEffect(() => {
    // Select permanent task
    forcePermanentTask();
  }, [selectedVehicle, taskActive]);

  useEffect(() => {
    // Default the selectors to the first option if no option is currently selected
    updateDisplayValues();
  }, [selectedVehicle, selectedTask]);

  useEffect(() => {
    if (forceMenuOpen) {
      setMenuToggled(true);
      socket.emit('setMenuToggled', {menuToggled: true});
    }
  }, [forceMenuOpen]);

  function updateSelectOptions() {
    // Update selector options based on data
    const vehicleOptions = [];
    const taskOptions = [];
    for (let i = 0; i < vehicleData.length; i++) {
      if (
        vehicleData[i].cabviewActive &&
        vehicleData[i].gpsCoords != undefined &&
        vehicleData[i].gpsCoords.length > 0
      ) {
        vehicleOptions.push({value: vehicleData[i].serialNumber, label: vehicleData[i].name});
      }
    }
    for (let i = 0; i < taskData.length; i++) {
      taskOptions.push({value: taskData[i].taskId, label: taskData[i].name});
    }
    setSelectOptions({
      vehicles: vehicleOptions,
      tasks: taskOptions,
    });
  }

  function updateDisplayValues() {
    // Update values to be displayed when a vehicle is being tracked
    let vehicleString = '';
    let taskString = '';
    let timeString = '';
    let coverageString = '';
    let coverageDataAvailableBoolean = false;

    // Find vehicle object given serialNumber
    const vehicleObj = vehicleData.find((vehicle) => {
      return vehicle.serialNumber == selectedVehicle;
    });
    // Find task object given id
    const taskObj = taskData.find((task) => {
      return task.taskId == selectedTask;
    });

    // Update strings to be displayed in menu
    if (vehicleObj) {
      vehicleString = vehicleObj.name;
      timeString = vehicleObj.inFieldTime;
      coverageString = vehicleObj.inFieldAcreage;
      coverageDataAvailableBoolean = vehicleObj.coverageDataAvailable;
    }
    if (taskObj) {
      taskString = taskObj.name;
    }

    const updateDisplayObj = {
      vehicle: vehicleString,
      task: taskString,
      time: timeString,
      coverage: coverageString,
      coverageDataAvailable: coverageDataAvailableBoolean,
    };

    setDisplayValues(updateDisplayObj);
  }

  function selectVehicle(vehicleSN) {
    const vehicleObj = vehicleData.find((vehicle) => {
      return vehicle.serialNumber == vehicleSN;
    });
    if (!taskActive) {
      // Auto Select the task if the vehicle was previously doing a task
      if (Object.prototype.hasOwnProperty.call(vehicleObj, 'latestTaskId') && vehicleObj['latestTaskId'] !== null) {
        const taskId = vehicleObj['latestTaskId'];
        dispatch(setSelectedTask(taskId));
        // Emit a socket message to backend
        socket.emit('setSelectedTask', {taskId});
      }
      dispatch(setSelectedVehicle(vehicleSN));
      // Emit a socket message to backend
      socket.emit('setSelectedVehicle', {vehicleSN});
    }
  }

  function selectTask(taskId) {
    dispatch(setSelectedTask(taskId));
    // Emit a socket message to backend
    socket.emit('setSelectedTask', {taskId});
  }

  function forcePermanentTask() {
    // Enable task select
    setPermanentTaskActive(false);

    // Find vehicle object given serialNumber
    const vehicleObj = vehicleData.find((vehicle) => {
      return vehicle.serialNumber == selectedVehicle;
    });
    if (vehicleObj) {
      if (vehicleObj.linkedImplementSN) {
        // Find implement object given serialNumber
        const implementObj = implementData.find((implement) => {
          return implement.serialNumber == vehicleObj.linkedImplementSN;
        });
        if (implementObj && implementObj.linkedTaskId) {
          // Find task object given id
          const taskObj = taskData.find((task) => {
            return task.taskId == implementObj.linkedTaskId;
          });
          if (taskObj && typeof taskObj.archived != undefined && taskObj.archived === false) {
            selectTask(implementObj.linkedTaskId);
            setPermanentTaskActive(true);
          }
        }
      } else if (vehicleObj.linkedTaskId) {
        // Find task object given id
        const taskObj = taskData.find((task) => {
          return task.taskId == vehicleObj.linkedTaskId;
        });
        if (taskObj && typeof taskObj.archived != undefined && taskObj.archived === false) {
          selectTask(vehicleObj.linkedTaskId);
          setPermanentTaskActive(true);
        }
      }
    }
  }

  function toggleStartTask() {
    if (!taskActive) {
      if (selectedTask === '' || selectedVehicle === '') {
        // Prevent selections when no selected vehicles are present
        return;
      } else {
        // Emit a socket message to backend that a vehicle is now being tracked
        socket.emit('startTracking', {selectedVehicle, selectedTask});
        // Change taskActive status for frontend visuals
        dispatch(setTaskActive(true));
      }
    } else {
      // Emit a socket message to backend that a vehicle is no longer being tracked
      socket.emit('stopTracking', []);
      // Change taskActive status for frontend visuals
      dispatch(setTaskActive(false));
    }
  }

  // The class names with 'md' are specifically for small screen sizes (< 767 px)
  return (
    <div className='bg-dark'>
      <div className='mw-100 pb-2'>
        {authenticationStatus.cabviewEnabled && (
          <button
            className='btn btn-dark w-100 p-0'
            onClick={() => {
              setMenuToggled(!menuToggled);
              socket.emit('setMenuToggled', {menuToggled: !menuToggled});

              return;
            }}
          >
            <FontAwesomeIcon icon={menuToggled ? 'fas fa-caret-down' : 'fas fa-caret-up'} size='2x' />
          </button>
        )}
        <div className='row m-0 mt-1'>
          <div className={`col ${menuToggled && 'col-md-6'}`}>
            <div className='row m-0'>
              <span className={`text-white col-6 ${menuToggled && 'col-md-4'} ${!taskActive && 'pt-2'}`}>
                Vehicle:
                {menuToggled &&
                  (selectedVehicle == '' ? (
                    <FontAwesomeIcon icon='far fa-circle-xmark' className='ml-1' style={{color: '#FF0004'}} />
                  ) : (
                    <FontAwesomeIcon icon='far fa-check-circle' className='ml-1' style={{color: '#00A41E'}} />
                  ))}
              </span>
              {taskActive ? (
                <span className={`text-white font-weight-bolder col-6 ${menuToggled && 'col-md-8'}`}>
                  {displayValues.vehicle}
                </span>
              ) : (
                <Select
                  menuPlacement='top'
                  className={`col-6 ${menuToggled && 'col-md-8'}`}
                  options={selectOptions.vehicles}
                  onChange={(e) => {
                    return selectVehicle(e.value);
                  }}
                  value={selectOptions.vehicles.find((obj) => {
                    return obj.value === selectedVehicle;
                  })}
                />
              )}
            </div>
            {menuToggled && (
              <React.Fragment>
                <div className='row m-0 mt-1'>
                  <span className={`text-white col-6 col-md-4 ${!taskActive && 'pt-2'}`}>
                    Task:
                    {selectedTask == '' ? (
                      <FontAwesomeIcon icon='far fa-circle-xmark' className='ml-1' style={{color: '#FF0004'}} />
                    ) : (
                      <FontAwesomeIcon icon='far fa-check-circle' className='ml-1' style={{color: '#00A41E'}} />
                    )}
                  </span>
                  {taskActive ? (
                    <span className='text-white font-weight-bolder col-6 col-md-8'>{displayValues.task}</span>
                  ) : (
                    <Select
                      isDisabled={permanentTaskActive}
                      menuPlacement='top'
                      className='col-6 col-md-8'
                      options={selectOptions.tasks}
                      onChange={(e) => {
                        return selectTask(e.value);
                      }}
                      value={selectOptions.tasks.find((obj) => {
                        return obj.value === selectedTask;
                      })}
                    />
                  )}
                </div>
                {taskActive ? (
                  <React.Fragment>
                    <div className='row m-0 mt-1'>
                      <div className='col-6 col-md-4'>
                        <span className='text-white'>In Field Time: </span>
                        <span className='text-white font-weight-bolder'>
                          {displayValues.coverageDataAvailable ? (
                            displayValues.time + ' h'
                          ) : (
                            <CircularProgress size={10} color='inherit' />
                          )}{' '}
                        </span>
                      </div>
                      <div className='col-6 col-md-8'>
                        <span className='text-white'>Coverage: </span>
                        <span className='text-white font-weight-bolder'>
                          {displayValues.coverageDataAvailable ? (
                            displayValues.coverage + ' ac'
                          ) : (
                            <CircularProgress size={10} color='inherit' />
                          )}{' '}
                        </span>
                      </div>
                    </div>
                    <div className='col text-center mt-2 d-md-none'>
                      <button className='btn btn-primary col-12' onClick={toggleStartTask}>
                        <FontAwesomeIcon icon='fas fa-arrow-left' /> Next Task
                      </button>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className='col text-center mt-2 d-md-none'>
                    <button
                      className='btn btn-success col-12'
                      onClick={toggleStartTask}
                      disabled={selectedVehicle == '' || selectedTask == ''}
                    >
                      Submit Task <FontAwesomeIcon icon='fas fa-play' />
                    </button>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          {menuToggled && taskActive && (
            <div className='col-6 d-none d-md-inline-block'>
              <div className='col text-center'>
                <button className='btn btn-primary col-12' onClick={toggleStartTask} style={{height: '70px'}}>
                  <FontAwesomeIcon icon='fas fa-arrow-left' /> Next Task
                </button>
              </div>
            </div>
          )}
          {menuToggled && !taskActive && (
            <div className='col-6 d-none d-md-inline-block'>
              <div className='col text-center'>
                <button
                  className='btn btn-success col-12'
                  onClick={toggleStartTask}
                  style={{height: '70px'}}
                  disabled={selectedVehicle == '' || selectedTask == ''}
                >
                  Submit Task <FontAwesomeIcon icon='fas fa-play' />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export {Menu};
