// Import for framework tools
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sendGAPageview} from '../../app/utils';

// Import dependent components
import {Framework} from '../../components/Framework';
import {ZoneInfoTable} from './ZoneInfoTable';
import {Map} from './Map';
import {Menu} from './Menu';

function Mapview() {
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.zoneinfo.loading;
  });
  const displayedTable = useSelector((state) => {
    return state.zoneinfo.displayedTable;
  });
  const mapView = useSelector((state) => {
    return state.zoneinfo.mapView;
  });
  const sortMethod = useSelector((state) => {
    return state.zoneinfo.sortMethod;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const zonesData = useSelector((state) => {
    return state.zoneinfo.zonesData;
  });
  const geoFenceIntelliBlockDict = useSelector((state) => {
    return state.zoneinfo.geoFenceIntelliBlockDict;
  });
  const geoFenceAttributesIntelliBlockDict = useSelector((state) => {
    return state.zoneinfo.geoFenceAttributesIntelliBlockDict;
  });

  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [tableData, setTableData] = useState({
    blocks: [],
    fields: [],
    regions: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    blocks: [],
    fields: [],
    regions: [],
  });
  const [filters, setFilters] = useState({
    blocks: [],
    fields: [],
    regions: [],
  });

  const [drag, setDrag] = useState({
    active: false,
    x: '',
    y: '',
  });

  const [dims, setDims] = useState({
    w: window.innerWidth / 1.75,
    h: window.innerHeight / 1.75,
  });

  useEffect(() => {
    document.title = 'IntelliCulture | MapView';
    sendGAPageview(document.title);
  }, []);

  useEffect(() => {
    getZoneInfoData();
  }, [zonesData, sortMethod]);

  function startResize(e) {
    setDrag({
      active: true,
      x: e.clientX || e.touches[0].pageX,
      y: e.clientY || e.touches[0].pageY,
    });
  }

  function resizeFrame(e) {
    const {active, x, y} = drag;
    if (active) {
      const pointerX = e.clientX || e.touches[0].pageX;
      const pointerY = e.clientY || e.touches[0].pageY;
      if (!smallScreen) {
        const xDiff = Math.abs(x - pointerX);
        let newW = x > pointerX ? dims.w - xDiff : dims.w + xDiff;

        const minWidth = 200;
        const maxWidth = window.innerWidth - 300;
        newW = newW < minWidth ? minWidth : newW;
        newW = newW > maxWidth ? maxWidth : newW;

        setDrag({...drag, x: pointerX, y: pointerY});
        setDims({w: newW, h: dims.h});
      } else {
        const yDiff = Math.abs(y - pointerY) * 3;
        const newH = y > pointerY ? dims.h - yDiff : dims.h + yDiff;
        setDrag({...drag, x: pointerX, y: pointerY});
        setDims({w: dims.w, h: newH});
      }
    }
  }

  function stopResize() {
    setDrag({...drag, active: false});
  }

  function setFilterDefault() {
    setFilters({
      blocks: [],
      fields: [],
      regions: [],
    });
  }

  function renderTable() {
    switch (displayedTable) {
      case 'block':
        return (
          <ZoneInfoTable
            type='blocks'
            tableData={
              userSettings.general.cropviewEnabled && customerSettings.general.geoFencesProvided ? tableData.blocks : []
            }
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'field':
        return (
          <ZoneInfoTable
            type='fields'
            tableData={
              userSettings.general.cropviewEnabled && customerSettings.general.geoFencesProvided ? tableData.fields : []
            }
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'region':
        return (
          <ZoneInfoTable
            type='regions'
            tableData={
              userSettings.general.cropviewEnabled && customerSettings.general.geoFencesProvided
                ? tableData.regions
                : []
            }
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
    }
  }

  function getZoneInfoData() {
    const blocks = [];
    const filterOptionsBlocks = [];
    const filterOptionsFields = [];
    const filterOptionsRegions = [];
    const fieldIdtoFieldDataDict = {};
    const regionIdtoRegionDataDict = {};

    for (let i = 0; i < zonesData.regions.length; i++) {
      const tempRegionData = {
        'name': zonesData.regions[i].region_name,
        'lat_max': zonesData.regions[i].lat_max,
        'lat_min': zonesData.regions[i].lat_min,
        'lng_max': zonesData.regions[i].lng_max,
        'lng_min': zonesData.regions[i].lng_min,
        'area_meters2': 0,
      };
      const tempRegionFilterOption = {
        value: tempRegionData.name,
        text: tempRegionData.name,
      };
      filterOptionsRegions.push(tempRegionFilterOption);
      regionIdtoRegionDataDict[zonesData.regions[i].region_id] = tempRegionData;
    }
    for (let j = 0; j < zonesData.fields.length; j++) {
      const tempFieldData = {
        'name': zonesData.fields[j].field_name,
        'lat_max': zonesData.fields[j].lat_max,
        'lat_min': zonesData.fields[j].lat_min,
        'lng_max': zonesData.fields[j].lng_max,
        'lng_min': zonesData.fields[j].lng_min,
        'parent_region_id': zonesData.fields[j].parent_region_id,
        'area_meters2': 0,
      };
      const tempFieldFilterOption = {
        value: tempFieldData.name,
        text: tempFieldData.name,
      };

      if (Object.prototype.hasOwnProperty.call(regionIdtoRegionDataDict, tempFieldData.parent_region_id)) {
        const parentRegion = regionIdtoRegionDataDict[tempFieldData.parent_region_id];
        tempFieldFilterOption.group = parentRegion.name;
      }
      filterOptionsFields.push(tempFieldFilterOption);
      fieldIdtoFieldDataDict[zonesData.fields[j].field_id] = tempFieldData;
    }
    for (let k = 0; k < zonesData.blocks.length; k++) {
      let rowSpacingMeters = '';
      if (typeof zonesData.blocks[k].row_spacing_meters != 'undefined') {
        rowSpacingMeters = zonesData.blocks[k].row_spacing_meters;
      }
      const tempBlockData = {
        'name': zonesData.blocks[k].block_name,
        'block_id': zonesData.blocks[k].block_id,
        'lat_max': zonesData.blocks[k].lat_max,
        'lat_min': zonesData.blocks[k].lat_min,
        'lng_max': zonesData.blocks[k].lng_max,
        'lng_min': zonesData.blocks[k].lng_min,
        'row_spacing_meters': rowSpacingMeters,
        'parent_field_id': zonesData.blocks[k].parent_field_id,
        'area_meters2': 0,
        'rowPassEnabled': 'No', // Yes, No, Mixed
        'cropPrimaryClass': zonesData.blocks[k].cropPrimaryClass,
        'cropSubClass': zonesData.blocks[k].cropSubClass,
        'cropVariety': zonesData.blocks[k].cropVariety,
      };

      // Find area of the block and determine row pass status
      let blockArea = 0;
      let intelliblockRowPassEnabledNum = 0;
      const blockIntelliBlockNums = zonesData.blocks[k].intelliblock_nums;
      for (let n = 0; n < blockIntelliBlockNums.length; n++) {
        let geoFence;
        let geoFenceAttribute;

        if (Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[n])) {
          geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[n]];
        }
        if (Object.prototype.hasOwnProperty.call(geoFenceAttributesIntelliBlockDict, blockIntelliBlockNums[n])) {
          geoFenceAttribute = geoFenceAttributesIntelliBlockDict[blockIntelliBlockNums[n]];
        }

        // Determine if geofence is rowpass enabled, product may not necessarily be using it
        const geofenceRowPassEnabled =
          geoFence.properties.rowPassEnabled &&
          geoFence.properties.trustRowBearing &&
          geoFence.properties.rowPassAnalysisAvailable;
        if (geofenceRowPassEnabled) {
          intelliblockRowPassEnabledNum += 1;
        }

        // Determine if geofence is rowpass enabled and product is actively using rowpass
        const geofenceRowPass =
          customerSettings.general.rowPassStatisticsEnabled &&
          geoFence.properties.rowPassEnabled &&
          geoFence.properties.trustRowBearing &&
          geoFence.properties.rowPassAnalysisAvailable &&
          typeof geoFenceAttribute != 'undefined' &&
          typeof geoFenceAttribute.estimatedArea != 'undefined';

        if (geofenceRowPass) {
          blockArea += geoFenceAttribute.estimatedArea;
        } else {
          blockArea += geoFence.properties.area_meters2;
        }
      }

      // Override block area if manually inputted area exists
      if (
        Object.prototype.hasOwnProperty.call(zonesData.blocks[k], 'manual_area_meters2') &&
        zonesData.blocks[k].manual_area_meters2 !== 0
      ) {
        blockArea = zonesData.blocks[k].manual_area_meters2;
      }

      // Determine row pass status
      let rowPassEnabledStatus = 'No';
      if (intelliblockRowPassEnabledNum == blockIntelliBlockNums.length) {
        rowPassEnabledStatus = 'Yes';
      } else if (intelliblockRowPassEnabledNum > 0) {
        rowPassEnabledStatus = 'Mixed';
      }

      // Assign data to block
      tempBlockData.area_meters2 = blockArea;
      tempBlockData.rowPassEnabled = rowPassEnabledStatus;

      const tempBlockFilterOption = {
        value: tempBlockData.name,
        text: tempBlockData.name,
      };

      if (Object.prototype.hasOwnProperty.call(fieldIdtoFieldDataDict, zonesData.blocks[k].parent_field_id)) {
        const parentField = fieldIdtoFieldDataDict[zonesData.blocks[k].parent_field_id];
        tempBlockFilterOption.group = parentField.name;
        parentField.area_meters2 += blockArea;
        if (Object.prototype.hasOwnProperty.call(regionIdtoRegionDataDict, parentField.parent_region_id)) {
          const parentRegion = regionIdtoRegionDataDict[parentField.parent_region_id];
          parentRegion.area_meters2 += blockArea;
        }
      }
      filterOptionsBlocks.push(tempBlockFilterOption);
      blocks.push(tempBlockData);
    }

    const fields = Object.values(fieldIdtoFieldDataDict);
    const regions = Object.values(regionIdtoRegionDataDict);

    const regionTableData = sortZoneData(regions);
    const fieldTableData = sortZoneData(fields);
    const blockTableData = sortZoneData(blocks);

    setFilterOptions((values) => {
      return {
        ...values,
        blocks: [...new Set(filterOptionsBlocks)],
        fields: [...new Set(filterOptionsFields)],
        regions: [...new Set(filterOptionsRegions)],
      };
    });

    setTableData({
      blocks: blockTableData,
      fields: fieldTableData,
      regions: regionTableData,
    });
  }

  function sortZoneData(dataList) {
    // Make copy of list or else will get error when trying to sort
    const zoneList = JSON.parse(JSON.stringify(dataList));
    // Sort zones and vehicle list within zone by method specified
    // Will sort a after b if compare function returns > 0 and sort a before b if compare function returns < 0
    if (sortMethod == 'name') {
      zoneList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if (sortMethod == 'acreage') {
      zoneList.sort((a, b) => {
        return a.area_meters2 < b.area_meters2 ? 1 : -1;
      });
    }
    return zoneList;
  }

  return (
    <React.Fragment>
      <Framework activePage='mapview' pageName='MapView'>
        <div
          className='container-fluid'
          id='tab-wrapper'
          onMouseMove={resizeFrame}
          onTouchMove={resizeFrame}
          onMouseUp={stopResize}
          onMouseLeave={stopResize}
          onTouchEnd={stopResize}
        >
          <Menu setFilterDefault={setFilterDefault} />
          {smallScreen ? (
            <React.Fragment>
              {mapView <= 1 && renderTable()}
              {mapView >= 1 && <Map filters={filters} />}
            </React.Fragment>
          ) : (
            <div className='d-flex flex-row cropview-main'>
              {renderTable()}
              <div className='cropview-handle-horizontal ml-1 mr-3 mb-5' onPointerDown={startResize}></div>
              <div className='flex-grow-1 pt-2 pb-0'>
                <Map filters={filters} />
              </div>
            </div>
          )}
        </div>
      </Framework>
    </React.Fragment>
  );
}

export {Mapview};
