// Import for framework tools
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sendGAPageview} from '../../app/utils';

// Import dependent components
import {Framework} from '../../components/Framework';
import {Tabs} from '../../components/Tabs';
import {TabContent} from '../../components/TabContent';

// Import tab components
import {UsersTab} from './UsersTab';
import {ReportsTab} from './Reports/ReportsTab';
import {ProductTab} from './ProductTab';
import {TasksTab} from './TasksTab';
import {AssetsTab} from './Assets/AssetsTab';

import {getSettingsData, getImplementsData, getTaskConfigsData} from './settingsSlice';

function Settings() {
  const dispatch = useDispatch();

  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const getSettingsTrigger = useSelector((state) => {
    return state.settings.getSettingsTrigger;
  });

  const [settingsTabs, setSettingsTabs] = useState([]);
  const [settingsIcons, setSettingsIcons] = useState([]);
  const [settingsContent, setSettingsContent] = useState([]);

  useEffect(() => {
    document.title = 'IntelliCulture | Settings';
    sendGAPageview(document.title);
  });

  useEffect(async () => {
    determineTabDisplay();
    dispatch(getImplementsData());
    dispatch(getTaskConfigsData());
    dispatch(getSettingsData());
  }, [getSettingsTrigger]);

  function determineTabDisplay() {
    const tabs = [];
    const icons = [];
    const content = [];

    // User management
    if (userSettings.roleViewAccess['teamManagement']) {
      tabs.push('Users');
      icons.push('fa-solid fa-users');
      content.push(<UsersTab key='Users' />);
    }

    // Product
    if (
      userSettings.roleViewAccess['generalManagement'] ||
      userSettings.roleViewAccess['landingManagement'] ||
      userSettings.roleViewAccess['cabviewManagement'] ||
      userSettings.roleViewAccess['cropviewManagement'] ||
      userSettings.roleViewAccess['shopviewManagement'] ||
      userSettings.roleViewAccess['diagnosticsManagement'] ||
      userSettings.roleViewAccess['inspectionManagement']
    ) {
      tabs.push('Product');
      icons.push('fa-solid fa-sliders');
      content.push(<ProductTab key='Product' />);
    }

    // Assets
    if (
      userSettings.roleViewAccess['vehicleAttributesManagement'] ||
      userSettings.roleViewAccess['vehicleStatusManagement'] ||
      userSettings.roleViewAccess['vehicleFeaturesManagement'] ||
      userSettings.roleViewAccess['implementManagement'] ||
      userSettings.roleViewAccess['labelManagement']
    ) {
      tabs.push('Assets');
      icons.push('fa-solid fa-tractor');
      content.push(<AssetsTab key='Assets' />);
    }

    // Tasks
    if (userSettings.roleViewAccess['taskManagement']) {
      tabs.push('Tasks');
      icons.push('fa-solid fa-list-check');
      content.push(<TasksTab key='Tasks' />);
    }

    // Reports
    if (
      userSettings.roleViewAccess['reportsBackgroundManagement'] ||
      userSettings.roleViewAccess['reportsDeliveryManagement'] ||
      userSettings.roleViewAccess['reportsExceptionsManagement'] ||
      userSettings.roleViewAccess['reportsCoveragePagesManagement'] ||
      userSettings.roleViewAccess['reportsVehicleFilterManagement'] ||
      userSettings.roleViewAccess['reportsTaskFilterManagement'] ||
      userSettings.roleViewAccess['inspectionManagement'] ||
      userSettings.roleViewAccess['dashboardManagement']
    ) {
      tabs.push('Reports');
      icons.push('fa-solid fa-file-lines');
      content.push(<ReportsTab key='Reports' />);
    }

    setSettingsTabs(tabs);
    setSettingsIcons(icons);
    setSettingsContent(content);
  }

  return (
    <React.Fragment>
      <Framework activePage='Settings' pageName='Settings'>
        <div className='container-fluid tab-wrapper'>
          <div className='row'>
            <div className='col-12'>
              <Tabs tabs={settingsTabs} icons={settingsIcons} />
            </div>
          </div>
          <TabContent content={settingsContent} />
        </div>
      </Framework>
    </React.Fragment>
  );
}

export {Settings};
